import {
	useState,
	useEffect
} from 'react';
import {createTimeOfInterest} from 'astronomy-bundle/time';
import {
	createMoon
} from 'astronomy-bundle/moon';
import {
	createMercury,
	createVenus,
	createMars,
	createJupiter,
	createSaturn,
	createUranus,
	createNeptune
} from 'astronomy-bundle/planets';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Dropdown from 'react-bootstrap/Dropdown';
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	// faSun,
	// faCloudSun,
	// faCloudSunRain,
	faCloud,
	faCloudRain,
	faCloudShowersHeavy,
	// faCloudBolt,
	faSnowflake,
	faMoon,
	// faCloudMoon,
	// faCloudMoonRain
} from '@fortawesome/free-solid-svg-icons';
import CelestialBody from './CelestialBody';
import * as moment from 'moment';

// https://www.freepik.com/free-vector/flat-design-planet-collection_5336208.htm#query=planet&position=5&from_view=keyword&track=sph?log-in=google
// https://www.vecteezy.com/vector-art/528672-planets-in-the-galaxy

function CalendarDay(props) {
	
	const isToday = props.date === moment().format('YYYY-MM-DD');

	const [celestialBodies, setCelestialBodies] = useState(null);
	const [weather, setWeather] = useState(null);
	
	useEffect(() => {
		
		const getCelestialBodies = async (utcStartDateTime, utcEndDateTime, coordinates, angle, arcLength, minimumAltitude) => {
			
			// https://www.timeanddate.com/astronomy/horizontal-coordinate-system.html
			// Altitude: 0 means it's on the horizon, 90 means it's straight up, -90 means it's straight down
			// Azimuth: 0 = north, 90 = east, 180 = south, 270 = west
			
			var isAngleBelowZero = !(angle + arcLength >= 360);
			
			// Because sometimes the starting angle is below 0, let's add 360 to every value so we're always comparing between 360 and 720
			if (isAngleBelowZero) {
				while (angle > 360) angle -= 360; angle += 360;
			}

			const minutesBetweenStartAndEndTimes = moment(utcEndDateTime).diff(moment(utcStartDateTime), 'minutes');

			const startToi = createTimeOfInterest.fromDate(utcStartDateTime);
			const middleToi = createTimeOfInterest.fromDate(moment(utcStartDateTime).add(minutesBetweenStartAndEndTimes / 2, 'minutes').toDate());
			const endToi = createTimeOfInterest.fromDate(utcEndDateTime);
						
			const moon = {
				start: createMoon(startToi),
				middle: createMoon(middleToi),
				end: createMoon(endToi)
			};
			const mercury = {
				start: createMercury(startToi),
				middle: createMercury(middleToi),
				end: createMercury(endToi)
			};
			const venus = {
				start: createVenus(startToi),
				middle: createVenus(middleToi),
				end: createVenus(endToi)
			};
			const mars = {
				start: createMars(startToi),
				middle: createMars(middleToi),
				end: createMars(endToi)
			};
			const jupiter = {
				start: createJupiter(startToi),
				middle: createJupiter(middleToi),
				end: createJupiter(endToi)
			};
			const saturn = {
				start: createSaturn(startToi),
				middle: createSaturn(middleToi),
				end: createSaturn(endToi)
			};
			const uranus = {
				start: createUranus(startToi),
				middle: createUranus(middleToi),
				end: createUranus(endToi)
			};
			const neptune = {
				start: createNeptune(startToi),
				middle: createNeptune(middleToi),
				end: createNeptune(endToi)
			};
			
			let moonStart = await moon.start.getApparentTopocentricHorizontalCoordinates(coordinates);
			moonStart['illuminatedFraction'] = await moon.start.getIlluminatedFraction();
			moonStart['isWaxing'] = await moon.start.isWaxing();
			
			const celestialBodiesInformation = {
				moon: {
					start: moonStart,
					middle: await moon.middle.getApparentTopocentricHorizontalCoordinates(coordinates),
					end: await moon.end.getApparentTopocentricHorizontalCoordinates(coordinates),
				},
				mercury: {
					start: await mercury.start.getApparentTopocentricHorizontalCoordinates(coordinates),
					middle: await mercury.middle.getApparentTopocentricHorizontalCoordinates(coordinates),
					end: await mercury.end.getApparentTopocentricHorizontalCoordinates(coordinates),
				},
				venus: {
					start: await venus.start.getApparentTopocentricHorizontalCoordinates(coordinates),
					middle: await venus.middle.getApparentTopocentricHorizontalCoordinates(coordinates),
					end: await venus.end.getApparentTopocentricHorizontalCoordinates(coordinates),
				},
				mars: {
					start: await mars.start.getApparentTopocentricHorizontalCoordinates(coordinates),
					middle: await mars.middle.getApparentTopocentricHorizontalCoordinates(coordinates),
					end: await mars.end.getApparentTopocentricHorizontalCoordinates(coordinates),
				},
				jupiter: {
					start: await jupiter.start.getApparentTopocentricHorizontalCoordinates(coordinates),
					middle: await jupiter.middle.getApparentTopocentricHorizontalCoordinates(coordinates),
					end: await jupiter.end.getApparentTopocentricHorizontalCoordinates(coordinates),
				},
				saturn: {
					start: await saturn.start.getApparentTopocentricHorizontalCoordinates(coordinates),
					middle: await saturn.middle.getApparentTopocentricHorizontalCoordinates(coordinates),
					end: await saturn.end.getApparentTopocentricHorizontalCoordinates(coordinates),
				},
				uranus: {
					start: await uranus.start.getApparentTopocentricHorizontalCoordinates(coordinates),
					middle: await uranus.middle.getApparentTopocentricHorizontalCoordinates(coordinates),
					end: await uranus.end.getApparentTopocentricHorizontalCoordinates(coordinates),
				},
				neptune: {
					start: await neptune.start.getApparentTopocentricHorizontalCoordinates(coordinates),
					middle: await neptune.middle.getApparentTopocentricHorizontalCoordinates(coordinates),
					end: await neptune.end.getApparentTopocentricHorizontalCoordinates(coordinates),
				},
			};
			
			// Removing any unwanted celestial bodies
			Object.keys(celestialBodiesInformation).filter((i) => props.celestialBodies.indexOf(i) === -1).forEach((i) => { delete celestialBodiesInformation[i]; });
			
			// Convert to int
			Object.keys(celestialBodiesInformation).forEach((object) => {
				Object.keys(celestialBodiesInformation[object]).forEach((time) => {
					delete celestialBodiesInformation[object][time]['radiusVector'];
					Object.keys(celestialBodiesInformation[object][time]).forEach((property) => {
						if (['illuminatedFraction', 'isWaxing'].indexOf(property) === -1) {
							celestialBodiesInformation[object][time][property] = parseInt(celestialBodiesInformation[object][time][property]);
						}
					});
				});
			});

			Object.keys(celestialBodiesInformation).forEach((object) => {

				var azimuthDegrees = [];
				var angleDegrees = [];
				var altitudes = [];
				var times = [];
				
				var dateTime = moment(utcStartDateTime);
				while (dateTime <= moment(utcEndDateTime)) {
					times.push({
						time: dateTime.format('HH:mm'),
					});
					dateTime = dateTime.add(1, 'minute');
				}
				for (var i = 0; i < times.length; i++) times[i].percentage = parseFloat((i / times.length).toFixed(2));
				
				// Getting each degree of viewable area
				for	(var ang = angle; ang <= angle + arcLength; ang++) {
					angleDegrees.push({
						value: ang,
						percentage: parseFloat(((ang - angle) / arcLength).toFixed(2))
					});
				}
				angleDegrees.sort((a, b) => a.percentage - b.percentage);
				
				// Getting each azimuth degree that exists between the start/end times
				for	(var azimuth = celestialBodiesInformation[object].start.azimuth; azimuth <= celestialBodiesInformation[object].end.azimuth; azimuth++) {
					
					// Because sometimes the starting angle is below 0, let's add 360 to every value so we're always comparing between 360 and 720
					var adjusted = azimuth;
					if (isAngleBelowZero) {
						while (adjusted > 360) adjusted -= 360; adjusted += 360;
					}
					
					azimuthDegrees.push({
						value: adjusted,
						percentage: parseFloat(((azimuth - celestialBodiesInformation[object].start.azimuth) / (celestialBodiesInformation[object].end.azimuth - celestialBodiesInformation[object].start.azimuth)).toFixed(2))
					});
					
				}
				azimuthDegrees.sort((a, b) => a.percentage - b.percentage);

				// Getting each estimated altitude degree that exists between the start/end times
				// (This is estimated because we actually only get 3 values, the start/middle/end. We will estimate the rest)
				if (celestialBodiesInformation[object].start.altitude < celestialBodiesInformation[object].middle.altitude) {
					for	(var altitude = celestialBodiesInformation[object].start.altitude; altitude < celestialBodiesInformation[object].middle.altitude; altitude++) {
						altitudes.push({
							value: altitude,
						});
					}
				}
				else {
					for	(var altitude = celestialBodiesInformation[object].start.altitude; altitude > celestialBodiesInformation[object].middle.altitude; altitude--) {
						altitudes.push({
							value: altitude,
						});
					}
				}
				if (celestialBodiesInformation[object].middle.altitude <= celestialBodiesInformation[object].end.altitude) {
					for	(var altitude = celestialBodiesInformation[object].middle.altitude; altitude <= celestialBodiesInformation[object].end.altitude; altitude++) {
						altitudes.push({
							value: altitude,
						});
					}
				}
				else {
					for	(var altitude = celestialBodiesInformation[object].middle.altitude; altitude >= celestialBodiesInformation[object].end.altitude; altitude--) {
						altitudes.push({
							value: altitude,
						});
					}
				}
				for (var i = 0; i < altitudes.length; i++) {
					altitudes[i].percentage = parseFloat((i / (altitudes.length - 1)).toFixed(2));
				}
				altitudes.sort((a, b) => a.percentage - b.percentage);
				
				for (var i = 0; i < times.length; i++) {
					
					const azimuthMatches = azimuthDegrees.filter(j => j.percentage >= times[i].percentage);
					const altitudeMatches = altitudes.filter(j => j.percentage >= times[i].percentage);
					
					var azimuthMatch = azimuthMatches.length > 0 ? azimuthMatches[0].value : 0;
					// Because sometimes the starting angle is below 0, let's add 360 to every value so we're always comparing between 360 and 720
					if (!isAngleBelowZero) {
						while (azimuthMatch > 360) azimuthMatch -= 360; azimuthMatch += 360;
					}
					
					var altitudeMatch = altitudeMatches.length > 0 ? altitudeMatches[0].value : -1;
					
					times[i].azimuth = azimuthMatch;
					times[i].altitude = altitudeMatch;
					times[i].isAboveHorizon = times[i].altitude >= minimumAltitude;
					times[i].isVisible = times[i].azimuth >= angle && times[i].azimuth <= angle + props.arcLength && times[i].isAboveHorizon;
	
/*	
console.log({
	time: times[i].time,
	azimuth: times[i].azimuth,
	angle: angle
});
*/
										
				}
				
				const visibleTimes = times.filter(i => i.isVisible);
				var visibleStartTime = visibleTimes.length > 0 ? visibleTimes[0].time : null;
				var visibleEndTime = visibleTimes.length > 0 ? visibleTimes[visibleTimes.length - 1].time : null;
				
				if (visibleStartTime && visibleStartTime === visibleEndTime) {
					visibleStartTime = null;
					visibleEndTime = null;
				}
				
				celestialBodiesInformation[object].start.time = visibleStartTime;
				celestialBodiesInformation[object].end.time = visibleEndTime;
				celestialBodiesInformation[object].visible = celestialBodiesInformation[object].start.time !== null && celestialBodiesInformation[object].end.time !== null && celestialBodiesInformation[object].start.time !== celestialBodiesInformation[object].end.time;
				
			});
			
			setCelestialBodies({
				utcDateTimes: {
					start: utcStartDateTime,
					end: utcEndDateTime
				},
				coordinates,
				angle,
				arcLength,
				minimumAltitude,
				items: celestialBodiesInformation
			});
			
		}
		
		const utcStartDateTime = new Date(`${props.date} ${props.startTime}`);

		const utcEndDateTime = new Date(`${props.date} ${props.endTime}`);
		if (utcEndDateTime < utcStartDateTime) utcEndDateTime.setDate(utcEndDateTime.getDate() + 1);

		getCelestialBodies(utcStartDateTime, utcEndDateTime, props.coordinates, props.angle, props.arcLength, props.minimumAltitude).catch(console.error);
		
	}, [props.date, props.startTime, props.endTime, props.coordinates, props.angle, props.arcLength, props.minimumAltitude, props.celestialBodies]);
	
	useEffect(() => {
		
		if (props.weather) {
			
			if (props.date >= moment().format('YYYY-MM-DD')) {
				
				var averageTemp = 0;
				var averagePrecipitation = 0;
				var averageHumidity = 0;
				var averageCloudCoverage = 0;
				var conditions = [];
				
				const startHour = parseInt(props.startTime.split(':')[0]);
				const endHour = parseInt(props.endTime.split(':')[0]);
				for (var hour = startHour; hour <= endHour; hour++) {
					averageTemp += props.weather.hour[hour].temp_f;
					averagePrecipitation += props.weather.hour[hour].precip_in;
					averageHumidity += props.weather.hour[hour].humidity;
					averageCloudCoverage += props.weather.hour[hour].cloud;
					
					if (conditions.filter(i => i.name === props.weather.hour[hour].condition.text).length === 0) {
						conditions.push({
							name: props.weather.hour[hour].condition.text,
							quantity: 0
						});
					}
					conditions.filter(i => i.name === props.weather.hour[hour].condition.text)[0].quantity++;
					
				}
				conditions.sort((a, b) => b.quantity - a.quantity);
				
				const temp = parseInt(averageTemp / (endHour - startHour + 1));
				const precipitation = parseFloat((averagePrecipitation / (endHour - startHour + 1)).toFixed(1));
				const humidity = parseInt(averageHumidity / (endHour - startHour + 1));
				const cloudCoverage = parseInt(averageCloudCoverage / (endHour - startHour + 1));
				const condition = conditions.length > 0 ? conditions[0].name.toLowerCase().trim() : '';
				
				// https://www.weatherapi.com/docs/#weather-icons
				let icon = null;
				switch (condition) {
					
					case 'blizzard':
					case 'blowing snow':
					case 'ice pellets':
						icon = <FontAwesomeIcon icon={faSnowflake} style={{ color: 'silver', marginTop: 7 }} />;
						break;

					case 'clear':
					case 'sunny':
						icon = <FontAwesomeIcon icon={faMoon} style={{ color: 'orange', marginTop: 7 }} />;
						break;

					case 'cloudy':
					case 'partly cloudy':
					case 'fog':
					case 'freezing fog':
					case 'mist':
					case 'overcast':
						icon = <FontAwesomeIcon icon={faCloud} style={{ color: 'gray', marginTop: 7 }} />;
						break;

					case 'freezing drizzle':
					case 'light drizzle':
					case 'light freezing rain':
					case 'light rain':
					case 'light rain shower':
					case 'light showers of ice pellets':
					case 'light sleet':
					case 'light sleet showers':
					case 'light snow':
					case 'light snow showers':
					case 'patchy freezing drizzle possible':
					case 'patchy light drizzle':
					case 'patchy light rain':
					case 'patchy light rain with thunder':
					case 'patchy light snow':
					case 'patchy light snow with thunder':
					case 'patchy rain nearby':
					case 'patchy rain possible':
					case 'patchy sleet possible':
					case 'patchy snow possible':
						icon = <FontAwesomeIcon icon={faCloudRain} style={{ color: 'cornflowerblue', marginTop: 7 }} />;
						break;

					case 'heavy freezing drizzle':
					case 'heavy rain':
					case 'heavy rain at times':
					case 'heavy snow':
					case 'moderate or heavy freezing rain':
					case 'moderate or heavy rain shower':
					case 'moderate or heavy rain with thunder':
					case 'moderate or heavy showers of ice pellets':
					case 'moderate or heavy sleet':
					case 'moderate or heavy sleet showers':
					case 'moderate or heavy snow showers':
					case 'moderate or heavy snow with thunder':
					case 'moderate rain':
					case 'moderate rain at times':
					case 'moderate snow':
					case 'patchy heavy snow':
					case 'patchy moderate snow':
					case 'thundery outbreaks possible':
					case 'torrential rain shower':
						icon = <FontAwesomeIcon icon={faCloudShowersHeavy} style={{ color: 'blue', marginTop: 7 }} />;
						break;

					default:
						console.warn(`Unhandled forecast: ${condition}`);
						break;
					
				}
				
				setWeather({
					temp,
					precipitation,
					humidity,
					cloudCoverage,
					condition,
					icon
				});
				
			}

		}
		
	}, [props.weather]);
	
	var calcDropdown = <Dropdown className='dayNumber'>
		<Dropdown.Toggle variant='link' id='dropdown-basic'>
			{moment(props.date).format('D')}
		</Dropdown.Toggle>
		<Dropdown.Menu>
			<Dropdown.Item href={`https://www.mooncalc.org/#/${props.coordinates.lat},${props.coordinates.lon},19/${props.date.replaceAll('-', '.')}/${props.startTime}/1/3`} target='external'>View Moon Position</Dropdown.Item>
			<Dropdown.Item href={`https://www.planetscalc.org/#/${props.coordinates.lat},${props.coordinates.lon},19/${props.date.replaceAll('-', '.')}/${props.startTime}/0/3`} target='external'>View Mercury Position</Dropdown.Item>
			<Dropdown.Item href={`https://www.planetscalc.org/#/${props.coordinates.lat},${props.coordinates.lon},19/${props.date.replaceAll('-', '.')}/${props.startTime}/1/3`} target='external'>View Venus Position</Dropdown.Item>
			<Dropdown.Item href={`https://www.planetscalc.org/#/${props.coordinates.lat},${props.coordinates.lon},19/${props.date.replaceAll('-', '.')}/${props.startTime}/2/3`} target='external'>View Mars Position</Dropdown.Item>
			<Dropdown.Item href={`https://www.planetscalc.org/#/${props.coordinates.lat},${props.coordinates.lon},19/${props.date.replaceAll('-', '.')}/${props.startTime}/3/3`} target='external'>View Jupiter Position</Dropdown.Item>
			<Dropdown.Item href={`https://www.planetscalc.org/#/${props.coordinates.lat},${props.coordinates.lon},19/${props.date.replaceAll('-', '.')}/${props.startTime}/4/3`} target='external'>View Saturn Position</Dropdown.Item>
			<Dropdown.Item href={`https://www.planetscalc.org/#/${props.coordinates.lat},${props.coordinates.lon},19/${props.date.replaceAll('-', '.')}/${props.startTime}/5/3`} target='external'>View Uranus Position</Dropdown.Item>
			<Dropdown.Item href={`https://www.planetscalc.org/#/${props.coordinates.lat},${props.coordinates.lon},19/${props.date.replaceAll('-', '.')}/${props.startTime}/6/3`} target='external'>View Neptune Position</Dropdown.Item>
		</Dropdown.Menu>
	</Dropdown>;
	
	var calcDropdown2 = <span className={`small ${(isToday ? 'fw-bold' : '')}`}>
		{moment(props.date).format('D')}
	</span>;
	
	if (!celestialBodies) return null;
	
	var visibleCelestialBodies = Object.keys(celestialBodies.items).filter((l) => celestialBodies.items[l].visible);
	
	var handleTdClick = (e) => {
		
		if (e.target.classList.contains('btn-close')) return;
		if (e.target.classList.contains('btn-primary')) return;
		
		props.showModal(props.date, celestialBodies.items, weather);
		
	};
	
	return (
		<td className={`day${(props.date === moment().format('YYYY-MM-DD') ? ' today' : '')}${Object.keys(celestialBodies.items).filter((l) => celestialBodies.items[l].visible).length > 0 ? ' hasCelestialBodies' : ''}`} onClick={handleTdClick}>
			<div className='d-flex justify-content-between align-items-center'>
				<div>
					{weather && !props.isMobile && <OverlayTrigger overlay={<Popover id='popover-basic'>
						<Popover.Body>
							<div className='text-center fw-bold border-bottom mb-1'>{weather.condition.toLowerCase().replace(/(?:^|\s)\w/g, function(match) { return match.toUpperCase(); })}</div>
							<table>
								<tbody>
									<tr className={(weather.cloudCoverage <= 33 ? 'text-success' : (weather.cloudCoverage <= 66 ? 'text-warning' : 'text-danger'))}>
										<td>Cloud Coverage: </td>
										<td className='text-end'>{weather.cloudCoverage}%</td>
									</tr>
									<tr className={(weather.precipitation <= 0.2 ? 'text-success' : (weather.precipitation <= 0.5 ? 'text-warning' : 'text-danger'))}>
										<td>Precipitation: </td>
										<td className='text-end'>{(weather.precipitation > 0 ? `${weather.precipitation} inches` : 'None')}</td>
									</tr>
									<tr className={(weather.humidity <= 70 ? 'text-success' : (weather.humidity <= 80 ? 'text-warning' : 'text-danger'))}>
										<td>Humidity: </td>
										<td className='text-end'>{weather.humidity}%</td>
									</tr>
									<tr className={(weather.temp <= 50 ? 'text-danger' : (weather.temp <= 85 ? 'text-success' : 'text-danger'))}>
										<td>Temp: </td>
										<td className='text-end'>{weather.temp}º</td>
									</tr>
								</tbody>
							</table>
						</Popover.Body>
					</Popover>}>
						{weather.icon}
					</OverlayTrigger>}
					{weather && props.isMobile && weather.icon}
				</div>
				<div>
					{isToday ? <OverlayTrigger overlay={<Tooltip>Today</Tooltip>}>
						{calcDropdown2}
					</OverlayTrigger> : calcDropdown2}
				</div>
			</div>
			{celestialBodies ? <div className='celestialBodies'>
				{visibleCelestialBodies.length > 0 ? visibleCelestialBodies.map((l) => {
					return <div key={l}>
						<CelestialBody
							coordinates={props.coordinates}
							date={props.date}
							startTime={props.startTime}
							celestialBody={l}
							values={celestialBodies.items[l]}
							isMobile={props.isMobile}
						/>
					</div>;
				}) : <div className='none'>
						Nothing<br />Visible
					</div>}
			</div> : null}
			
		</td>
	);
}

export default CalendarDay;