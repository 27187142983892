import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App/App';
import reportWebVitals from './reportWebVitals';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App/Assets/Styles/App.css';
import './App/Assets/Styles/Mobile.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

const useStrictMode = !true; // https://stackoverflow.com/a/72238236
if (useStrictMode) {
	root.render(
		<React.StrictMode>
			<App />
		</React.StrictMode>
	);
}
else {
	root.render(
		<App />
	);
}

// If you want to start measuring performance in your app, pass a function to log results (for example: reportWebVitals(console.log)) or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();