import {
	useState,
	useEffect
} from 'react';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import * as moment from 'moment';
import CalendarDay from './CalendarDay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';

import MoonFull from '../Assets/Images/Moon_Full.png';
import MoonNew from '../Assets/Images/Moon_New.png';
import MoonQuarterFirst from '../Assets/Images/Moon_Quarter_First.png';
import MoonQuarterThird from '../Assets/Images/Moon_Quarter_Third.png';
import MoonWaningCrescent from '../Assets/Images/Moon_Waning_Crescent.png';
import MoonWaningGibbous from '../Assets/Images/Moon_Waning_Gibbous.png';
import MoonWaxingCrescent from '../Assets/Images/Moon_Waxing_Crescent.png';
import MoonWaxingGibbous from '../Assets/Images/Moon_Waxing_Gibbous.png';
import Mercury from '../Assets/Images/Mercury.png';
import Venus from '../Assets/Images/Venus.png';
import Mars from '../Assets/Images/Mars.png';
import Jupiter from '../Assets/Images/Jupiter.png';
import Saturn from '../Assets/Images/Saturn.png';
import Uranus from '../Assets/Images/Uranus.png';
import Neptune from '../Assets/Images/Neptune.png';

import Unknown from '../Assets/Images/Unknown.png';

// import * as fa from '@fortawesome/free-solid-svg-icons';
// Object.keys(fa).forEach((icon) => {
	// if (icon.toLowerCase().indexOf('caret') !== -1) {
		// console.log(`<FontAwesomeIcon icon={fa.${icon}} />`);
	// }
// });

// https://www.weatherapi.com/api-explorer.aspx#forecast
// https://stackoverflow.com/a/53059152
const useGetWeather = (coordinates) => {
	
	/*
	Here are some other weather APIs
	
	https://api.met.no/weatherapi/locationforecast/2.0/documentation
	https://www.weather.gov/documentation/services-web-api
	https://www.visualcrossing.com/weather-data-editions
	https://openweathermap.org/price
	
	*/
	
	const lat = parseFloat(coordinates.lat.toFixed(2));
	const lon = parseFloat(coordinates.lon.toFixed(2));
	
	const [data, setData] = useState(null);

	const weatherApiKey = '6356a823b8464c2aaa411008231109';
	const daysToGet = 10;

	useEffect(() => {
		
		var cacheKey = `weather(${lat},${lon})`;
		
		async function fetchData() {
			try {
				
				const url = `https://api.weatherapi.com/v1/forecast.json?key=${weatherApiKey}&q=${lat},${lon}&days=${daysToGet}&aqi=yes&alerts=yes`;

				const response = await fetch(url);
				const json = await response.json();
				//console.log('Got weather from API', json);
				
				setData(json);
				window.localStorage.setItem(cacheKey, JSON.stringify({
					data: JSON.stringify(json),
					dateTime: new Date()
				}));

			}
			catch {
				console.error('There was an error fetching the weather');
			}
		}
		
		function cleanUpOld() {			
			//console.log('Cleaning up old weather models');

			Object.keys(window.localStorage).forEach(key => {
				if (key.toLowerCase().trim().substr(0, 8) === 'weather(') {

					var secondsSinceLastCached = (new Date() - new Date(JSON.parse(window.localStorage[key]).dateTime)) / 1000;
					
					if (secondsSinceLastCached >= 60 * 60) {
						//console.log(`Deleting: ${key}`);
						window.localStorage.removeItem(key);
					}

				}
			});
		}
		
		cleanUpOld();
	
		const cachedValue = window.localStorage.getItem(cacheKey);
		if (!cachedValue) {
			try {
				
				const json = JSON.parse(cachedValue);
				
				var secondsSinceLastCached = (new Date() - new Date(json.dateTime)) / 1000;
				
				if (secondsSinceLastCached < 60 * 60) {
					//console.log('Got weather from cache', JSON.parse(json.data));
					setData(JSON.parse(json.data));
				}
				else {
					fetchData();
				}

			}
			catch (e) {
				fetchData();
			}			
		}
		else {
			fetchData();
		}
			
	}, [lat, lon]);
	
	return data;
};

function Calendar(props) {
	
	const [modalDate, setModalDate] = useState(null);
	const [modalCelestialBodies, setModalCelestialBodies] = useState(null);
	const [modalWeather, setModalWeather] = useState(null);

	const previousMonth = {
		year: parseInt(moment(new Date(props.year, props.month - 1, 1)).add(-1, 'M').format('YYYY')),
		month: parseInt(moment(new Date(props.year, props.month - 1, 1)).add(-1, 'M').format('M'))
	};
		
	const nextMonth = {
		year: parseInt(moment(new Date(props.year, props.month - 1, 1)).add(1, 'M').format('YYYY')),
		month: parseInt(moment(new Date(props.year, props.month - 1, 1)).add(1, 'M').format('M'))
	};

	const daysInMonth = new Date(props.year, props.month, 0).getDate();
	var days = [];
	for (var day = 1; day <= daysInMonth; day++) days.push(day);
	
	const weather = useGetWeather(props.coordinates);
	
	const [showModal, setShowModal] = useState(false);

	var rows = [];
	rows.push([]);
	for (var i = 0; i < moment(new Date(props.year, props.month - 1, 1)).day(); i++) {
		rows[rows.length - 1].push(<td className='day emptyDay' key={i}></td>);
	}
	days.forEach((day) => {
		
		const date = moment(new Date(props.year, props.month - 1, day));
		if (date.day() === 0) rows.push([]);
		
		rows[rows.length - 1].push(<CalendarDay
			date={date.format('YYYY-MM-DD')}
			startTime={props.startTime}
			endTime={props.endTime}
			celestialBodies={props.celestialBodies}
			coordinates={props.coordinates}
			angle={props.angle}
			arcLength={props.arcLength}
			minimumAltitude={props.minimumAltitude}
			weather={weather && weather.forecast.forecastday.filter(i => i.date === date.format('YYYY-MM-DD')).length > 0 ? weather.forecast.forecastday.filter(i => i.date === date.format('YYYY-MM-DD'))[0] : null}
			slices={props.slices}
			isMobile={props.isMobile}
			showModal={(date, celestialBodies, weather) => {
				
				setModalDate(date);
				setModalCelestialBodies(celestialBodies);
				setModalWeather(weather);

				setShowModal(true);
				
			}}
			key={date.format('YYYY-MM-DD')}
		/>);

	})
	for (var j = moment(new Date(props.year, props.month, 0)).day() + 1; j < 7; j++) {
		rows[rows.length - 1].push(<td className='day emptyDay' key={j}></td>);
	}
	
	var planetsCalcMapping = {
		'mercury': 0,
		'venus': 1,
		'mars': 2,
		'jupiter': 3,
		'saturn': 4,
		'uranus': 5,
		'neptune': 6
	};
	
	var calculateUrl = (planet) => {

		planet = planet.toLowerCase().trim();

		if (planet === 'moon')
			return `https://www.mooncalc.org/#/${props.coordinates.lat},${props.coordinates.lon},19/${modalDate.replaceAll('-', '.')}/${props.startTime}/1/3`;
		else
			return `https://www.planetscalc.org/#/${props.coordinates.lat},${props.coordinates.lon},19/${modalDate.replaceAll('-', '.')}/${props.startTime}/${planetsCalcMapping[planet]}/3`;

	}
	
	var getCelestialBodyIcon = (name, model) => {
		
		switch (name.toLowerCase().trim()) {
			
			case 'mercury':
				return Mercury;
			case 'venus':
				return Venus;
			case 'mars':
				return Mars;
			case 'jupiter':
				return Jupiter;
			case 'saturn':
				return Saturn;
			case 'uranus':
				return Uranus;
			case 'neptune':
				return Neptune;
			
			default: // Moon
			
				switch (model.start.isWaxing) {
					
					case true:
						
						if (model.start.illuminatedFraction < 0.01)
							return MoonNew;
						else if (model.start.illuminatedFraction < 0.44)
							return MoonWaxingCrescent;
						else if (model.start.illuminatedFraction < 0.55)
							return MoonQuarterFirst;
						else if (model.start.illuminatedFraction < 0.99)
							return MoonWaxingGibbous;
						else
							return MoonFull;
						
						break;
					
					default: // false

						if (model.start.illuminatedFraction < 0.01)
							return MoonNew;
						else if (model.start.illuminatedFraction < 0.44)
							return MoonWaningCrescent;
						else if (model.start.illuminatedFraction < 0.55)
							return MoonQuarterThird;
						else if (model.start.illuminatedFraction < 0.99)
							return MoonWaningGibbous;
						else
							return MoonFull;

						break;
					
				}

		}

	}
	var getCelestialBodyNote = (name, model) => {
		
		if (name.toLowerCase().trim() !== 'moon') return null;
		
		switch (model.start.isWaxing) {
			
			case true:
				
				if (model.start.illuminatedFraction < 0.01)
					return 'New Moon';
				else if (model.start.illuminatedFraction < 0.44)
					return 'Waxing Crescent';
				else if (model.start.illuminatedFraction < 0.55)
					return 'First Quarter';
				else if (model.start.illuminatedFraction < 0.99)
					return 'Waxing Gibbous';
				else
					return 'Full Moon';
				
				break;
			
			default: // false

				if (model.start.illuminatedFraction < 0.01)
					return 'New Moon';
				else if (model.start.illuminatedFraction < 0.44)
					return 'Waning Crescent';
				else if (model.start.illuminatedFraction < 0.55)
					return 'Third Quarter';
				else if (model.start.illuminatedFraction < 0.99)
					return 'Waning Gibbous';
				else
					return 'Full Moon';

				break;
			
		}
			
	}
	
	var getYears = (yearCount) => {
		
		if (yearCount === undefined) yearCount = 10;
		
		var startYear = parseInt(moment().format('YYYY'));
		
		var years = [];
		for (var i = 0; i < yearCount; i++) {
			years.push(startYear + i);
		}
		
		return years;
		
	};
		
	return (
		<div>
			<Card>
				<Card.Header className='d-flex justify-content-between align-items-center text-center'>
					<Button size='lg' variant='outline-dark' onClick={(e) => {
						e.preventDefault();
						props.onChange(previousMonth);
					}}>
						<FontAwesomeIcon icon={faCaretLeft} />
					</Button>
					<div>
					
						<div>
							<InputGroup size='lg'>
								<Form.Select value={props.month} onChange={(e) => {
									
									var month = parseInt(e.target.value);
									var year = props.year;
									
									props.onChange({
										year,
										month
									});
									
								}}>
									<option value={1}>Jan</option>
									<option value={2}>Feb</option>
									<option value={3}>Mar</option>
									<option value={4}>Apr</option>
									<option value={5}>May</option>
									<option value={6}>Jun</option>
									<option value={7}>Jul</option>
									<option value={8}>Aug</option>
									<option value={9}>Sep</option>
									<option value={10}>Oct</option>
									<option value={11}>Nov</option>
									<option value={12}>Dec</option>
								</Form.Select>
								<Form.Select value={props.year} onChange={(e) => {
									
									var month = props.month;
									var year = parseInt(e.target.value);
									
									props.onChange({
										year,
										month
									});
									
								}}>
									{getYears().map(y => <option value={y} key={y}>{y}</option>)}
								</Form.Select>
							</InputGroup>
						</div>
						<div>
							<Button variant='link' size='sm' className='mb-0' onClick={() => {
								
								var month = parseInt(moment().format('M'));
								var year = parseInt(moment().format('YYYY'));
								
								props.onChange({
									year,
									month
								});

							}}>Current Month</Button>
						</div>
						<label className='small text-secondary' style={{}}>{(props.preset ? `${props.preset} - ` : '')}{moment(`2000-01-01 ${props.startTime}`, 'YYYY-MM-DD h:mm:ss A').format('h:mmA')}-{moment(`2000-01-01 ${props.endTime}`, 'YYYY-MM-DD h:mm:ss A').format('h:mmA')}</label>
					</div>
					<Button size='lg' variant='outline-dark' onClick={(e) => {
						e.preventDefault();
						props.onChange(nextMonth);
					}}>
						<FontAwesomeIcon icon={faCaretRight} />
					</Button>
				</Card.Header>
				<Card.Body style={{ padding: 0 }}>

					<div>
						<Table className={`table table-bordered calendar mb-0${(props.isMobile ? ' mobile' : '')}`}>
							<thead>
								<tr>
									<th style={{ width: '14.2857%' }}>Sun</th>
									<th style={{ width: '14.2857%' }}>Mon</th>
									<th style={{ width: '14.2857%' }}>Tue</th>
									<th style={{ width: '14.2857%' }}>Wed</th>
									<th style={{ width: '14.2857%' }}>Thu</th>
									<th style={{ width: '14.2857%' }}>Fri</th>
									<th style={{ width: '14.2857%' }}>Sat</th>
								</tr>
							</thead>
							<tbody>
								{rows.map((row, k) => <tr key={k}>
									{row.map((day) => {
										return day;
									})}
								</tr>)}
							</tbody>
						</Table>
					</div>

				</Card.Body>
			</Card>
			<Modal
				size='lg'
				show={showModal}
				onHide={() => { setShowModal(false); }}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{moment(modalDate).format('dddd, MMMM D, YYYY')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className=''>
				
					<div className='row'>
						<div className='col-12 col-lg-6'>
							<div className='card mb-2'>
								<div className='card-header d-flex justify-content-between align-items-center'>
									<div>Weather</div>
									<a href={`https://www.wunderground.com/hourly/${props.coordinates.lat.toFixed(2)},${props.coordinates.lon.toFixed(2)}/date/${modalDate}`} target='external' className='btn btn-sm btn-outline-dark'>
										Hourly
									</a>
								</div>
								{modalWeather ? <table className='table table-bordered mb-0'>
									<tbody>
										<tr className={`table-${['sunny','clear'].indexOf(modalWeather.condition.toLowerCase().trim()) !== -1 ? 'success' : 'danger'} small`}>
											<td style={{ width: 150 }}>Description</td>
											<td className='text-end'>{modalWeather.condition.toLowerCase().replace(/(?:^|\s)\w/g, function(match) { return match.toUpperCase(); })}</td>
										</tr>
										<tr className={(modalWeather.cloudCoverage <= 33 ? 'small table-success' : (modalWeather.cloudCoverage <= 66 ? 'small table-warning' : 'small table-danger'))}>
											<td>Cloud Coverage</td>
											<td className='text-end'>{modalWeather.cloudCoverage}%</td>
										</tr>
										<tr className={(modalWeather.precipitation <= 0.2 ? 'small table-success' : (modalWeather.precipitation <= 0.5 ? 'small table-warning' : 'small table-danger'))}>
											<td>Precipitation</td>
											<td className='text-end'>{modalWeather.precipitation ? `${modalWeather.precipitation} in` : 'None'}</td>
										</tr>
										<tr className={(modalWeather.humidity <= 70 ? 'small table-success' : (modalWeather.humidity <= 80 ? 'small table-warning' : 'small table-danger'))}>
											<td>Humidity</td>
											<td className='text-end'>{modalWeather.humidity}%</td>
										</tr>
										<tr className={(modalWeather.temp <= 50 ? 'small table-danger' : (modalWeather.temp <= 85 ? 'small table-success' : 'small table-danger'))}>
											<td>Temperature</td>
											<td className='text-end'>{modalWeather.temp}&deg;</td>
										</tr>
									</tbody>
								</table> : <div className='card-body text-center text-muted small'>
									{modalDate < moment().format('YYYY-MM-DD') ? 'This day is in the past.' : 'The date is too far in the future for the weather to be accurate.'}
								</div>}
							</div>
						</div>
						<div className='col-12 col-lg-6'>
							<div className='card'>
								<div className='card-header'>Celestial Bodies</div>
								<table className='table table-bordered mb-0'>
									<tbody>
										{modalCelestialBodies && Object.keys(modalCelestialBodies).map((key, i) => <tr className={modalCelestialBodies[key].start.time ? 'table-success small' : 'table-danger small'} key={i}>
											<td className='text-center' style={{ width: 50 }}>
												<img src={getCelestialBodyIcon(key, modalCelestialBodies[key])} title={`${key.substr(0, 1).toUpperCase()}${key.substr(1)}`} alt={`${key.substr(0, 1).toUpperCase()}${key.substr(1)}`} style={{ height: 24 }} />
											</td>
											<td style={{ width: 110 }}>
												<div><a href={calculateUrl(key)} target='external'>{key.substr(0, 1).toUpperCase()}{key.substr(1)}</a></div>
												<div className='small text-muted'>{getCelestialBodyNote(key, modalCelestialBodies[key])}</div>
											</td>
											{modalCelestialBodies[key].start.time ?
												<td className='small text-end'>
													Visible from {(moment(`${moment().format('YYYY-MM-DD')} ${modalCelestialBodies[key].start.time}`).format('h:mm A'))}<br />until {(moment(`${moment().format('YYYY-MM-DD')} ${modalCelestialBodies[key].end.time}`).format('h:mm A'))}
												</td> :
												<td className='small text-muted text-end'>
													Nothing Visible
												</td>
											}
										</tr>)}
									</tbody>
								</table>
							</div>
						</div>
					</div>
							
				</Modal.Body>
				<Modal.Footer>
				
					<Button
						variant='primary'
						onClick={() => { setShowModal(false); }}
					>
						Close
					</Button>
					
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export default Calendar;