import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { faCog as faSettings, faCircleQuestion as faWhatIsThis } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';

import Ad from './Components/Ad';
import Calendar from './Components/Calendar';
import Map from './Components/Map';
import IconViewer from './Components/IconViewer';

import favicon from './Assets/Icons/jupiter.ico';

import Logo from './Assets/Images/Jupiter.png';

function App() {
	
	const [width, setWidth] = useState(window.innerWidth);
	const [viirsArray, setViirsArray] = useState(null);
	const [coordinateViirs, setCoordinateViirs] = useState(null);
	const [slices] = useState(1); // Slices are the number of times to divide the startTime and endTime. So, for example, if the slice=1, then we'll get 3 times (0% (ie. startTime), 50%, and 100% (ie. endTime)). If there are 2 slices, we'll get 4 times (0% (ie. startTime), 33%, 66%, and 100% (ie. endTime))

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		}
	}, []);
	const isMobile = width <= 663;
	
	var hasCoordinates = window.localStorage.getItem('coordinates');
	var hasSeenAbout = window.localStorage.getItem('hasSeenAbout');
	
	var lsCelestialBodies = window.localStorage.getItem('celestialBodies');
	if (lsCelestialBodies) lsCelestialBodies = JSON.parse(lsCelestialBodies);
	const allCelestialBodies = [
		{ name: 'Moon', id: 1, enabled: lsCelestialBodies ? lsCelestialBodies.indexOf('moon') !== -1 : true },
		{ name: 'Mercury', id: 0, enabled: lsCelestialBodies ? lsCelestialBodies.indexOf('mercury') !== -1 : true },
		{ name: 'Venus', id: 1, enabled: lsCelestialBodies ? lsCelestialBodies.indexOf('venus') !== -1 : true },
		{ name: 'Mars', id: 2, enabled: lsCelestialBodies ? lsCelestialBodies.indexOf('mars') !== -1 : true },
		{ name: 'Jupiter', id: 3, enabled: lsCelestialBodies ? lsCelestialBodies.indexOf('jupiter') !== -1 : true },
		{ name: 'Saturn', id: 4, enabled: lsCelestialBodies ? lsCelestialBodies.indexOf('saturn') !== -1 : true },
		{ name: 'Uranus', id: 5, enabled: lsCelestialBodies ? lsCelestialBodies.indexOf('uranus') !== -1 : true },
		{ name: 'Neptune', id: 6, enabled: lsCelestialBodies ? lsCelestialBodies.indexOf('neptune') !== -1 : true },
	];
		
	const [showSettings, setShowSettings] = useState(hasCoordinates === null);
	const [showAbout, setShowAbout] = useState(hasSeenAbout === null);
	const [clearLocalStorageClicks, setClearLocalStorageClicks] = useState(0);

	const [isGettingCurrentLocation, setIsGettingCurrentLocation] = useState(false);
	
	const [duskJson, setDuskJson] = useState(null);

	const [presets, setPresets] = useState(null);
	const [preset, setPreset] = useState(window.localStorage.getItem('preset') ? window.localStorage.getItem('preset') : '');
	const [year, setYear] = useState(window.localStorage.getItem('year') ? parseInt(window.localStorage.getItem('year')) : new Date().getFullYear());
	const [month, setMonth] = useState(window.localStorage.getItem('month') ? parseInt(window.localStorage.getItem('month')) : new Date().getMonth() + 1);
	const [startTime, setStartTime] = useState(window.localStorage.getItem('startTime') && window.localStorage.getItem('startTime') !== null ? window.localStorage.getItem('startTime') : '20:00');
	const [endTime, setEndTime] = useState(window.localStorage.getItem('endTime') ? window.localStorage.getItem('endTime') : '23:00');
	const [dusk, setDusk] = useState(null);
	const [coordinates, setCoordinates] = useState(window.localStorage.getItem('coordinates') ? JSON.parse(window.localStorage.getItem('coordinates')) : { lat: 38.431633247608644, lon: -90.38682711124012 });
	const [zoom, setZoom] = useState(window.localStorage.getItem('zoom') ? parseInt(window.localStorage.getItem('zoom')) : 18);
	const [angle, setAngle] = useState(window.localStorage.getItem('angle') ? (isNaN(window.localStorage.getItem('angle')) ? 0 : parseInt(window.localStorage.getItem('angle'))) : 140);
	const [arcLength, setArcLength] = useState(window.localStorage.getItem('arcLength') ? (isNaN(window.localStorage.getItem('arcLength')) ? 0 : parseInt(window.localStorage.getItem('arcLength'))) : 50);
	const [minimumAltitude, setMinimumAltitude] = useState(window.localStorage.getItem('minimumAltitude') ? parseInt(window.localStorage.getItem('minimumAltitude')) : 0);
	const [celestialBodies, setCelestialBodies] = useState(allCelestialBodies.filter(i => i.enabled).map(q => q.name.toLowerCase()));
	const [bortlesScale, setBortlesScale] = useState(window.localStorage.getItem('bortlesScale') ? parseInt(window.localStorage.getItem('bortlesScale')) : null);
	const [image, setImage] = useState();
	const [context, setContext] = useState();
	
	const [submittedYear, setSubmittedYear] = useState(year);
	const [submittedMonth, setSubmittedMonth] = useState(month);
	const [submittedStartTime, setSubmittedStartTime] = useState(startTime);
	const [submittedEndTime, setSubmittedEndTime] = useState(endTime);
	const [submittedCoordinates, setSubmittedCoordinates] = useState(coordinates);
	const [submittedAngle, setSubmittedAngle] = useState(angle);
	const [submittedArcLength, setSubmittedArcLength] = useState(arcLength);
	const [submittedMinimumAltitude, setSubmittedMinimumAltitude] = useState(minimumAltitude);
	const [submittedCelestialBodies, setSubmittedCelestialBodies] = useState(celestialBodies);

	useEffect(() => {
		const fetchData = async () => {
			try {
			const response = await fetch('/SUOMI_VIIRS_C2_Global_7d.csv');
			const reader = response.body.getReader();
			const decoder = new TextDecoder('utf-8');

			let result = await reader.read();
			let text = decoder.decode(result.value || new Uint8Array(), {
				stream: !result.done,
			});

			let linesArray = text.split(/\r\n|\n/);

			while (!result.done) {
				result = await reader.read();
				text = decoder.decode(result.value || new Uint8Array(), {
				stream: !result.done,
				});
				linesArray = linesArray.concat(text.split(/\r\n|\n/));
			}

			setViirsArray(linesArray);
			
			} catch (error) {
			console.error('Error reading VIIRS file:', error);
			}
		};

		fetchData();
	}, []);

	// // Get VIIRS coordinates value
	// useEffect(() => {
		// if (!viirsArray) return;

		// var lat = parseFloat(coordinates.lat.toFixed(1));
		// var lng = parseFloat(coordinates.lon.toFixed(1));
		
		// var cacheKey = `viirs(${lat},${lng})`;

		// async function fetchData() {
			// try {
				
				// var items = viirsArray.filter(r => {

					// var segments = r.split(',');
					
					// var viirsLat = parseFloat(segments[0]).toFixed(1);
					// var viirsLng = parseFloat(segments[1]).toFixed(1);
					
					// return Math.abs(lat - viirsLat) < 1 && Math.abs(lng - viirsLng) < 1;
						
				// }).map(r => {
					
					// var segments = r.split(',');
					
					// var viirsLat = parseFloat(segments[0]).toFixed(1);
					// var viirsLng = parseFloat(segments[1]).toFixed(1);
					
					// var latDiff = Math.abs(lat - viirsLat);
					// var lngDiff = Math.abs(lng - viirsLng);;
					
					// segments.push(Math.sqrt((latDiff * latDiff) + (lngDiff * lngDiff)));
					
					// return segments;
										
				// });
				// items.sort((a, b) => a[13] - b[13]);

				// var closestMatch = items[0];
				// if (closestMatch === undefined) closestMatch = null;

				// var brightness = parseFloat(closestMatch ? closestMatch[2] : 208);
				// brightness = (brightness - 208) / 367;
				
				// window.localStorage.setItem(cacheKey, JSON.stringify({
					// data: brightness,
					// dateTime: new Date()
				// }));
				// setCoordinateViirs(brightness);
				
				// console.log(`Loaded VIIRS brightness (${brightness}) from API`);
				
			// }
			// catch (error) {
				// console.error('Error fetching VIIRS data:', error);
			// }
		// }
		
		// function cleanUpOld() {
			// Object.keys(window.localStorage).forEach(key => {
				// if (key.toLowerCase().trim().substr(0, 6) === 'viirs(') {

					// var secondsSinceLastCached = (new Date() - new Date(JSON.parse(window.localStorage[key]).dateTime)) / 1000;
					
					// if (secondsSinceLastCached >= 60 * 60 * 24 * 35) {
						// //console.log(`Deleting: ${key}`);
						// window.localStorage.removeItem(key);
					// }

				// }
			// });
		// }
		
		// cleanUpOld();
	
		// const cachedValue = window.localStorage.getItem(cacheKey);
		// if (!true && cachedValue) {
			
			// const json = JSON.parse(cachedValue);
			
			// var secondsSinceLastCached = (new Date() - new Date(json.dateTime)) / 1000;
			
			// if (secondsSinceLastCached < 60 * 60 * 24 * 35) {
				// console.log(`Loaded VIIRS brightness (${json.data}) from API`);
				// setCoordinateViirs(parseFloat(json.data));
			// }
			// else {
				// fetchData();
			// }
			
		// }
		// else {
			// fetchData();
		// }
				
	// }, [viirsArray, coordinates]);
  
	// useEffect(() => {
		// if (coordinateViirs) console.log('Brightness', coordinateViirs);
	// }, [coordinateViirs]);
	
	// Clear local storage
	useEffect(() => {
		
		if (clearLocalStorageClicks >= 5) {
			setClearLocalStorageClicks(0);
			
			if (window.confirm('Are you sure you want to reset Skygenda?')) {
				
				Object.keys(window.localStorage).forEach(key => {
					window.localStorage.removeItem(key);
				});
				console.log('Skygenda reset');

				alert('Skygenda has been reset. The page will refresh now');
				window.location.href = window.location.href;
				
			}
			
		}
	}, [clearLocalStorageClicks]);
	
	// Gets dusk JSON
	useEffect(() => {
		
		var lat = coordinates.lat.toFixed(1);
		var lng = coordinates.lat.toFixed(1);
		
		var cacheKey = `dusk(${lat},${lng},${year},${month})`;
		
		async function fetchData() {
			try {
				
				var startDate = `${year}-${month.toString().padStart(2, '0')}-01`;
				var endDate = moment(startDate, 'YYYY-MM-DD').add(1, 'month').add(-1, 'day').format('YYYY-MM-DD');
				
				const response = await fetch(`https://api.sunrisesunset.io/json?lat=${lat}&lng=${lng}&date_start=${startDate}&date_end=${endDate}`);
				const json = await response.json();
				
				window.localStorage.setItem(cacheKey, JSON.stringify({
					data: JSON.stringify(json.results),
					dateTime: new Date()
				}));
				setDuskJson(json.results);
				
				//console.log('Loaded dusk times from API');
				
			}
			catch (error) {
				console.error('Error fetching dusk data:', error);
			}
		}
		
		function cleanUpOld() {
			Object.keys(window.localStorage).forEach(key => {
				if (key.toLowerCase().trim().substr(0, 5) === 'dusk(') {

					var secondsSinceLastCached = (new Date() - new Date(JSON.parse(window.localStorage[key]).dateTime)) / 1000;
					
					if (secondsSinceLastCached >= 60 * 60 * 24 * 35) {
						//console.log(`Deleting: ${key}`);
						window.localStorage.removeItem(key);
					}

				}
			});
		}
		
		cleanUpOld();
	
		const cachedValue = window.localStorage.getItem(cacheKey);
		if (cachedValue) {
			
			const json = JSON.parse(cachedValue);
			
			var secondsSinceLastCached = (new Date() - new Date(json.dateTime)) / 1000;
			
			if (secondsSinceLastCached < 60 * 60 * 24 * 35) {
				//console.log('Got dusk times from cache');
				setDuskJson(JSON.parse(json.data));
			}
			else {
				fetchData();
			}
			
		}
		else {
			fetchData();
		}
				
	}, [year, month, coordinates]);
	
	// Get's dusk time
	useEffect(() => {
		if (duskJson) {
			
			// Get the average dusk time from the entire month
			var sumOfTotalDusks = duskJson.map(i => new Date(`${year}-${month.toString().padStart(2, '0')}-01 ${i.dusk}`).getTime()).reduce((total, current) => {
				return total + current;
			}, 0);
			var averageDusk = sumOfTotalDusks / duskJson.length;

			setDusk(moment(new Date(parseInt(averageDusk))).format('H:mm'));

		}		
	}, [duskJson]);

	function CelestialBodyCheck({celestialBody}) {
		return (
			<Form.Check
				id={`celestialBody_${celestialBody.name.toLowerCase()}`}
				type='switch'
				className='regular'
				style={{ display: 'inline-block' }}
			>
				<Form.Check.Input
					checked={celestialBodies.indexOf(celestialBody.name.toLowerCase()) !== -1}
					onChange={(e) => {
						
						const celestialBodiesClone = [...celestialBodies];
						
						var celestialBody = e.target.id.split('_')[1];
						
						if (celestialBodiesClone.indexOf(celestialBody) === -1)
							celestialBodiesClone.push(celestialBody);
						else
							celestialBodiesClone.splice(celestialBodiesClone.indexOf(celestialBody), 1);
						
						setCelestialBodies(celestialBodiesClone);
						window.localStorage.setItem('celestialBodies', JSON.stringify(celestialBodiesClone));
						
					}}
				/>
				<Form.Check.Label><a href={celestialBody.name.toLowerCase() === 'moon' ? `https://www.mooncalc.org/#/${coordinates.lat},${coordinates.lon},19/${moment().format('YYYY.MM.DD')}/${moment().format('HH:mm')}/1/3` : `https://www.planetscalc.org/#/${coordinates.lat},${coordinates.lon},19/${moment().format('YYYY.MM.DD')}/${moment().format('HH:mm')}/${allCelestialBodies.filter(i => i.name.toLowerCase() === celestialBody.name.toLowerCase())[0].id}/3`} className='text-dark' style={{textDecoration:'none'}} target='external'>{celestialBody.name}</a></Form.Check.Label>
			</Form.Check>
		)
	}
	
	// Gets presets from local storage
	function getPresets() {
	
		var storedPresets = [];
		Object.keys(window.localStorage).forEach(key => {
			if (key.toLowerCase().trim().substr(0, 8) === 'presets(') {
				var presetName = key.substr(8).trim();
				presetName = presetName.substr(0, presetName.length - 1).trim();
				storedPresets.push(presetName);
			}
		});
		
		setPresets(storedPresets.sort());

	}
	
	function loadPreset(presetName) {
		
		presetName = presetName.trim();
		setPreset(presetName);

		if (presetName === '') {
						
			setAngle(0);
			setArcLength(360);
			setMinimumAltitude(0);
			setCelestialBodies(["moon","mercury","venus","mars","jupiter","saturn","uranus","neptune"]);

		}
		else {

			presetName = `presets(${presetName})`;
			
			var preset = JSON.parse(window.localStorage.getItem(presetName));
			
			setStartTime(preset.startTime);
			setEndTime(preset.endTime);
			setCoordinates(preset.coordinates);
			setAngle(preset.angle);
			setArcLength(preset.arcLength);
			setMinimumAltitude(preset.minimumAltitude);
			setCelestialBodies(preset.celestialBodies);
			
		}
		
	}
	
	function savePreset(preset) {
		
		var presetName = prompt('What would you like to name this preset?', preset);
		if (presetName === null || presetName === undefined) presetName = '';
		presetName = presetName.trim();
		if (presetName === '') return;
		
		var preset = {
			startTime: startTime,
			endTime: endTime,
			coordinates: coordinates,
			celestialBodies: celestialBodies,
			angle: angle,
			arcLength: arcLength,
			minimumAltitude: minimumAltitude
		};
		
		var existingNames = [];
		var existingPresets = [];
		Object.keys(window.localStorage).forEach(key => {
			if (key.toLowerCase().trim().substr(0, 8) === 'presets(') {
				existingNames.push(key);
				existingPresets.push(window.localStorage.getItem(key));
			}
		});
		
		if (existingNames.indexOf(`presets(${presetName})`) !== -1) {
			window.localStorage.removeItem(`presets(${presetName})`);
		}

		var existingPresetId = existingPresets.indexOf(JSON.stringify(preset));
		if (existingPresetId !== -1) {
			
			var existingName = existingNames[existingPresetId];
			existingName = existingName.substr(8);
			existingName = existingName.substr(0, existingName.length - 1);
			
			alert(`A preset named "${existingName}" already has the same settings`);
			// console.log(JSON.stringify(JSON.parse(existingPresets[0]), null, 4));
			// console.log(JSON.stringify(preset, null, 4));
			return;
		}
		
		window.localStorage.setItem(`presets(${presetName})`, JSON.stringify(preset));
		
		setPreset(presetName);

		var presetsClone = [...presets];
		if (presetsClone.indexOf(presetName) !== -1) presetsClone.splice(presetsClone.indexOf(presetName), 1);
		presetsClone.push(presetName);
		presetsClone = presetsClone.sort();
		setPresets(presetsClone);

	}

	function deletePreset(preset) {
		
		var presetsClone = [...presets];
		presetsClone.splice(presetsClone.indexOf(preset), 1);
		presetsClone = presetsClone.sort();
		setPresets(presetsClone);

		window.localStorage.removeItem(`presets(${preset})`);
		window.localStorage.removeItem('preset');
		setPreset('');
		
	}

	var tilesets = [
		{
			url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
			attribution: 'Tiles © Esri - Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community',
			minZoom: 2,
			maxZoom: 18
		},
		{
			url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
			attribution: 'Tiles © Esri - Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
			minZoom: 2,
			maxZoom: 18
		},
		{
			url: 'https://map1.vis.earthdata.nasa.gov/wmts-webmerc/VIIRS_CityLights_2012/default//GoogleMapsCompatible_Level8/{z}/{y}/{x}.jpg',
			attribution: 'Imagery provided by services from the Global Imagery Browse Services (GIBS), operated by the NASA/GSFC/Earth Science Data and Information System (<a href="https://earthdata.nasa.gov">ESDIS</a>) with funding provided by NASA/HQ.',
			minZoom: 2,
			maxZoom: 8
		},
	];
	const useImagery = false;
	var tileset = tilesets[useImagery !== undefined && useImagery ? 1 : 0];
	//tileset = tilesets[1];
	
	var clearLocalStorage = () => {
	};
	
	return (
		<>
			<Helmet>
				<title>Skygenda.com</title>
				<link rel='icon' type='image/png' href={favicon} sizes='16x16' />
			</Helmet>
			<Navbar bg='dark' data-bs-theme='dark'>
				<Container fluid>
					<Navbar.Brand>
						<img
							alt=''
							src={Logo}
							width={24}
							height={24}
						/>&nbsp;
						Skygenda
					</Navbar.Brand>
					<Navbar.Toggle />
					<Navbar.Collapse className="justify-content-end">
						<Button variant='primary' onClick={() => { setShowSettings(true); }}>
							<FontAwesomeIcon icon={faSettings} /><span className='d-none d-sm-inline-block'>&nbsp;Settings</span>
						</Button>&nbsp;
						<Button variant='secondary' onClick={() => { setShowAbout(true); }}>
							<FontAwesomeIcon icon={faWhatIsThis} /><span className='d-none d-sm-inline-block'>&nbsp;What Is Skygenda?</span>
						</Button>
					</Navbar.Collapse>
				</Container>
			</Navbar>
			{false && <IconViewer query='cog' />}
			<Container fluid style={{ padding: 20 }}>
							
				<Row>
					<Col xs={12} sm={12}>
						
						{false && <Ad
							size={width < 320 ? 'Mobile Leaderboard' : 'Leaderboard'}
							className='d-inline-block d-md-none mb-2'
							text={width < 320 ? 'Mobile Leaderboard' : 'Leaderboard'}
						/>}
					
						<Calendar
							preset={preset}
							year={submittedYear}
							month={submittedMonth}
							startTime={submittedStartTime}
							endTime={submittedEndTime}
							celestialBodies={submittedCelestialBodies}
							coordinates={submittedCoordinates}
							angle={submittedAngle}
							arcLength={submittedArcLength}
							minimumAltitude={submittedMinimumAltitude}
							slices={slices}
							isMobile={isMobile}
							onChange={(e) => {
								
								console.log(`Changing month to ${e.month}-${e.year}`);
								
								setYear(e.year);
								setMonth(e.month);
								setSubmittedYear(e.year);
								setSubmittedMonth(e.month);
								window.localStorage.setItem('year', e.year);
								window.localStorage.setItem('month', e.month);
							}}
						/>
						
					</Col>
					{false && <Col xs={12} sm={12} md={3} lg={3} xl={2} xxl={2}>
						<Ad
							size='Wide Skyscraper'
							className='d-none d-md-inline-block'
							text='Wide\nSkyscraper'
						/>
					</Col>}
				</Row>
						
				<Modal
					size='lg'
					show={showSettings}
					onHide={() => { setShowSettings(false); }}
				>
					<Modal.Header className='d-flex justify-content-between align-items-center'>

						<Modal.Title>
							<Button
								variant='link'
								className='m-0 p-0 text-decoration-none text-dark'
								onClick={() => {
									setClearLocalStorageClicks(clearLocalStorageClicks + 1);
								}}
							>
								<h4 className='mb-0'>Settings</h4>
							</Button>
						</Modal.Title>
									
						<InputGroup
							style={{
								width: 300
							}}
						>
							<InputGroup.Text>Presets</InputGroup.Text>
							<Form.Select
								size='sm'
								value={preset}
								aria-label='Preset'
								onChange={(e) => {
									loadPreset(e.target.value);
								}}
							>
								<option value=''>None</option>
								{presets && presets.map((item, index) => (
									<option key={index} value={item}>{item}</option>
								))}
							</Form.Select>
							{preset !== '' && <Button variant='outline-danger' onClick={(e) => {
								if (window.confirm(`Are you sure you want to delete "${preset}"`)) {
									deletePreset(preset);
								}
							}}><FontAwesomeIcon icon={faTrashCan} /></Button>}
						</InputGroup>

					</Modal.Header>
					<Modal.Body>
					
						<Form>
							<Row>

								<Col xs={12} sm={12} md={7} lg={6} xl={6} xxl={6}>
								
									<Form.Group className='mb-3'>
										<Row>
											<Col xs={6} sm={4} md={6} lg={4} xl={4} xxl={4}>
												<Form.Label>
													Start Time&nbsp;
													<OverlayTrigger overlay={<Popover id='popover-basic'>
														<Popover.Body>
															This is the earliest time you'd like to stargaze.
														</Popover.Body>
													</Popover>}>
														<FontAwesomeIcon icon={faCircleQuestion} />
													</OverlayTrigger>
												</Form.Label>
												<Form.Control
													type='time'
													placeholder='Start Time'
													size='sm'
													value={startTime}
													onChange={(e) => {
														setStartTime(e.target.value)
														window.localStorage.setItem('startTime', e.target.value);
													}}
												/>
											</Col>
											<Col xs={6} sm={4} md={6} lg={4} xl={4} xxl={4}>
												<Form.Label>
													End Time&nbsp;
													<OverlayTrigger overlay={<Popover id='popover-basic'>
														<Popover.Body>
															This is the latest time you'd like to stargaze.
														</Popover.Body>
													</Popover>}>
														<FontAwesomeIcon icon={faCircleQuestion} />
													</OverlayTrigger>
												</Form.Label>
												<Form.Control
													type='time'
													placeholder='End Time'
													size='sm'
													value={endTime}
													onChange={(e) => {
														setEndTime(e.target.value)
														window.localStorage.setItem('endTime', e.target.value);
													}}
												/>
											</Col>
										</Row>
										{dusk && <Button variant='link' size='sm' className='p-0' onClick={(e) => {
											setStartTime(dusk)
											window.localStorage.setItem('startTime', dusk);
										}}>Use Dusk</Button>}
									</Form.Group>

								</Col>
							</Row>
							<Form.Group className='mb-3'>
								<Form.Label>
									Bodies Of Interest&nbsp;
									<OverlayTrigger overlay={<Popover id='popover-basic'>
										<Popover.Body>
											These are the objects in the night sky you're interested in.
										</Popover.Body>
									</Popover>}>
										<FontAwesomeIcon icon={faCircleQuestion} />
									</OverlayTrigger>
								</Form.Label>
								<Container fluid>
									<Row>
										<Col xs={4}>
											{allCelestialBodies.slice(0, 3).map((celestialBody) => <div key={celestialBody.name}>
												<CelestialBodyCheck celestialBody={celestialBody} />
											</div>)}
										</Col>
										<Col xs={4}>
											{allCelestialBodies.slice(3, 6).map((celestialBody) => <div key={celestialBody.name}>
												<CelestialBodyCheck celestialBody={celestialBody} />
											</div>)}
										</Col>
										<Col xs={4}>
											{allCelestialBodies.slice(6, 8).map((celestialBody) => <div key={celestialBody.name}>
												<CelestialBodyCheck celestialBody={celestialBody} />
											</div>)}
										</Col>
									</Row>
								</Container>
							</Form.Group>
							<Form.Group className='mb-3'>
								<Form.Label>
									Choose Your Location&nbsp;
									<OverlayTrigger overlay={<Popover id='popover-basic'>
										<Popover.Body>
											<strong>Attribution</strong><hr className='m-0' />{tileset.attribution}
										</Popover.Body>
									</Popover>}>
										<FontAwesomeIcon icon={faCircleQuestion} />
									</OverlayTrigger>
								</Form.Label>
								
								<Map
									tileset={tileset}
									coordinates={coordinates}
									angle={angle}
									arcLength={arcLength}
									zoom={zoom}
									onChange={(e) => {
										const coords = { lat: e.lat, lon: e.lng };
										setCoordinates(coords);
										window.localStorage.setItem('coordinates', JSON.stringify(coords));
									}}
								/>
								{navigator.geolocation && <Button variant='link' size='sm' className='p-0' disabled={isGettingCurrentLocation} onClick={(e) => {
									
									setIsGettingCurrentLocation(true);
									
									navigator.geolocation.getCurrentPosition((position) => {

										setIsGettingCurrentLocation(false);

										setCoordinates({
											lat: position.coords.latitude,
											lon: position.coords.longitude
										});
										setZoom(tileset.maxZoom);

									}, (error) => {
										// display an error if we cant get the users position
										console.error('Error getting user location:', error);
									});

								}}>{isGettingCurrentLocation ? <div><FontAwesomeIcon icon={faCircleNotch} spin /> Getting Current Location</div> : <div>Current Location</div>}</Button>}
								
							</Form.Group>
							{false && <Form.Group className='mb-3'>
								
								<Form.Label>
									Bortles Scale&nbsp;
									<OverlayTrigger overlay={<Popover id='popover-basic'>
										<Popover.Body>
											This is a nine-level numeric scale that measures the night sky's brightness. The lower the number, the darker the night sky.
										</Popover.Body>
									</Popover>}>
										<FontAwesomeIcon icon={faCircleQuestion} />
									</OverlayTrigger>
								</Form.Label>
								<Form.Control
									type='text'
									placeholder='Bortles Scale'
									size='sm'
									value={(bortlesScale ? bortlesScale : '')}
									disabled readOnly
									onChange={(e) => {
									}}
								/>
								
							</Form.Group>}
							<Form.Group className='mb-3'>
								<Row>
									<Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
										<Form.Label className='d-flex justify-content-between align-items-center'>
											<div>
												Viewable Angle&nbsp;
												<OverlayTrigger overlay={<Popover id='popover-basic'>
													<Popover.Body>
														Sometimes obstructions block our view to the night sky. But by setting the "Viewable Angle", "Arc Length", and "Minimum Altitude" values, we can specify our viewable area. These factors determine what celestial objects show up on the calendar.
													</Popover.Body>
												</Popover>}>
													<FontAwesomeIcon icon={faCircleQuestion} />
												</OverlayTrigger>
											</div>
											<div>{angle}º</div>
										</Form.Label>
										<InputGroup className='mb-3'>
											<Form.Range
												min={0}
												max={359}
												value={angle}
												size='sm'
												className='text-center'
												onChange={(e) => {
													setAngle(e.target.value === '' ? 0 : parseInt(e.target.value));
													window.localStorage.setItem('angle', e.target.value === '' ? 0 : parseInt(e.target.value));
												}}
											/>
										</InputGroup>
									</Col>
									<Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
										<Form.Label className='d-flex justify-content-between align-items-center'>
											<div>
												Arc Length&nbsp;
												<OverlayTrigger overlay={<Popover id='popover-basic'>
													<Popover.Body>
														The arc length is the overall width, in degrees, of the viewable area
													</Popover.Body>
												</Popover>}>
													<FontAwesomeIcon icon={faCircleQuestion} />
												</OverlayTrigger>
											</div>
											<div>{arcLength}º</div>
										</Form.Label>
										<InputGroup className='mb-3'>
											<Form.Range
												min={1}
												max={359}
												value={arcLength}
												size='sm'
												className='text-center'
												onChange={(e) => {
													setArcLength(e.target.value === '' ? 359 : parseInt(e.target.value));
													window.localStorage.setItem('arcLength', e.target.value === '' ? 359 : parseInt(e.target.value));
												}}
											/>
										</InputGroup>
									</Col>
									<Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
										<Form.Label className='d-flex justify-content-between align-items-center'>
											<div>
												Minimum Altitude&nbsp;
												<OverlayTrigger overlay={<Popover id='popover-basic'>
													<Popover.Body>
														Set this value to only show celestial objects above a certain altitude. This is useful if you have ground-level obstructions blocking your view, such as trees.
													</Popover.Body>
												</Popover>}>
													<FontAwesomeIcon icon={faCircleQuestion} />
												</OverlayTrigger>
											</div>
											<div>{minimumAltitude}º</div>
										</Form.Label>
										<InputGroup className='mb-3'>
											<Form.Range
												min={0}
												max={90}
												value={minimumAltitude}
												size='sm'
												className='text-center'
												onChange={(e) => {
													setMinimumAltitude(parseInt(e.target.value));
													window.localStorage.setItem('minimumAltitude', parseInt(e.target.value));
												}}
											/>
										</InputGroup>
									</Col>
								</Row>
							</Form.Group>
						</Form>
								
					</Modal.Body>
					<Modal.Footer className='d-flex justify-content-between align-items-center'>
					
						<div className='d-grid gap-2'>
							<Button size='sm' variant='outline-primary' onClick={() => { savePreset(preset); }} >Save As Preset</Button>
						</div>

						<div>
						
							<Button
								size='sm'
								variant='success'
								onClick={() => {
									
									setSubmittedYear(year);
									setSubmittedMonth(month);
									setSubmittedStartTime(startTime);
									setSubmittedEndTime(endTime);
									setSubmittedCoordinates(coordinates);
									setSubmittedAngle(angle);
									setSubmittedArcLength(arcLength);
									setSubmittedMinimumAltitude(minimumAltitude);
									setSubmittedCelestialBodies(celestialBodies);

									window.localStorage.setItem('preset', preset);
									window.localStorage.setItem('year', year);
									window.localStorage.setItem('month', month);
									window.localStorage.setItem('startTime', startTime);
									window.localStorage.setItem('endTime', endTime);
									window.localStorage.setItem('coordinates', JSON.stringify(coordinates));
									window.localStorage.setItem('angle', angle);
									window.localStorage.setItem('arcLength', arcLength);
									window.localStorage.setItem('minimumAltitude', minimumAltitude);
									window.localStorage.setItem('celestialBodies', JSON.stringify(celestialBodies));
									
									setShowSettings(false);

								}}
							>
								Submit
							</Button>&nbsp;

							<Button
								size='sm'
								variant='outline-secondary'
								onClick={() => { setShowSettings(false); }}
							>
								Cancel
							</Button>

						</div>
						
					</Modal.Footer>
				</Modal>
				
				<Modal
					size='lg'
					show={showAbout}
					onHide={() => { setShowAbout(false); window.localStorage.setItem('hasSeenAbout', true); }}
				>
					<Modal.Header closeButton>
						{hasSeenAbout ? <Modal.Title>What Is Skygenda?</Modal.Title> : <Modal.Title>Welcome To Skygenda!</Modal.Title>}
					</Modal.Header>
					<Modal.Body>
					
						<p>
							<b>Welcome to Skygenda, your personalized guide to the celestial wonders above!</b><br />
							Ever wondered what celestial bodies will grace your evening sky on any given night? With Skygenda, wonder no more! Our innovative platform allows you to set your location and viewing preferences, giving you a tailored calendar view of celestial events for the months ahead.
						</p>

						<p>
							<b>Explore the Cosmos, Tailored to You</b><br />
							Whether you're a seasoned stargazer or just beginning your cosmic journey, our website offers a user-friendly interface to plan your celestial adventures. Simply input your location and viewing angles, and voila! A comprehensive calendar appears, detailing which celestial bodies will be visible from your vantage point each night.
						</p>

						<p>
							<b>Plan Ahead, Never Miss a Moment</b><br />
							Gone are the days of last-minute stargazing decisions. With Skygenda, you can plan your astronomical outings in advance. Our calendar view lets you see the entire month at a glance, so you can mark your calendar for those special celestial events.
						</p>

						<p>
							<b>Weather-Proof Your Stargazing</b><br />
							We know there's nothing more frustrating than a cloudy night spoiling your stargazing plans. That's why we've integrated weather forecasts into our platform. Now, even if a full moon is scheduled, you'll know in advance whether clouds will obscure your view.
						</p>

						<p>
							<b>Discover the Magic of the Night Sky</b><br />
							Stargazing isn't just a hobby; it's a journey of discovery. Whether you're spotting familiar constellations or marveling at the dance of the planets, Skygenda is your companion in exploring the cosmos. Get ready to be inspired, awed, and utterly captivated by the wonders above.
						</p>

						<p>
							Ready to embark on your cosmic adventure? Start exploring with Skygenda today!
						</p>
								
					</Modal.Body>
					<Modal.Footer>
					
						<Button
							variant='success'
							onClick={() => { setShowAbout(false); window.localStorage.setItem('hasSeenAbout', true); }}
						>
							Let's Go!
						</Button>
						
					</Modal.Footer>
				</Modal>
				
			</Container>
		</>
	);
}

export default App;