import {
	useState,
	useEffect,
//	useMemo
} from 'react';
import { Polygon } from 'react-leaflet';

function MapViewableArea(props) {
	
	const [startPoint, setStartPoint] = useState([props.coordinates.lat, props.coordinates.lon]);
	const [points, setPoints] = useState([]);

	function getEndpoint(angle, meters) {
		
		// Calculate new latitude and longitude
		const lat1 = (Math.PI / 180) * startPoint[0];
		const lon1 = (Math.PI / 180) * startPoint[1];
		const angularDistance = props.meters / 6371000; // Earth radius in meters
		const bearing = (Math.PI / 180) * angle;

		const lat2 = Math.asin(
			Math.sin(lat1) * Math.cos(angularDistance) +
				Math.cos(lat1) * Math.sin(angularDistance) * Math.cos(bearing)
		);

		const lon2 =
			lon1 +
			Math.atan2(
				Math.sin(bearing) * Math.sin(angularDistance) * Math.cos(lat1),
				Math.cos(angularDistance) - Math.sin(lat1) * Math.sin(lat2)
			)
		;
		
		return [(180 / Math.PI) * lat2, (180 / Math.PI) * lon2];

	}
	
	useEffect(() => {
		setStartPoint([props.coordinates.lat, props.coordinates.lon]);
	}, [props.coordinates]);

	useEffect(() => {
		
		const tempPoints = [];
		
		tempPoints.push(startPoint);
		
		for	(var radian = 0; radian <= props.arcLength; radian++) {
			tempPoints.push(getEndpoint(props.angle + radian));
		}
		
		setPoints(tempPoints);
		
	}, [startPoint, props.angle, props.arcLength]);

	if (points.length === 0) return null;
	return <Polygon positions={points} pathOptions={{ fillColor:'green', color: 'green', weight: 1 }} />;
	
}

export default MapViewableArea;