import { useEffect, useState, useMemo, useRef } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import ViewableArea from './MapViewableArea';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css'; // Re-uses images from ~leaflet package
import 'leaflet-defaulticon-compatibility';

function CoordinateMarker(props) {

	const markerRef = useRef(null);
	
	useMapEvents({
		click(e) {
			props.onChange(e.latlng);
		}
	});

	const eventHandlers = useMemo(() => ({
		dragstart() {
			props.onDragStart();
		},
		dragend() {
			props.onDragEnd();
			const marker = markerRef.current;
			if (marker != null) {
				//console.log(`https://www.google.com/maps?q=${marker.getLatLng().lat},${marker.getLatLng().lng}`);
				props.onChange(marker.getLatLng());
			}
		},
	}), []);
	
	return <Marker
		draggable={true}
		eventHandlers={eventHandlers}
		position={props.coordinates}
		ref={markerRef}
	/>;
	
}

function Map(props) {

	const mapRef = useRef(null);
	const [isDragging, setIsDragging] = useState(false);
	
	useEffect(() => {
		if (mapRef.current) {
			mapRef.current.setView(props.coordinates); //, props.zoom);
		}
	}, [props.coordinates, props.zoom]);
	
	return (
		<MapContainer
			center={props.coordinates}
			zoom={props.zoom}
			minZoom={props.tileset.minZoom}
			maxZoom={props.tileset.maxZoom}
			scrollWheelZoom={false}
			ref={mapRef}
		>
			<TileLayer
				url={props.tileset.url}
			/>
			<CoordinateMarker
				coordinates={props.coordinates}
				onChange={props.onChange}
				onDragStart={() => {
					setIsDragging(true);
				}}
				onDragEnd={() => {
					setIsDragging(false);
				}}
			/>
			{!isDragging && <ViewableArea
				coordinates={props.coordinates}
				angle={props.angle}
				arcLength={props.arcLength}
				meters={1 * 1000}
			/>}
		</MapContainer>
	);
}

export default Map;