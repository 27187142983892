import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import * as moment from 'moment';

import MoonFull from '../Assets/Images/Moon_Full.png';
import MoonNew from '../Assets/Images/Moon_New.png';
import MoonQuarterFirst from '../Assets/Images/Moon_Quarter_First.png';
import MoonQuarterThird from '../Assets/Images/Moon_Quarter_Third.png';
import MoonWaningCrescent from '../Assets/Images/Moon_Waning_Crescent.png';
import MoonWaningGibbous from '../Assets/Images/Moon_Waning_Gibbous.png';
import MoonWaxingCrescent from '../Assets/Images/Moon_Waxing_Crescent.png';
import MoonWaxingGibbous from '../Assets/Images/Moon_Waxing_Gibbous.png';
import Mercury from '../Assets/Images/Mercury.png';
import Venus from '../Assets/Images/Venus.png';
import Mars from '../Assets/Images/Mars.png';
import Jupiter from '../Assets/Images/Jupiter.png';
import Saturn from '../Assets/Images/Saturn.png';
import Uranus from '../Assets/Images/Uranus.png';
import Neptune from '../Assets/Images/Neptune.png';

import Unknown from '../Assets/Images/Unknown.png';

// https://www.freepik.com/free-vector/flat-design-planet-collection_5336208.htm#query=planet&position=5&from_view=keyword&track=sph?log-in=google
// https://www.vecteezy.com/vector-art/528672-planets-in-the-galaxy

function CelestialBody(props) {
	
	const planetscalcIds = {
		mercury: 0,
		venus: 1,
		mars: 2,
		jupiter: 3,
		saturn: 4,
		uranus: 5,
		neptune: 6,
	}
	const unknownCelestialBody = Object.keys(planetscalcIds).concat(['moon']).indexOf(props.celestialBody) === -1;
	
	let moonIcon = null;
	let moonLabel = 'Moon';
	if (props.celestialBody === 'moon') {

		switch (props.values.start.isWaxing) {
			
			case true:
				
				if (props.values.start.illuminatedFraction < 0.01) {
					moonIcon = MoonNew;
					moonLabel = 'New Moon';
				}
				else if (props.values.start.illuminatedFraction < 0.44) {
					moonIcon = MoonWaxingCrescent;
					moonLabel = 'Waxing Crescent Moon';
				}
				else if (props.values.start.illuminatedFraction < 0.55) {
					moonIcon = MoonQuarterFirst;
					moonLabel = 'First Quarter Moon';
				}
				else if (props.values.start.illuminatedFraction < 0.99) {
					moonIcon = MoonWaxingGibbous;
					moonLabel = 'Waxing Gibbous Moon';
				}
				else {
					moonIcon = MoonFull;
					moonLabel = 'Full Moon';
				}
				
				break;
			
			default: // false

				if (props.values.start.illuminatedFraction < 0.01) {
					moonIcon = MoonNew;
					moonLabel = 'New Moon';
				}
				else if (props.values.start.illuminatedFraction < 0.44) {
					moonIcon = MoonWaningCrescent;
					moonLabel = 'Waning Crescent Moon';
				}
				else if (props.values.start.illuminatedFraction < 0.55) {
					moonIcon = MoonQuarterThird;
					moonLabel = 'Third Quarter Moon';
				}
				else if (props.values.start.illuminatedFraction < 0.99) {
					moonIcon = MoonWaningGibbous;
					moonLabel = 'Waning Gibbous Moon';
				}
				else {
					moonIcon = MoonFull;
					moonLabel = 'Full Moon';
				}

				break;
			
		}
		
	}
	
	function popOver(label, startTime, endTime) {
		
		//const body = `Visiblity starting at ${(moment(`${moment().format('YYYY-MM-DD')} ${startTime}`).format('h:mm A'))}.`;
		const body = `Visible from ${(moment(`${moment().format('YYYY-MM-DD')} ${startTime}`).format('h:mm A'))} until ${(moment(`${moment().format('YYYY-MM-DD')} ${endTime}`).format('h:mm A'))}.`;
		
		return (
			<Popover>
				<Popover.Body>
					<div className='fw-bold border-bottom mb-1'>{label}</div>
					{body}
				</Popover.Body>
			</Popover>
		);
	}
	
	if (!props.isMobile) {
		return (
			<a data-body={props.celestialBody} href={(props.celestialBody === 'moon' ? `https://www.mooncalc.org/#/${props.coordinates.lat},${props.coordinates.lon},19/${props.date.replaceAll('-', '.')}/${props.startTime}/1/3` : `https://www.planetscalc.org/#/${props.coordinates.lat},${props.coordinates.lon},19/${props.date.replaceAll('-', '.')}/${props.startTime}/${planetscalcIds[props.celestialBody]}/3`)} className='celestialBody' target='external'>
				{!unknownCelestialBody && props.celestialBody === 'moon' ? <OverlayTrigger overlay={popOver(moonLabel, props.values.start.time, props.values.end.time)}><img src={moonIcon} title='Moon' alt='Moon' /></OverlayTrigger> : null}
				{!unknownCelestialBody && props.celestialBody === 'mercury' ? <OverlayTrigger overlay={popOver('Mercury', props.values.start.time, props.values.end.time)}><img src={Mercury} title='Mercury' alt='Mercury' /></OverlayTrigger> : null}
				{!unknownCelestialBody && props.celestialBody === 'venus' ? <OverlayTrigger overlay={popOver('Venus', props.values.start.time, props.values.end.time)}><img src={Venus} title='Venus' alt='Venus' /></OverlayTrigger> : null}
				{!unknownCelestialBody && props.celestialBody === 'mars' ? <OverlayTrigger overlay={popOver('Mars', props.values.start.time, props.values.end.time)}><img src={Mars} title='Mars' alt='Mars' /></OverlayTrigger> : null}
				{!unknownCelestialBody && props.celestialBody === 'jupiter' ? <OverlayTrigger overlay={popOver('Jupiter', props.values.start.time, props.values.end.time)}><img src={Jupiter} title='Jupiter' alt='Jupiter' /></OverlayTrigger> : null}
				{!unknownCelestialBody && props.celestialBody === 'saturn' ? <OverlayTrigger overlay={popOver('Saturn', props.values.start.time, props.values.end.time)}><img src={Saturn} title='Saturn' alt='Saturn' /></OverlayTrigger> : null}
				{!unknownCelestialBody && props.celestialBody === 'uranus' ? <OverlayTrigger overlay={popOver('Uranus', props.values.start.time, props.values.end.time)}><img src={Uranus} title='Uranus' alt='Uranus' /></OverlayTrigger> : null}
				{!unknownCelestialBody && props.celestialBody === 'neptune' ? <OverlayTrigger overlay={popOver('Neptune', props.values.start.time, props.values.end.time)}><img src={Neptune} title='Neptune' alt='Neptune' /></OverlayTrigger> : null}
				{unknownCelestialBody ? <OverlayTrigger overlay={popOver(`Unknown: ${props.celestialBody}`, props.values.start.time, props.values.end.time)}><img src={Unknown} title={`Unknown: ${props.celestialBody}`} alt={`Unknown: ${props.celestialBody}`} /></OverlayTrigger> : null}
			</a>
		);
	}
	else {
		return (
			<span className='celestialBody'>
				{!unknownCelestialBody && props.celestialBody === 'moon' ? <img src={moonIcon} title='Moon' alt='Moon' /> : null}
				{!unknownCelestialBody && props.celestialBody === 'mercury' ? <img src={Mercury} title='Mercury' alt='Mercury' /> : null}
				{!unknownCelestialBody && props.celestialBody === 'venus' ? <img src={Venus} title='Venus' alt='Venus' /> : null}
				{!unknownCelestialBody && props.celestialBody === 'mars' ? <img src={Mars} title='Mars' alt='Mars' /> : null}
				{!unknownCelestialBody && props.celestialBody === 'jupiter' ? <img src={Jupiter} title='Jupiter' alt='Jupiter' /> : null}
				{!unknownCelestialBody && props.celestialBody === 'saturn' ? <img src={Saturn} title='Saturn' alt='Saturn' /> : null}
				{!unknownCelestialBody && props.celestialBody === 'uranus' ? <img src={Uranus} title='Uranus' alt='Uranus' /> : null}
				{!unknownCelestialBody && props.celestialBody === 'neptune' ? <img src={Neptune} title='Neptune' alt='Neptune' /> : null}
				{unknownCelestialBody ? <img src={Unknown} title={`Unknown: ${props.celestialBody}`} alt={`Unknown: ${props.celestialBody}`} /> : null}
			</span>
		);
	}
	
}

export default CelestialBody;