import { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as fa from '@fortawesome/free-solid-svg-icons';

function IconViewer(props) {
	
	const [icons, setIcons] = useState([]);

	useEffect(() => {
		
		var iconList = [];
		iconList.push({ name: 'fa0', icon: fa.fa0 });
		iconList.push({ name: 'fa1', icon: fa.fa1 });
		iconList.push({ name: 'fa2', icon: fa.fa2 });
		iconList.push({ name: 'fa3', icon: fa.fa3 });
		iconList.push({ name: 'fa4', icon: fa.fa4 });
		iconList.push({ name: 'fa5', icon: fa.fa5 });
		iconList.push({ name: 'fa6', icon: fa.fa6 });
		iconList.push({ name: 'fa7', icon: fa.fa7 });
		iconList.push({ name: 'fa8', icon: fa.fa8 });
		iconList.push({ name: 'fa9', icon: fa.fa9 });
		iconList.push({ name: 'faA', icon: fa.faA });
		iconList.push({ name: 'faAd', icon: fa.faAd });
		iconList.push({ name: 'faAdd', icon: fa.faAdd });
		iconList.push({ name: 'faAddressBook', icon: fa.faAddressBook });
		iconList.push({ name: 'faAddressCard', icon: fa.faAddressCard });
		iconList.push({ name: 'faAdjust', icon: fa.faAdjust });
		iconList.push({ name: 'faAirFreshener', icon: fa.faAirFreshener });
		iconList.push({ name: 'faAlignCenter', icon: fa.faAlignCenter });
		iconList.push({ name: 'faAlignJustify', icon: fa.faAlignJustify });
		iconList.push({ name: 'faAlignLeft', icon: fa.faAlignLeft });
		iconList.push({ name: 'faAlignRight', icon: fa.faAlignRight });
		iconList.push({ name: 'faAllergies', icon: fa.faAllergies });
		iconList.push({ name: 'faAmbulance', icon: fa.faAmbulance });
		iconList.push({ name: 'faAmericanSignLanguageInterpreting', icon: fa.faAmericanSignLanguageInterpreting });
		iconList.push({ name: 'faAnchor', icon: fa.faAnchor });
		iconList.push({ name: 'faAnchorCircleCheck', icon: fa.faAnchorCircleCheck });
		iconList.push({ name: 'faAnchorCircleExclamation', icon: fa.faAnchorCircleExclamation });
		iconList.push({ name: 'faAnchorCircleXmark', icon: fa.faAnchorCircleXmark });
		iconList.push({ name: 'faAnchorLock', icon: fa.faAnchorLock });
		iconList.push({ name: 'faAngleDoubleDown', icon: fa.faAngleDoubleDown });
		iconList.push({ name: 'faAngleDoubleLeft', icon: fa.faAngleDoubleLeft });
		iconList.push({ name: 'faAngleDoubleRight', icon: fa.faAngleDoubleRight });
		iconList.push({ name: 'faAngleDoubleUp', icon: fa.faAngleDoubleUp });
		iconList.push({ name: 'faAngleDown', icon: fa.faAngleDown });
		iconList.push({ name: 'faAngleLeft', icon: fa.faAngleLeft });
		iconList.push({ name: 'faAngleRight', icon: fa.faAngleRight });
		iconList.push({ name: 'faAngleUp', icon: fa.faAngleUp });
		iconList.push({ name: 'faAnglesDown', icon: fa.faAnglesDown });
		iconList.push({ name: 'faAnglesLeft', icon: fa.faAnglesLeft });
		iconList.push({ name: 'faAnglesRight', icon: fa.faAnglesRight });
		iconList.push({ name: 'faAnglesUp', icon: fa.faAnglesUp });
		iconList.push({ name: 'faAngry', icon: fa.faAngry });
		iconList.push({ name: 'faAnkh', icon: fa.faAnkh });
		iconList.push({ name: 'faAppleAlt', icon: fa.faAppleAlt });
		iconList.push({ name: 'faAppleWhole', icon: fa.faAppleWhole });
		iconList.push({ name: 'faArchive', icon: fa.faArchive });
		iconList.push({ name: 'faArchway', icon: fa.faArchway });
		iconList.push({ name: 'faAreaChart', icon: fa.faAreaChart });
		iconList.push({ name: 'faArrowAltCircleDown', icon: fa.faArrowAltCircleDown });
		iconList.push({ name: 'faArrowAltCircleLeft', icon: fa.faArrowAltCircleLeft });
		iconList.push({ name: 'faArrowAltCircleRight', icon: fa.faArrowAltCircleRight });
		iconList.push({ name: 'faArrowAltCircleUp', icon: fa.faArrowAltCircleUp });
		iconList.push({ name: 'faArrowCircleDown', icon: fa.faArrowCircleDown });
		iconList.push({ name: 'faArrowCircleLeft', icon: fa.faArrowCircleLeft });
		iconList.push({ name: 'faArrowCircleRight', icon: fa.faArrowCircleRight });
		iconList.push({ name: 'faArrowCircleUp', icon: fa.faArrowCircleUp });
		iconList.push({ name: 'faArrowDown', icon: fa.faArrowDown });
		iconList.push({ name: 'faArrowDown19', icon: fa.faArrowDown19 });
		iconList.push({ name: 'faArrowDown91', icon: fa.faArrowDown91 });
		iconList.push({ name: 'faArrowDownAZ', icon: fa.faArrowDownAZ });
		iconList.push({ name: 'faArrowDownLong', icon: fa.faArrowDownLong });
		iconList.push({ name: 'faArrowDownShortWide', icon: fa.faArrowDownShortWide });
		iconList.push({ name: 'faArrowDownUpAcrossLine', icon: fa.faArrowDownUpAcrossLine });
		iconList.push({ name: 'faArrowDownUpLock', icon: fa.faArrowDownUpLock });
		iconList.push({ name: 'faArrowDownWideShort', icon: fa.faArrowDownWideShort });
		iconList.push({ name: 'faArrowDownZA', icon: fa.faArrowDownZA });
		iconList.push({ name: 'faArrowLeft', icon: fa.faArrowLeft });
		iconList.push({ name: 'faArrowLeftLong', icon: fa.faArrowLeftLong });
		iconList.push({ name: 'faArrowLeftRotate', icon: fa.faArrowLeftRotate });
		iconList.push({ name: 'faArrowPointer', icon: fa.faArrowPointer });
		iconList.push({ name: 'faArrowRight', icon: fa.faArrowRight });
		iconList.push({ name: 'faArrowRightArrowLeft', icon: fa.faArrowRightArrowLeft });
		iconList.push({ name: 'faArrowRightFromBracket', icon: fa.faArrowRightFromBracket });
		iconList.push({ name: 'faArrowRightFromFile', icon: fa.faArrowRightFromFile });
		iconList.push({ name: 'faArrowRightLong', icon: fa.faArrowRightLong });
		iconList.push({ name: 'faArrowRightRotate', icon: fa.faArrowRightRotate });
		iconList.push({ name: 'faArrowRightToBracket', icon: fa.faArrowRightToBracket });
		iconList.push({ name: 'faArrowRightToCity', icon: fa.faArrowRightToCity });
		iconList.push({ name: 'faArrowRightToFile', icon: fa.faArrowRightToFile });
		iconList.push({ name: 'faArrowRotateBack', icon: fa.faArrowRotateBack });
		iconList.push({ name: 'faArrowRotateBackward', icon: fa.faArrowRotateBackward });
		iconList.push({ name: 'faArrowRotateForward', icon: fa.faArrowRotateForward });
		iconList.push({ name: 'faArrowRotateLeft', icon: fa.faArrowRotateLeft });
		iconList.push({ name: 'faArrowRotateRight', icon: fa.faArrowRotateRight });
		iconList.push({ name: 'faArrowTrendDown', icon: fa.faArrowTrendDown });
		iconList.push({ name: 'faArrowTrendUp', icon: fa.faArrowTrendUp });
		iconList.push({ name: 'faArrowTurnDown', icon: fa.faArrowTurnDown });
		iconList.push({ name: 'faArrowTurnRight', icon: fa.faArrowTurnRight });
		iconList.push({ name: 'faArrowTurnUp', icon: fa.faArrowTurnUp });
		iconList.push({ name: 'faArrowUp', icon: fa.faArrowUp });
		iconList.push({ name: 'faArrowUp19', icon: fa.faArrowUp19 });
		iconList.push({ name: 'faArrowUp91', icon: fa.faArrowUp91 });
		iconList.push({ name: 'faArrowUpAZ', icon: fa.faArrowUpAZ });
		iconList.push({ name: 'faArrowUpFromBracket', icon: fa.faArrowUpFromBracket });
		iconList.push({ name: 'faArrowUpFromGroundWater', icon: fa.faArrowUpFromGroundWater });
		iconList.push({ name: 'faArrowUpFromWaterPump', icon: fa.faArrowUpFromWaterPump });
		iconList.push({ name: 'faArrowUpLong', icon: fa.faArrowUpLong });
		iconList.push({ name: 'faArrowUpRightDots', icon: fa.faArrowUpRightDots });
		iconList.push({ name: 'faArrowUpRightFromSquare', icon: fa.faArrowUpRightFromSquare });
		iconList.push({ name: 'faArrowUpShortWide', icon: fa.faArrowUpShortWide });
		iconList.push({ name: 'faArrowUpWideShort', icon: fa.faArrowUpWideShort });
		iconList.push({ name: 'faArrowUpZA', icon: fa.faArrowUpZA });
		iconList.push({ name: 'faArrows', icon: fa.faArrows });
		iconList.push({ name: 'faArrowsAlt', icon: fa.faArrowsAlt });
		iconList.push({ name: 'faArrowsAltH', icon: fa.faArrowsAltH });
		iconList.push({ name: 'faArrowsAltV', icon: fa.faArrowsAltV });
		iconList.push({ name: 'faArrowsDownToLine', icon: fa.faArrowsDownToLine });
		iconList.push({ name: 'faArrowsDownToPeople', icon: fa.faArrowsDownToPeople });
		iconList.push({ name: 'faArrowsH', icon: fa.faArrowsH });
		iconList.push({ name: 'faArrowsLeftRight', icon: fa.faArrowsLeftRight });
		iconList.push({ name: 'faArrowsLeftRightToLine', icon: fa.faArrowsLeftRightToLine });
		iconList.push({ name: 'faArrowsRotate', icon: fa.faArrowsRotate });
		iconList.push({ name: 'faArrowsSpin', icon: fa.faArrowsSpin });
		iconList.push({ name: 'faArrowsSplitUpAndLeft', icon: fa.faArrowsSplitUpAndLeft });
		iconList.push({ name: 'faArrowsToCircle', icon: fa.faArrowsToCircle });
		iconList.push({ name: 'faArrowsToDot', icon: fa.faArrowsToDot });
		iconList.push({ name: 'faArrowsToEye', icon: fa.faArrowsToEye });
		iconList.push({ name: 'faArrowsTurnRight', icon: fa.faArrowsTurnRight });
		iconList.push({ name: 'faArrowsTurnToDots', icon: fa.faArrowsTurnToDots });
		iconList.push({ name: 'faArrowsUpDown', icon: fa.faArrowsUpDown });
		iconList.push({ name: 'faArrowsUpDownLeftRight', icon: fa.faArrowsUpDownLeftRight });
		iconList.push({ name: 'faArrowsUpToLine', icon: fa.faArrowsUpToLine });
		iconList.push({ name: 'faArrowsV', icon: fa.faArrowsV });
		iconList.push({ name: 'faAslInterpreting', icon: fa.faAslInterpreting });
		iconList.push({ name: 'faAssistiveListeningSystems', icon: fa.faAssistiveListeningSystems });
		iconList.push({ name: 'faAsterisk', icon: fa.faAsterisk });
		iconList.push({ name: 'faAt', icon: fa.faAt });
		iconList.push({ name: 'faAtlas', icon: fa.faAtlas });
		iconList.push({ name: 'faAtom', icon: fa.faAtom });
		iconList.push({ name: 'faAudioDescription', icon: fa.faAudioDescription });
		iconList.push({ name: 'faAustralSign', icon: fa.faAustralSign });
		iconList.push({ name: 'faAutomobile', icon: fa.faAutomobile });
		iconList.push({ name: 'faAward', icon: fa.faAward });
		iconList.push({ name: 'faB', icon: fa.faB });
		iconList.push({ name: 'faBaby', icon: fa.faBaby });
		iconList.push({ name: 'faBabyCarriage', icon: fa.faBabyCarriage });
		iconList.push({ name: 'faBackspace', icon: fa.faBackspace });
		iconList.push({ name: 'faBackward', icon: fa.faBackward });
		iconList.push({ name: 'faBackwardFast', icon: fa.faBackwardFast });
		iconList.push({ name: 'faBackwardStep', icon: fa.faBackwardStep });
		iconList.push({ name: 'faBacon', icon: fa.faBacon });
		iconList.push({ name: 'faBacteria', icon: fa.faBacteria });
		iconList.push({ name: 'faBacterium', icon: fa.faBacterium });
		iconList.push({ name: 'faBagShopping', icon: fa.faBagShopping });
		iconList.push({ name: 'faBahai', icon: fa.faBahai });
		iconList.push({ name: 'faBahtSign', icon: fa.faBahtSign });
		iconList.push({ name: 'faBalanceScale', icon: fa.faBalanceScale });
		iconList.push({ name: 'faBalanceScaleLeft', icon: fa.faBalanceScaleLeft });
		iconList.push({ name: 'faBalanceScaleRight', icon: fa.faBalanceScaleRight });
		iconList.push({ name: 'faBan', icon: fa.faBan });
		iconList.push({ name: 'faBanSmoking', icon: fa.faBanSmoking });
		iconList.push({ name: 'faBandAid', icon: fa.faBandAid });
		iconList.push({ name: 'faBandage', icon: fa.faBandage });
		iconList.push({ name: 'faBangladeshiTakaSign', icon: fa.faBangladeshiTakaSign });
		iconList.push({ name: 'faBank', icon: fa.faBank });
		iconList.push({ name: 'faBarChart', icon: fa.faBarChart });
		iconList.push({ name: 'faBarcode', icon: fa.faBarcode });
		iconList.push({ name: 'faBars', icon: fa.faBars });
		iconList.push({ name: 'faBarsProgress', icon: fa.faBarsProgress });
		iconList.push({ name: 'faBarsStaggered', icon: fa.faBarsStaggered });
		iconList.push({ name: 'faBaseball', icon: fa.faBaseball });
		iconList.push({ name: 'faBaseballBall', icon: fa.faBaseballBall });
		iconList.push({ name: 'faBaseballBatBall', icon: fa.faBaseballBatBall });
		iconList.push({ name: 'faBasketShopping', icon: fa.faBasketShopping });
		iconList.push({ name: 'faBasketball', icon: fa.faBasketball });
		iconList.push({ name: 'faBasketballBall', icon: fa.faBasketballBall });
		iconList.push({ name: 'faBath', icon: fa.faBath });
		iconList.push({ name: 'faBathtub', icon: fa.faBathtub });
		iconList.push({ name: 'faBattery', icon: fa.faBattery });
		iconList.push({ name: 'faBattery0', icon: fa.faBattery0 });
		iconList.push({ name: 'faBattery2', icon: fa.faBattery2 });
		iconList.push({ name: 'faBattery3', icon: fa.faBattery3 });
		iconList.push({ name: 'faBattery4', icon: fa.faBattery4 });
		iconList.push({ name: 'faBattery5', icon: fa.faBattery5 });
		iconList.push({ name: 'faBatteryCar', icon: fa.faBatteryCar });
		iconList.push({ name: 'faBatteryEmpty', icon: fa.faBatteryEmpty });
		iconList.push({ name: 'faBatteryFull', icon: fa.faBatteryFull });
		iconList.push({ name: 'faBatteryHalf', icon: fa.faBatteryHalf });
		iconList.push({ name: 'faBatteryQuarter', icon: fa.faBatteryQuarter });
		iconList.push({ name: 'faBatteryThreeQuarters', icon: fa.faBatteryThreeQuarters });
		iconList.push({ name: 'faBed', icon: fa.faBed });
		iconList.push({ name: 'faBedPulse', icon: fa.faBedPulse });
		iconList.push({ name: 'faBeer', icon: fa.faBeer });
		iconList.push({ name: 'faBeerMugEmpty', icon: fa.faBeerMugEmpty });
		iconList.push({ name: 'faBell', icon: fa.faBell });
		iconList.push({ name: 'faBellConcierge', icon: fa.faBellConcierge });
		iconList.push({ name: 'faBellSlash', icon: fa.faBellSlash });
		iconList.push({ name: 'faBezierCurve', icon: fa.faBezierCurve });
		iconList.push({ name: 'faBible', icon: fa.faBible });
		iconList.push({ name: 'faBicycle', icon: fa.faBicycle });
		iconList.push({ name: 'faBiking', icon: fa.faBiking });
		iconList.push({ name: 'faBinoculars', icon: fa.faBinoculars });
		iconList.push({ name: 'faBiohazard', icon: fa.faBiohazard });
		iconList.push({ name: 'faBirthdayCake', icon: fa.faBirthdayCake });
		iconList.push({ name: 'faBitcoinSign', icon: fa.faBitcoinSign });
		iconList.push({ name: 'faBlackboard', icon: fa.faBlackboard });
		iconList.push({ name: 'faBlender', icon: fa.faBlender });
		iconList.push({ name: 'faBlenderPhone', icon: fa.faBlenderPhone });
		iconList.push({ name: 'faBlind', icon: fa.faBlind });
		iconList.push({ name: 'faBlog', icon: fa.faBlog });
		iconList.push({ name: 'faBold', icon: fa.faBold });
		iconList.push({ name: 'faBolt', icon: fa.faBolt });
		iconList.push({ name: 'faBoltLightning', icon: fa.faBoltLightning });
		iconList.push({ name: 'faBomb', icon: fa.faBomb });
		iconList.push({ name: 'faBone', icon: fa.faBone });
		iconList.push({ name: 'faBong', icon: fa.faBong });
		iconList.push({ name: 'faBook', icon: fa.faBook });
		iconList.push({ name: 'faBookAtlas', icon: fa.faBookAtlas });
		iconList.push({ name: 'faBookBible', icon: fa.faBookBible });
		iconList.push({ name: 'faBookBookmark', icon: fa.faBookBookmark });
		iconList.push({ name: 'faBookDead', icon: fa.faBookDead });
		iconList.push({ name: 'faBookJournalWhills', icon: fa.faBookJournalWhills });
		iconList.push({ name: 'faBookMedical', icon: fa.faBookMedical });
		iconList.push({ name: 'faBookOpen', icon: fa.faBookOpen });
		iconList.push({ name: 'faBookOpenReader', icon: fa.faBookOpenReader });
		iconList.push({ name: 'faBookQuran', icon: fa.faBookQuran });
		iconList.push({ name: 'faBookReader', icon: fa.faBookReader });
		iconList.push({ name: 'faBookSkull', icon: fa.faBookSkull });
		iconList.push({ name: 'faBookTanakh', icon: fa.faBookTanakh });
		iconList.push({ name: 'faBookmark', icon: fa.faBookmark });
		iconList.push({ name: 'faBorderAll', icon: fa.faBorderAll });
		iconList.push({ name: 'faBorderNone', icon: fa.faBorderNone });
		iconList.push({ name: 'faBorderStyle', icon: fa.faBorderStyle });
		iconList.push({ name: 'faBorderTopLeft', icon: fa.faBorderTopLeft });
		iconList.push({ name: 'faBoreHole', icon: fa.faBoreHole });
		iconList.push({ name: 'faBottleDroplet', icon: fa.faBottleDroplet });
		iconList.push({ name: 'faBottleWater', icon: fa.faBottleWater });
		iconList.push({ name: 'faBowlFood', icon: fa.faBowlFood });
		iconList.push({ name: 'faBowlRice', icon: fa.faBowlRice });
		iconList.push({ name: 'faBowlingBall', icon: fa.faBowlingBall });
		iconList.push({ name: 'faBox', icon: fa.faBox });
		iconList.push({ name: 'faBoxArchive', icon: fa.faBoxArchive });
		iconList.push({ name: 'faBoxOpen', icon: fa.faBoxOpen });
		iconList.push({ name: 'faBoxTissue', icon: fa.faBoxTissue });
		iconList.push({ name: 'faBoxes', icon: fa.faBoxes });
		iconList.push({ name: 'faBoxesAlt', icon: fa.faBoxesAlt });
		iconList.push({ name: 'faBoxesPacking', icon: fa.faBoxesPacking });
		iconList.push({ name: 'faBoxesStacked', icon: fa.faBoxesStacked });
		iconList.push({ name: 'faBraille', icon: fa.faBraille });
		iconList.push({ name: 'faBrain', icon: fa.faBrain });
		iconList.push({ name: 'faBrazilianRealSign', icon: fa.faBrazilianRealSign });
		iconList.push({ name: 'faBreadSlice', icon: fa.faBreadSlice });
		iconList.push({ name: 'faBridge', icon: fa.faBridge });
		iconList.push({ name: 'faBridgeCircleCheck', icon: fa.faBridgeCircleCheck });
		iconList.push({ name: 'faBridgeCircleExclamation', icon: fa.faBridgeCircleExclamation });
		iconList.push({ name: 'faBridgeCircleXmark', icon: fa.faBridgeCircleXmark });
		iconList.push({ name: 'faBridgeLock', icon: fa.faBridgeLock });
		iconList.push({ name: 'faBridgeWater', icon: fa.faBridgeWater });
		iconList.push({ name: 'faBriefcase', icon: fa.faBriefcase });
		iconList.push({ name: 'faBriefcaseClock', icon: fa.faBriefcaseClock });
		iconList.push({ name: 'faBriefcaseMedical', icon: fa.faBriefcaseMedical });
		iconList.push({ name: 'faBroadcastTower', icon: fa.faBroadcastTower });
		iconList.push({ name: 'faBroom', icon: fa.faBroom });
		iconList.push({ name: 'faBroomBall', icon: fa.faBroomBall });
		iconList.push({ name: 'faBrush', icon: fa.faBrush });
		iconList.push({ name: 'faBucket', icon: fa.faBucket });
		iconList.push({ name: 'faBug', icon: fa.faBug });
		iconList.push({ name: 'faBugSlash', icon: fa.faBugSlash });
		iconList.push({ name: 'faBugs', icon: fa.faBugs });
		iconList.push({ name: 'faBuilding', icon: fa.faBuilding });
		iconList.push({ name: 'faBuildingCircleArrowRight', icon: fa.faBuildingCircleArrowRight });
		iconList.push({ name: 'faBuildingCircleCheck', icon: fa.faBuildingCircleCheck });
		iconList.push({ name: 'faBuildingCircleExclamation', icon: fa.faBuildingCircleExclamation });
		iconList.push({ name: 'faBuildingCircleXmark', icon: fa.faBuildingCircleXmark });
		iconList.push({ name: 'faBuildingColumns', icon: fa.faBuildingColumns });
		iconList.push({ name: 'faBuildingFlag', icon: fa.faBuildingFlag });
		iconList.push({ name: 'faBuildingLock', icon: fa.faBuildingLock });
		iconList.push({ name: 'faBuildingNgo', icon: fa.faBuildingNgo });
		iconList.push({ name: 'faBuildingShield', icon: fa.faBuildingShield });
		iconList.push({ name: 'faBuildingUn', icon: fa.faBuildingUn });
		iconList.push({ name: 'faBuildingUser', icon: fa.faBuildingUser });
		iconList.push({ name: 'faBuildingWheat', icon: fa.faBuildingWheat });
		iconList.push({ name: 'faBullhorn', icon: fa.faBullhorn });
		iconList.push({ name: 'faBullseye', icon: fa.faBullseye });
		iconList.push({ name: 'faBurger', icon: fa.faBurger });
		iconList.push({ name: 'faBurn', icon: fa.faBurn });
		iconList.push({ name: 'faBurst', icon: fa.faBurst });
		iconList.push({ name: 'faBus', icon: fa.faBus });
		iconList.push({ name: 'faBusAlt', icon: fa.faBusAlt });
		iconList.push({ name: 'faBusSimple', icon: fa.faBusSimple });
		iconList.push({ name: 'faBusinessTime', icon: fa.faBusinessTime });
		iconList.push({ name: 'faC', icon: fa.faC });
		iconList.push({ name: 'faCab', icon: fa.faCab });
		iconList.push({ name: 'faCableCar', icon: fa.faCableCar });
		iconList.push({ name: 'faCake', icon: fa.faCake });
		iconList.push({ name: 'faCakeCandles', icon: fa.faCakeCandles });
		iconList.push({ name: 'faCalculator', icon: fa.faCalculator });
		iconList.push({ name: 'faCalendar', icon: fa.faCalendar });
		iconList.push({ name: 'faCalendarAlt', icon: fa.faCalendarAlt });
		iconList.push({ name: 'faCalendarCheck', icon: fa.faCalendarCheck });
		iconList.push({ name: 'faCalendarDay', icon: fa.faCalendarDay });
		iconList.push({ name: 'faCalendarDays', icon: fa.faCalendarDays });
		iconList.push({ name: 'faCalendarMinus', icon: fa.faCalendarMinus });
		iconList.push({ name: 'faCalendarPlus', icon: fa.faCalendarPlus });
		iconList.push({ name: 'faCalendarTimes', icon: fa.faCalendarTimes });
		iconList.push({ name: 'faCalendarWeek', icon: fa.faCalendarWeek });
		iconList.push({ name: 'faCalendarXmark', icon: fa.faCalendarXmark });
		iconList.push({ name: 'faCamera', icon: fa.faCamera });
		iconList.push({ name: 'faCameraAlt', icon: fa.faCameraAlt });
		iconList.push({ name: 'faCameraRetro', icon: fa.faCameraRetro });
		iconList.push({ name: 'faCameraRotate', icon: fa.faCameraRotate });
		iconList.push({ name: 'faCampground', icon: fa.faCampground });
		iconList.push({ name: 'faCancel', icon: fa.faCancel });
		iconList.push({ name: 'faCandyCane', icon: fa.faCandyCane });
		iconList.push({ name: 'faCannabis', icon: fa.faCannabis });
		iconList.push({ name: 'faCapsules', icon: fa.faCapsules });
		iconList.push({ name: 'faCar', icon: fa.faCar });
		iconList.push({ name: 'faCarAlt', icon: fa.faCarAlt });
		iconList.push({ name: 'faCarBattery', icon: fa.faCarBattery });
		iconList.push({ name: 'faCarBurst', icon: fa.faCarBurst });
		iconList.push({ name: 'faCarCrash', icon: fa.faCarCrash });
		iconList.push({ name: 'faCarOn', icon: fa.faCarOn });
		iconList.push({ name: 'faCarRear', icon: fa.faCarRear });
		iconList.push({ name: 'faCarSide', icon: fa.faCarSide });
		iconList.push({ name: 'faCarTunnel', icon: fa.faCarTunnel });
		iconList.push({ name: 'faCaravan', icon: fa.faCaravan });
		iconList.push({ name: 'faCaretDown', icon: fa.faCaretDown });
		iconList.push({ name: 'faCaretLeft', icon: fa.faCaretLeft });
		iconList.push({ name: 'faCaretRight', icon: fa.faCaretRight });
		iconList.push({ name: 'faCaretSquareDown', icon: fa.faCaretSquareDown });
		iconList.push({ name: 'faCaretSquareLeft', icon: fa.faCaretSquareLeft });
		iconList.push({ name: 'faCaretSquareRight', icon: fa.faCaretSquareRight });
		iconList.push({ name: 'faCaretSquareUp', icon: fa.faCaretSquareUp });
		iconList.push({ name: 'faCaretUp', icon: fa.faCaretUp });
		iconList.push({ name: 'faCarriageBaby', icon: fa.faCarriageBaby });
		iconList.push({ name: 'faCarrot', icon: fa.faCarrot });
		iconList.push({ name: 'faCartArrowDown', icon: fa.faCartArrowDown });
		iconList.push({ name: 'faCartFlatbed', icon: fa.faCartFlatbed });
		iconList.push({ name: 'faCartFlatbedSuitcase', icon: fa.faCartFlatbedSuitcase });
		iconList.push({ name: 'faCartPlus', icon: fa.faCartPlus });
		iconList.push({ name: 'faCartShopping', icon: fa.faCartShopping });
		iconList.push({ name: 'faCashRegister', icon: fa.faCashRegister });
		iconList.push({ name: 'faCat', icon: fa.faCat });
		iconList.push({ name: 'faCediSign', icon: fa.faCediSign });
		iconList.push({ name: 'faCentSign', icon: fa.faCentSign });
		iconList.push({ name: 'faCertificate', icon: fa.faCertificate });
		iconList.push({ name: 'faChain', icon: fa.faChain });
		iconList.push({ name: 'faChainBroken', icon: fa.faChainBroken });
		iconList.push({ name: 'faChainSlash', icon: fa.faChainSlash });
		iconList.push({ name: 'faChair', icon: fa.faChair });
		iconList.push({ name: 'faChalkboard', icon: fa.faChalkboard });
		iconList.push({ name: 'faChalkboardTeacher', icon: fa.faChalkboardTeacher });
		iconList.push({ name: 'faChalkboardUser', icon: fa.faChalkboardUser });
		iconList.push({ name: 'faChampagneGlasses', icon: fa.faChampagneGlasses });
		iconList.push({ name: 'faChargingStation', icon: fa.faChargingStation });
		iconList.push({ name: 'faChartArea', icon: fa.faChartArea });
		iconList.push({ name: 'faChartBar', icon: fa.faChartBar });
		iconList.push({ name: 'faChartColumn', icon: fa.faChartColumn });
		iconList.push({ name: 'faChartGantt', icon: fa.faChartGantt });
		iconList.push({ name: 'faChartLine', icon: fa.faChartLine });
		iconList.push({ name: 'faChartPie', icon: fa.faChartPie });
		iconList.push({ name: 'faChartSimple', icon: fa.faChartSimple });
		iconList.push({ name: 'faCheck', icon: fa.faCheck });
		iconList.push({ name: 'faCheckCircle', icon: fa.faCheckCircle });
		iconList.push({ name: 'faCheckDouble', icon: fa.faCheckDouble });
		iconList.push({ name: 'faCheckSquare', icon: fa.faCheckSquare });
		iconList.push({ name: 'faCheckToSlot', icon: fa.faCheckToSlot });
		iconList.push({ name: 'faCheese', icon: fa.faCheese });
		iconList.push({ name: 'faChess', icon: fa.faChess });
		iconList.push({ name: 'faChessBishop', icon: fa.faChessBishop });
		iconList.push({ name: 'faChessBoard', icon: fa.faChessBoard });
		iconList.push({ name: 'faChessKing', icon: fa.faChessKing });
		iconList.push({ name: 'faChessKnight', icon: fa.faChessKnight });
		iconList.push({ name: 'faChessPawn', icon: fa.faChessPawn });
		iconList.push({ name: 'faChessQueen', icon: fa.faChessQueen });
		iconList.push({ name: 'faChessRook', icon: fa.faChessRook });
		iconList.push({ name: 'faChevronCircleDown', icon: fa.faChevronCircleDown });
		iconList.push({ name: 'faChevronCircleLeft', icon: fa.faChevronCircleLeft });
		iconList.push({ name: 'faChevronCircleRight', icon: fa.faChevronCircleRight });
		iconList.push({ name: 'faChevronCircleUp', icon: fa.faChevronCircleUp });
		iconList.push({ name: 'faChevronDown', icon: fa.faChevronDown });
		iconList.push({ name: 'faChevronLeft', icon: fa.faChevronLeft });
		iconList.push({ name: 'faChevronRight', icon: fa.faChevronRight });
		iconList.push({ name: 'faChevronUp', icon: fa.faChevronUp });
		iconList.push({ name: 'faChild', icon: fa.faChild });
		iconList.push({ name: 'faChildCombatant', icon: fa.faChildCombatant });
		iconList.push({ name: 'faChildDress', icon: fa.faChildDress });
		iconList.push({ name: 'faChildReaching', icon: fa.faChildReaching });
		iconList.push({ name: 'faChildRifle', icon: fa.faChildRifle });
		iconList.push({ name: 'faChildren', icon: fa.faChildren });
		iconList.push({ name: 'faChurch', icon: fa.faChurch });
		iconList.push({ name: 'faCircle', icon: fa.faCircle });
		iconList.push({ name: 'faCircleArrowDown', icon: fa.faCircleArrowDown });
		iconList.push({ name: 'faCircleArrowLeft', icon: fa.faCircleArrowLeft });
		iconList.push({ name: 'faCircleArrowRight', icon: fa.faCircleArrowRight });
		iconList.push({ name: 'faCircleArrowUp', icon: fa.faCircleArrowUp });
		iconList.push({ name: 'faCircleCheck', icon: fa.faCircleCheck });
		iconList.push({ name: 'faCircleChevronDown', icon: fa.faCircleChevronDown });
		iconList.push({ name: 'faCircleChevronLeft', icon: fa.faCircleChevronLeft });
		iconList.push({ name: 'faCircleChevronRight', icon: fa.faCircleChevronRight });
		iconList.push({ name: 'faCircleChevronUp', icon: fa.faCircleChevronUp });
		iconList.push({ name: 'faCircleDollarToSlot', icon: fa.faCircleDollarToSlot });
		iconList.push({ name: 'faCircleDot', icon: fa.faCircleDot });
		iconList.push({ name: 'faCircleDown', icon: fa.faCircleDown });
		iconList.push({ name: 'faCircleExclamation', icon: fa.faCircleExclamation });
		iconList.push({ name: 'faCircleH', icon: fa.faCircleH });
		iconList.push({ name: 'faCircleHalfStroke', icon: fa.faCircleHalfStroke });
		iconList.push({ name: 'faCircleInfo', icon: fa.faCircleInfo });
		iconList.push({ name: 'faCircleLeft', icon: fa.faCircleLeft });
		iconList.push({ name: 'faCircleMinus', icon: fa.faCircleMinus });
		iconList.push({ name: 'faCircleNodes', icon: fa.faCircleNodes });
		iconList.push({ name: 'faCircleNotch', icon: fa.faCircleNotch });
		iconList.push({ name: 'faCirclePause', icon: fa.faCirclePause });
		iconList.push({ name: 'faCirclePlay', icon: fa.faCirclePlay });
		iconList.push({ name: 'faCirclePlus', icon: fa.faCirclePlus });
		iconList.push({ name: 'faCircleQuestion', icon: fa.faCircleQuestion });
		iconList.push({ name: 'faCircleRadiation', icon: fa.faCircleRadiation });
		iconList.push({ name: 'faCircleRight', icon: fa.faCircleRight });
		iconList.push({ name: 'faCircleStop', icon: fa.faCircleStop });
		iconList.push({ name: 'faCircleUp', icon: fa.faCircleUp });
		iconList.push({ name: 'faCircleUser', icon: fa.faCircleUser });
		iconList.push({ name: 'faCircleXmark', icon: fa.faCircleXmark });
		iconList.push({ name: 'faCity', icon: fa.faCity });
		iconList.push({ name: 'faClapperboard', icon: fa.faClapperboard });
		iconList.push({ name: 'faClinicMedical', icon: fa.faClinicMedical });
		iconList.push({ name: 'faClipboard', icon: fa.faClipboard });
		iconList.push({ name: 'faClipboardCheck', icon: fa.faClipboardCheck });
		iconList.push({ name: 'faClipboardList', icon: fa.faClipboardList });
		iconList.push({ name: 'faClipboardQuestion', icon: fa.faClipboardQuestion });
		iconList.push({ name: 'faClipboardUser', icon: fa.faClipboardUser });
		iconList.push({ name: 'faClock', icon: fa.faClock });
		iconList.push({ name: 'faClockFour', icon: fa.faClockFour });
		iconList.push({ name: 'faClockRotateLeft', icon: fa.faClockRotateLeft });
		iconList.push({ name: 'faClone', icon: fa.faClone });
		iconList.push({ name: 'faClose', icon: fa.faClose });
		iconList.push({ name: 'faClosedCaptioning', icon: fa.faClosedCaptioning });
		iconList.push({ name: 'faCloud', icon: fa.faCloud });
		iconList.push({ name: 'faCloudArrowDown', icon: fa.faCloudArrowDown });
		iconList.push({ name: 'faCloudArrowUp', icon: fa.faCloudArrowUp });
		iconList.push({ name: 'faCloudBolt', icon: fa.faCloudBolt });
		iconList.push({ name: 'faCloudDownload', icon: fa.faCloudDownload });
		iconList.push({ name: 'faCloudDownloadAlt', icon: fa.faCloudDownloadAlt });
		iconList.push({ name: 'faCloudMeatball', icon: fa.faCloudMeatball });
		iconList.push({ name: 'faCloudMoon', icon: fa.faCloudMoon });
		iconList.push({ name: 'faCloudMoonRain', icon: fa.faCloudMoonRain });
		iconList.push({ name: 'faCloudRain', icon: fa.faCloudRain });
		iconList.push({ name: 'faCloudShowersHeavy', icon: fa.faCloudShowersHeavy });
		iconList.push({ name: 'faCloudShowersWater', icon: fa.faCloudShowersWater });
		iconList.push({ name: 'faCloudSun', icon: fa.faCloudSun });
		iconList.push({ name: 'faCloudSunRain', icon: fa.faCloudSunRain });
		iconList.push({ name: 'faCloudUpload', icon: fa.faCloudUpload });
		iconList.push({ name: 'faCloudUploadAlt', icon: fa.faCloudUploadAlt });
		iconList.push({ name: 'faClover', icon: fa.faClover });
		iconList.push({ name: 'faCny', icon: fa.faCny });
		iconList.push({ name: 'faCocktail', icon: fa.faCocktail });
		iconList.push({ name: 'faCode', icon: fa.faCode });
		iconList.push({ name: 'faCodeBranch', icon: fa.faCodeBranch });
		iconList.push({ name: 'faCodeCommit', icon: fa.faCodeCommit });
		iconList.push({ name: 'faCodeCompare', icon: fa.faCodeCompare });
		iconList.push({ name: 'faCodeFork', icon: fa.faCodeFork });
		iconList.push({ name: 'faCodeMerge', icon: fa.faCodeMerge });
		iconList.push({ name: 'faCodePullRequest', icon: fa.faCodePullRequest });
		iconList.push({ name: 'faCoffee', icon: fa.faCoffee });
		iconList.push({ name: 'faCog', icon: fa.faCog });
		iconList.push({ name: 'faCogs', icon: fa.faCogs });
		iconList.push({ name: 'faCoins', icon: fa.faCoins });
		iconList.push({ name: 'faColonSign', icon: fa.faColonSign });
		iconList.push({ name: 'faColumns', icon: fa.faColumns });
		iconList.push({ name: 'faComment', icon: fa.faComment });
		iconList.push({ name: 'faCommentAlt', icon: fa.faCommentAlt });
		iconList.push({ name: 'faCommentDollar', icon: fa.faCommentDollar });
		iconList.push({ name: 'faCommentDots', icon: fa.faCommentDots });
		iconList.push({ name: 'faCommentMedical', icon: fa.faCommentMedical });
		iconList.push({ name: 'faCommentSlash', icon: fa.faCommentSlash });
		iconList.push({ name: 'faCommentSms', icon: fa.faCommentSms });
		iconList.push({ name: 'faCommenting', icon: fa.faCommenting });
		iconList.push({ name: 'faComments', icon: fa.faComments });
		iconList.push({ name: 'faCommentsDollar', icon: fa.faCommentsDollar });
		iconList.push({ name: 'faCompactDisc', icon: fa.faCompactDisc });
		iconList.push({ name: 'faCompass', icon: fa.faCompass });
		iconList.push({ name: 'faCompassDrafting', icon: fa.faCompassDrafting });
		iconList.push({ name: 'faCompress', icon: fa.faCompress });
		iconList.push({ name: 'faCompressAlt', icon: fa.faCompressAlt });
		iconList.push({ name: 'faCompressArrowsAlt', icon: fa.faCompressArrowsAlt });
		iconList.push({ name: 'faComputer', icon: fa.faComputer });
		iconList.push({ name: 'faComputerMouse', icon: fa.faComputerMouse });
		iconList.push({ name: 'faConciergeBell', icon: fa.faConciergeBell });
		iconList.push({ name: 'faContactBook', icon: fa.faContactBook });
		iconList.push({ name: 'faContactCard', icon: fa.faContactCard });
		iconList.push({ name: 'faCookie', icon: fa.faCookie });
		iconList.push({ name: 'faCookieBite', icon: fa.faCookieBite });
		iconList.push({ name: 'faCopy', icon: fa.faCopy });
		iconList.push({ name: 'faCopyright', icon: fa.faCopyright });
		iconList.push({ name: 'faCouch', icon: fa.faCouch });
		iconList.push({ name: 'faCow', icon: fa.faCow });
		iconList.push({ name: 'faCreditCard', icon: fa.faCreditCard });
		iconList.push({ name: 'faCreditCardAlt', icon: fa.faCreditCardAlt });
		iconList.push({ name: 'faCrop', icon: fa.faCrop });
		iconList.push({ name: 'faCropAlt', icon: fa.faCropAlt });
		iconList.push({ name: 'faCropSimple', icon: fa.faCropSimple });
		iconList.push({ name: 'faCross', icon: fa.faCross });
		iconList.push({ name: 'faCrosshairs', icon: fa.faCrosshairs });
		iconList.push({ name: 'faCrow', icon: fa.faCrow });
		iconList.push({ name: 'faCrown', icon: fa.faCrown });
		iconList.push({ name: 'faCrutch', icon: fa.faCrutch });
		iconList.push({ name: 'faCruzeiroSign', icon: fa.faCruzeiroSign });
		iconList.push({ name: 'faCube', icon: fa.faCube });
		iconList.push({ name: 'faCubes', icon: fa.faCubes });
		iconList.push({ name: 'faCubesStacked', icon: fa.faCubesStacked });
		iconList.push({ name: 'faCut', icon: fa.faCut });
		iconList.push({ name: 'faCutlery', icon: fa.faCutlery });
		iconList.push({ name: 'faD', icon: fa.faD });
		iconList.push({ name: 'faDashboard', icon: fa.faDashboard });
		iconList.push({ name: 'faDatabase', icon: fa.faDatabase });
		iconList.push({ name: 'faDeaf', icon: fa.faDeaf });
		iconList.push({ name: 'faDeafness', icon: fa.faDeafness });
		iconList.push({ name: 'faDedent', icon: fa.faDedent });
		iconList.push({ name: 'faDeleteLeft', icon: fa.faDeleteLeft });
		iconList.push({ name: 'faDemocrat', icon: fa.faDemocrat });
		iconList.push({ name: 'faDesktop', icon: fa.faDesktop });
		iconList.push({ name: 'faDesktopAlt', icon: fa.faDesktopAlt });
		iconList.push({ name: 'faDharmachakra', icon: fa.faDharmachakra });
		iconList.push({ name: 'faDiagnoses', icon: fa.faDiagnoses });
		iconList.push({ name: 'faDiagramNext', icon: fa.faDiagramNext });
		iconList.push({ name: 'faDiagramPredecessor', icon: fa.faDiagramPredecessor });
		iconList.push({ name: 'faDiagramProject', icon: fa.faDiagramProject });
		iconList.push({ name: 'faDiagramSuccessor', icon: fa.faDiagramSuccessor });
		iconList.push({ name: 'faDiamond', icon: fa.faDiamond });
		iconList.push({ name: 'faDiamondTurnRight', icon: fa.faDiamondTurnRight });
		iconList.push({ name: 'faDice', icon: fa.faDice });
		iconList.push({ name: 'faDiceD20', icon: fa.faDiceD20 });
		iconList.push({ name: 'faDiceD6', icon: fa.faDiceD6 });
		iconList.push({ name: 'faDiceFive', icon: fa.faDiceFive });
		iconList.push({ name: 'faDiceFour', icon: fa.faDiceFour });
		iconList.push({ name: 'faDiceOne', icon: fa.faDiceOne });
		iconList.push({ name: 'faDiceSix', icon: fa.faDiceSix });
		iconList.push({ name: 'faDiceThree', icon: fa.faDiceThree });
		iconList.push({ name: 'faDiceTwo', icon: fa.faDiceTwo });
		iconList.push({ name: 'faDigging', icon: fa.faDigging });
		iconList.push({ name: 'faDigitalTachograph', icon: fa.faDigitalTachograph });
		iconList.push({ name: 'faDirections', icon: fa.faDirections });
		iconList.push({ name: 'faDisease', icon: fa.faDisease });
		iconList.push({ name: 'faDisplay', icon: fa.faDisplay });
		iconList.push({ name: 'faDivide', icon: fa.faDivide });
		iconList.push({ name: 'faDizzy', icon: fa.faDizzy });
		iconList.push({ name: 'faDna', icon: fa.faDna });
		iconList.push({ name: 'faDog', icon: fa.faDog });
		iconList.push({ name: 'faDollar', icon: fa.faDollar });
		iconList.push({ name: 'faDollarSign', icon: fa.faDollarSign });
		iconList.push({ name: 'faDolly', icon: fa.faDolly });
		iconList.push({ name: 'faDollyBox', icon: fa.faDollyBox });
		iconList.push({ name: 'faDollyFlatbed', icon: fa.faDollyFlatbed });
		iconList.push({ name: 'faDonate', icon: fa.faDonate });
		iconList.push({ name: 'faDongSign', icon: fa.faDongSign });
		iconList.push({ name: 'faDoorClosed', icon: fa.faDoorClosed });
		iconList.push({ name: 'faDoorOpen', icon: fa.faDoorOpen });
		iconList.push({ name: 'faDotCircle', icon: fa.faDotCircle });
		iconList.push({ name: 'faDove', icon: fa.faDove });
		iconList.push({ name: 'faDownLeftAndUpRightToCenter', icon: fa.faDownLeftAndUpRightToCenter });
		iconList.push({ name: 'faDownLong', icon: fa.faDownLong });
		iconList.push({ name: 'faDownload', icon: fa.faDownload });
		iconList.push({ name: 'faDraftingCompass', icon: fa.faDraftingCompass });
		iconList.push({ name: 'faDragon', icon: fa.faDragon });
		iconList.push({ name: 'faDrawPolygon', icon: fa.faDrawPolygon });
		iconList.push({ name: 'faDriversLicense', icon: fa.faDriversLicense });
		iconList.push({ name: 'faDroplet', icon: fa.faDroplet });
		iconList.push({ name: 'faDropletSlash', icon: fa.faDropletSlash });
		iconList.push({ name: 'faDrum', icon: fa.faDrum });
		iconList.push({ name: 'faDrumSteelpan', icon: fa.faDrumSteelpan });
		iconList.push({ name: 'faDrumstickBite', icon: fa.faDrumstickBite });
		iconList.push({ name: 'faDumbbell', icon: fa.faDumbbell });
		iconList.push({ name: 'faDumpster', icon: fa.faDumpster });
		iconList.push({ name: 'faDumpsterFire', icon: fa.faDumpsterFire });
		iconList.push({ name: 'faDungeon', icon: fa.faDungeon });
		iconList.push({ name: 'faE', icon: fa.faE });
		iconList.push({ name: 'faEarDeaf', icon: fa.faEarDeaf });
		iconList.push({ name: 'faEarListen', icon: fa.faEarListen });
		iconList.push({ name: 'faEarth', icon: fa.faEarth });
		iconList.push({ name: 'faEarthAfrica', icon: fa.faEarthAfrica });
		iconList.push({ name: 'faEarthAmerica', icon: fa.faEarthAmerica });
		iconList.push({ name: 'faEarthAmericas', icon: fa.faEarthAmericas });
		iconList.push({ name: 'faEarthAsia', icon: fa.faEarthAsia });
		iconList.push({ name: 'faEarthEurope', icon: fa.faEarthEurope });
		iconList.push({ name: 'faEarthOceania', icon: fa.faEarthOceania });
		iconList.push({ name: 'faEdit', icon: fa.faEdit });
		iconList.push({ name: 'faEgg', icon: fa.faEgg });
		iconList.push({ name: 'faEject', icon: fa.faEject });
		iconList.push({ name: 'faElevator', icon: fa.faElevator });
		iconList.push({ name: 'faEllipsis', icon: fa.faEllipsis });
		iconList.push({ name: 'faEllipsisH', icon: fa.faEllipsisH });
		iconList.push({ name: 'faEllipsisV', icon: fa.faEllipsisV });
		iconList.push({ name: 'faEllipsisVertical', icon: fa.faEllipsisVertical });
		iconList.push({ name: 'faEnvelope', icon: fa.faEnvelope });
		iconList.push({ name: 'faEnvelopeCircleCheck', icon: fa.faEnvelopeCircleCheck });
		iconList.push({ name: 'faEnvelopeOpen', icon: fa.faEnvelopeOpen });
		iconList.push({ name: 'faEnvelopeOpenText', icon: fa.faEnvelopeOpenText });
		iconList.push({ name: 'faEnvelopeSquare', icon: fa.faEnvelopeSquare });
		iconList.push({ name: 'faEnvelopesBulk', icon: fa.faEnvelopesBulk });
		iconList.push({ name: 'faEquals', icon: fa.faEquals });
		iconList.push({ name: 'faEraser', icon: fa.faEraser });
		iconList.push({ name: 'faEthernet', icon: fa.faEthernet });
		iconList.push({ name: 'faEur', icon: fa.faEur });
		iconList.push({ name: 'faEuro', icon: fa.faEuro });
		iconList.push({ name: 'faEuroSign', icon: fa.faEuroSign });
		iconList.push({ name: 'faExchange', icon: fa.faExchange });
		iconList.push({ name: 'faExchangeAlt', icon: fa.faExchangeAlt });
		iconList.push({ name: 'faExclamation', icon: fa.faExclamation });
		iconList.push({ name: 'faExclamationCircle', icon: fa.faExclamationCircle });
		iconList.push({ name: 'faExclamationTriangle', icon: fa.faExclamationTriangle });
		iconList.push({ name: 'faExpand', icon: fa.faExpand });
		iconList.push({ name: 'faExpandAlt', icon: fa.faExpandAlt });
		iconList.push({ name: 'faExpandArrowsAlt', icon: fa.faExpandArrowsAlt });
		iconList.push({ name: 'faExplosion', icon: fa.faExplosion });
		iconList.push({ name: 'faExternalLink', icon: fa.faExternalLink });
		iconList.push({ name: 'faExternalLinkAlt', icon: fa.faExternalLinkAlt });
		iconList.push({ name: 'faExternalLinkSquare', icon: fa.faExternalLinkSquare });
		iconList.push({ name: 'faExternalLinkSquareAlt', icon: fa.faExternalLinkSquareAlt });
		iconList.push({ name: 'faEye', icon: fa.faEye });
		iconList.push({ name: 'faEyeDropper', icon: fa.faEyeDropper });
		iconList.push({ name: 'faEyeDropperEmpty', icon: fa.faEyeDropperEmpty });
		iconList.push({ name: 'faEyeLowVision', icon: fa.faEyeLowVision });
		iconList.push({ name: 'faEyeSlash', icon: fa.faEyeSlash });
		iconList.push({ name: 'faEyedropper', icon: fa.faEyedropper });
		iconList.push({ name: 'faF', icon: fa.faF });
		iconList.push({ name: 'faFaceAngry', icon: fa.faFaceAngry });
		iconList.push({ name: 'faFaceDizzy', icon: fa.faFaceDizzy });
		iconList.push({ name: 'faFaceFlushed', icon: fa.faFaceFlushed });
		iconList.push({ name: 'faFaceFrown', icon: fa.faFaceFrown });
		iconList.push({ name: 'faFaceFrownOpen', icon: fa.faFaceFrownOpen });
		iconList.push({ name: 'faFaceGrimace', icon: fa.faFaceGrimace });
		iconList.push({ name: 'faFaceGrin', icon: fa.faFaceGrin });
		iconList.push({ name: 'faFaceGrinBeam', icon: fa.faFaceGrinBeam });
		iconList.push({ name: 'faFaceGrinBeamSweat', icon: fa.faFaceGrinBeamSweat });
		iconList.push({ name: 'faFaceGrinHearts', icon: fa.faFaceGrinHearts });
		iconList.push({ name: 'faFaceGrinSquint', icon: fa.faFaceGrinSquint });
		iconList.push({ name: 'faFaceGrinSquintTears', icon: fa.faFaceGrinSquintTears });
		iconList.push({ name: 'faFaceGrinStars', icon: fa.faFaceGrinStars });
		iconList.push({ name: 'faFaceGrinTears', icon: fa.faFaceGrinTears });
		iconList.push({ name: 'faFaceGrinTongue', icon: fa.faFaceGrinTongue });
		iconList.push({ name: 'faFaceGrinTongueSquint', icon: fa.faFaceGrinTongueSquint });
		iconList.push({ name: 'faFaceGrinTongueWink', icon: fa.faFaceGrinTongueWink });
		iconList.push({ name: 'faFaceGrinWide', icon: fa.faFaceGrinWide });
		iconList.push({ name: 'faFaceGrinWink', icon: fa.faFaceGrinWink });
		iconList.push({ name: 'faFaceKiss', icon: fa.faFaceKiss });
		iconList.push({ name: 'faFaceKissBeam', icon: fa.faFaceKissBeam });
		iconList.push({ name: 'faFaceKissWinkHeart', icon: fa.faFaceKissWinkHeart });
		iconList.push({ name: 'faFaceLaugh', icon: fa.faFaceLaugh });
		iconList.push({ name: 'faFaceLaughBeam', icon: fa.faFaceLaughBeam });
		iconList.push({ name: 'faFaceLaughSquint', icon: fa.faFaceLaughSquint });
		iconList.push({ name: 'faFaceLaughWink', icon: fa.faFaceLaughWink });
		iconList.push({ name: 'faFaceMeh', icon: fa.faFaceMeh });
		iconList.push({ name: 'faFaceMehBlank', icon: fa.faFaceMehBlank });
		iconList.push({ name: 'faFaceRollingEyes', icon: fa.faFaceRollingEyes });
		iconList.push({ name: 'faFaceSadCry', icon: fa.faFaceSadCry });
		iconList.push({ name: 'faFaceSadTear', icon: fa.faFaceSadTear });
		iconList.push({ name: 'faFaceSmile', icon: fa.faFaceSmile });
		iconList.push({ name: 'faFaceSmileBeam', icon: fa.faFaceSmileBeam });
		iconList.push({ name: 'faFaceSmileWink', icon: fa.faFaceSmileWink });
		iconList.push({ name: 'faFaceSurprise', icon: fa.faFaceSurprise });
		iconList.push({ name: 'faFaceTired', icon: fa.faFaceTired });
		iconList.push({ name: 'faFan', icon: fa.faFan });
		iconList.push({ name: 'faFastBackward', icon: fa.faFastBackward });
		iconList.push({ name: 'faFastForward', icon: fa.faFastForward });
		iconList.push({ name: 'faFaucet', icon: fa.faFaucet });
		iconList.push({ name: 'faFaucetDrip', icon: fa.faFaucetDrip });
		iconList.push({ name: 'faFax', icon: fa.faFax });
		iconList.push({ name: 'faFeather', icon: fa.faFeather });
		iconList.push({ name: 'faFeatherAlt', icon: fa.faFeatherAlt });
		iconList.push({ name: 'faFeatherPointed', icon: fa.faFeatherPointed });
		iconList.push({ name: 'faFeed', icon: fa.faFeed });
		iconList.push({ name: 'faFemale', icon: fa.faFemale });
		iconList.push({ name: 'faFerry', icon: fa.faFerry });
		iconList.push({ name: 'faFighterJet', icon: fa.faFighterJet });
		iconList.push({ name: 'faFile', icon: fa.faFile });
		iconList.push({ name: 'faFileAlt', icon: fa.faFileAlt });
		iconList.push({ name: 'faFileArchive', icon: fa.faFileArchive });
		iconList.push({ name: 'faFileArrowDown', icon: fa.faFileArrowDown });
		iconList.push({ name: 'faFileArrowUp', icon: fa.faFileArrowUp });
		iconList.push({ name: 'faFileAudio', icon: fa.faFileAudio });
		iconList.push({ name: 'faFileCircleCheck', icon: fa.faFileCircleCheck });
		iconList.push({ name: 'faFileCircleExclamation', icon: fa.faFileCircleExclamation });
		iconList.push({ name: 'faFileCircleMinus', icon: fa.faFileCircleMinus });
		iconList.push({ name: 'faFileCirclePlus', icon: fa.faFileCirclePlus });
		iconList.push({ name: 'faFileCircleQuestion', icon: fa.faFileCircleQuestion });
		iconList.push({ name: 'faFileCircleXmark', icon: fa.faFileCircleXmark });
		iconList.push({ name: 'faFileClipboard', icon: fa.faFileClipboard });
		iconList.push({ name: 'faFileCode', icon: fa.faFileCode });
		iconList.push({ name: 'faFileContract', icon: fa.faFileContract });
		iconList.push({ name: 'faFileCsv', icon: fa.faFileCsv });
		iconList.push({ name: 'faFileDownload', icon: fa.faFileDownload });
		iconList.push({ name: 'faFileEdit', icon: fa.faFileEdit });
		iconList.push({ name: 'faFileExcel', icon: fa.faFileExcel });
		iconList.push({ name: 'faFileExport', icon: fa.faFileExport });
		iconList.push({ name: 'faFileImage', icon: fa.faFileImage });
		iconList.push({ name: 'faFileImport', icon: fa.faFileImport });
		iconList.push({ name: 'faFileInvoice', icon: fa.faFileInvoice });
		iconList.push({ name: 'faFileInvoiceDollar', icon: fa.faFileInvoiceDollar });
		iconList.push({ name: 'faFileLines', icon: fa.faFileLines });
		iconList.push({ name: 'faFileMedical', icon: fa.faFileMedical });
		iconList.push({ name: 'faFileMedicalAlt', icon: fa.faFileMedicalAlt });
		iconList.push({ name: 'faFilePdf', icon: fa.faFilePdf });
		iconList.push({ name: 'faFilePen', icon: fa.faFilePen });
		iconList.push({ name: 'faFilePowerpoint', icon: fa.faFilePowerpoint });
		iconList.push({ name: 'faFilePrescription', icon: fa.faFilePrescription });
		iconList.push({ name: 'faFileShield', icon: fa.faFileShield });
		iconList.push({ name: 'faFileSignature', icon: fa.faFileSignature });
		iconList.push({ name: 'faFileText', icon: fa.faFileText });
		iconList.push({ name: 'faFileUpload', icon: fa.faFileUpload });
		iconList.push({ name: 'faFileVideo', icon: fa.faFileVideo });
		iconList.push({ name: 'faFileWaveform', icon: fa.faFileWaveform });
		iconList.push({ name: 'faFileWord', icon: fa.faFileWord });
		iconList.push({ name: 'faFileZipper', icon: fa.faFileZipper });
		iconList.push({ name: 'faFill', icon: fa.faFill });
		iconList.push({ name: 'faFillDrip', icon: fa.faFillDrip });
		iconList.push({ name: 'faFilm', icon: fa.faFilm });
		iconList.push({ name: 'faFilter', icon: fa.faFilter });
		iconList.push({ name: 'faFilterCircleDollar', icon: fa.faFilterCircleDollar });
		iconList.push({ name: 'faFilterCircleXmark', icon: fa.faFilterCircleXmark });
		iconList.push({ name: 'faFingerprint', icon: fa.faFingerprint });
		iconList.push({ name: 'faFire', icon: fa.faFire });
		iconList.push({ name: 'faFireAlt', icon: fa.faFireAlt });
		iconList.push({ name: 'faFireBurner', icon: fa.faFireBurner });
		iconList.push({ name: 'faFireExtinguisher', icon: fa.faFireExtinguisher });
		iconList.push({ name: 'faFireFlameCurved', icon: fa.faFireFlameCurved });
		iconList.push({ name: 'faFireFlameSimple', icon: fa.faFireFlameSimple });
		iconList.push({ name: 'faFirstAid', icon: fa.faFirstAid });
		iconList.push({ name: 'faFish', icon: fa.faFish });
		iconList.push({ name: 'faFishFins', icon: fa.faFishFins });
		iconList.push({ name: 'faFistRaised', icon: fa.faFistRaised });
		iconList.push({ name: 'faFlag', icon: fa.faFlag });
		iconList.push({ name: 'faFlagCheckered', icon: fa.faFlagCheckered });
		iconList.push({ name: 'faFlagUsa', icon: fa.faFlagUsa });
		iconList.push({ name: 'faFlask', icon: fa.faFlask });
		iconList.push({ name: 'faFlaskVial', icon: fa.faFlaskVial });
		iconList.push({ name: 'faFloppyDisk', icon: fa.faFloppyDisk });
		iconList.push({ name: 'faFlorinSign', icon: fa.faFlorinSign });
		iconList.push({ name: 'faFlushed', icon: fa.faFlushed });
		iconList.push({ name: 'faFolder', icon: fa.faFolder });
		iconList.push({ name: 'faFolderBlank', icon: fa.faFolderBlank });
		iconList.push({ name: 'faFolderClosed', icon: fa.faFolderClosed });
		iconList.push({ name: 'faFolderMinus', icon: fa.faFolderMinus });
		iconList.push({ name: 'faFolderOpen', icon: fa.faFolderOpen });
		iconList.push({ name: 'faFolderPlus', icon: fa.faFolderPlus });
		iconList.push({ name: 'faFolderTree', icon: fa.faFolderTree });
		iconList.push({ name: 'faFont', icon: fa.faFont });
		iconList.push({ name: 'faFontAwesome', icon: fa.faFontAwesome });
		iconList.push({ name: 'faFontAwesomeFlag', icon: fa.faFontAwesomeFlag });
		iconList.push({ name: 'faFontAwesomeLogoFull', icon: fa.faFontAwesomeLogoFull });
		iconList.push({ name: 'faFootball', icon: fa.faFootball });
		iconList.push({ name: 'faFootballBall', icon: fa.faFootballBall });
		iconList.push({ name: 'faForward', icon: fa.faForward });
		iconList.push({ name: 'faForwardFast', icon: fa.faForwardFast });
		iconList.push({ name: 'faForwardStep', icon: fa.faForwardStep });
		iconList.push({ name: 'faFrancSign', icon: fa.faFrancSign });
		iconList.push({ name: 'faFrog', icon: fa.faFrog });
		iconList.push({ name: 'faFrown', icon: fa.faFrown });
		iconList.push({ name: 'faFrownOpen', icon: fa.faFrownOpen });
		iconList.push({ name: 'faFunnelDollar', icon: fa.faFunnelDollar });
		iconList.push({ name: 'faFutbol', icon: fa.faFutbol });
		iconList.push({ name: 'faFutbolBall', icon: fa.faFutbolBall });
		iconList.push({ name: 'faG', icon: fa.faG });
		iconList.push({ name: 'faGamepad', icon: fa.faGamepad });
		iconList.push({ name: 'faGasPump', icon: fa.faGasPump });
		iconList.push({ name: 'faGauge', icon: fa.faGauge });
		iconList.push({ name: 'faGaugeHigh', icon: fa.faGaugeHigh });
		iconList.push({ name: 'faGaugeMed', icon: fa.faGaugeMed });
		iconList.push({ name: 'faGaugeSimple', icon: fa.faGaugeSimple });
		iconList.push({ name: 'faGaugeSimpleHigh', icon: fa.faGaugeSimpleHigh });
		iconList.push({ name: 'faGaugeSimpleMed', icon: fa.faGaugeSimpleMed });
		iconList.push({ name: 'faGavel', icon: fa.faGavel });
		iconList.push({ name: 'faGbp', icon: fa.faGbp });
		iconList.push({ name: 'faGear', icon: fa.faGear });
		iconList.push({ name: 'faGears', icon: fa.faGears });
		iconList.push({ name: 'faGem', icon: fa.faGem });
		iconList.push({ name: 'faGenderless', icon: fa.faGenderless });
		iconList.push({ name: 'faGhost', icon: fa.faGhost });
		iconList.push({ name: 'faGift', icon: fa.faGift });
		iconList.push({ name: 'faGifts', icon: fa.faGifts });
		iconList.push({ name: 'faGlassCheers', icon: fa.faGlassCheers });
		iconList.push({ name: 'faGlassMartini', icon: fa.faGlassMartini });
		iconList.push({ name: 'faGlassMartiniAlt', icon: fa.faGlassMartiniAlt });
		iconList.push({ name: 'faGlassWater', icon: fa.faGlassWater });
		iconList.push({ name: 'faGlassWaterDroplet', icon: fa.faGlassWaterDroplet });
		iconList.push({ name: 'faGlassWhiskey', icon: fa.faGlassWhiskey });
		iconList.push({ name: 'faGlasses', icon: fa.faGlasses });
		iconList.push({ name: 'faGlobe', icon: fa.faGlobe });
		iconList.push({ name: 'faGlobeAfrica', icon: fa.faGlobeAfrica });
		iconList.push({ name: 'faGlobeAmericas', icon: fa.faGlobeAmericas });
		iconList.push({ name: 'faGlobeAsia', icon: fa.faGlobeAsia });
		iconList.push({ name: 'faGlobeEurope', icon: fa.faGlobeEurope });
		iconList.push({ name: 'faGlobeOceania', icon: fa.faGlobeOceania });
		iconList.push({ name: 'faGolfBall', icon: fa.faGolfBall });
		iconList.push({ name: 'faGolfBallTee', icon: fa.faGolfBallTee });
		iconList.push({ name: 'faGopuram', icon: fa.faGopuram });
		iconList.push({ name: 'faGraduationCap', icon: fa.faGraduationCap });
		iconList.push({ name: 'faGreaterThan', icon: fa.faGreaterThan });
		iconList.push({ name: 'faGreaterThanEqual', icon: fa.faGreaterThanEqual });
		iconList.push({ name: 'faGrimace', icon: fa.faGrimace });
		iconList.push({ name: 'faGrin', icon: fa.faGrin });
		iconList.push({ name: 'faGrinAlt', icon: fa.faGrinAlt });
		iconList.push({ name: 'faGrinBeam', icon: fa.faGrinBeam });
		iconList.push({ name: 'faGrinBeamSweat', icon: fa.faGrinBeamSweat });
		iconList.push({ name: 'faGrinHearts', icon: fa.faGrinHearts });
		iconList.push({ name: 'faGrinSquint', icon: fa.faGrinSquint });
		iconList.push({ name: 'faGrinSquintTears', icon: fa.faGrinSquintTears });
		iconList.push({ name: 'faGrinStars', icon: fa.faGrinStars });
		iconList.push({ name: 'faGrinTears', icon: fa.faGrinTears });
		iconList.push({ name: 'faGrinTongue', icon: fa.faGrinTongue });
		iconList.push({ name: 'faGrinTongueSquint', icon: fa.faGrinTongueSquint });
		iconList.push({ name: 'faGrinTongueWink', icon: fa.faGrinTongueWink });
		iconList.push({ name: 'faGrinWink', icon: fa.faGrinWink });
		iconList.push({ name: 'faGrip', icon: fa.faGrip });
		iconList.push({ name: 'faGripHorizontal', icon: fa.faGripHorizontal });
		iconList.push({ name: 'faGripLines', icon: fa.faGripLines });
		iconList.push({ name: 'faGripLinesVertical', icon: fa.faGripLinesVertical });
		iconList.push({ name: 'faGripVertical', icon: fa.faGripVertical });
		iconList.push({ name: 'faGroupArrowsRotate', icon: fa.faGroupArrowsRotate });
		iconList.push({ name: 'faGuaraniSign', icon: fa.faGuaraniSign });
		iconList.push({ name: 'faGuitar', icon: fa.faGuitar });
		iconList.push({ name: 'faGun', icon: fa.faGun });
		iconList.push({ name: 'faH', icon: fa.faH });
		iconList.push({ name: 'faHSquare', icon: fa.faHSquare });
		iconList.push({ name: 'faHamburger', icon: fa.faHamburger });
		iconList.push({ name: 'faHammer', icon: fa.faHammer });
		iconList.push({ name: 'faHamsa', icon: fa.faHamsa });
		iconList.push({ name: 'faHand', icon: fa.faHand });
		iconList.push({ name: 'faHandBackFist', icon: fa.faHandBackFist });
		iconList.push({ name: 'faHandDots', icon: fa.faHandDots });
		iconList.push({ name: 'faHandFist', icon: fa.faHandFist });
		iconList.push({ name: 'faHandHolding', icon: fa.faHandHolding });
		iconList.push({ name: 'faHandHoldingDollar', icon: fa.faHandHoldingDollar });
		iconList.push({ name: 'faHandHoldingDroplet', icon: fa.faHandHoldingDroplet });
		iconList.push({ name: 'faHandHoldingHand', icon: fa.faHandHoldingHand });
		iconList.push({ name: 'faHandHoldingHeart', icon: fa.faHandHoldingHeart });
		iconList.push({ name: 'faHandHoldingMedical', icon: fa.faHandHoldingMedical });
		iconList.push({ name: 'faHandHoldingUsd', icon: fa.faHandHoldingUsd });
		iconList.push({ name: 'faHandHoldingWater', icon: fa.faHandHoldingWater });
		iconList.push({ name: 'faHandLizard', icon: fa.faHandLizard });
		iconList.push({ name: 'faHandMiddleFinger', icon: fa.faHandMiddleFinger });
		iconList.push({ name: 'faHandPaper', icon: fa.faHandPaper });
		iconList.push({ name: 'faHandPeace', icon: fa.faHandPeace });
		iconList.push({ name: 'faHandPointDown', icon: fa.faHandPointDown });
		iconList.push({ name: 'faHandPointLeft', icon: fa.faHandPointLeft });
		iconList.push({ name: 'faHandPointRight', icon: fa.faHandPointRight });
		iconList.push({ name: 'faHandPointUp', icon: fa.faHandPointUp });
		iconList.push({ name: 'faHandPointer', icon: fa.faHandPointer });
		iconList.push({ name: 'faHandRock', icon: fa.faHandRock });
		iconList.push({ name: 'faHandScissors', icon: fa.faHandScissors });
		iconList.push({ name: 'faHandSparkles', icon: fa.faHandSparkles });
		iconList.push({ name: 'faHandSpock', icon: fa.faHandSpock });
		iconList.push({ name: 'faHandcuffs', icon: fa.faHandcuffs });
		iconList.push({ name: 'faHands', icon: fa.faHands });
		iconList.push({ name: 'faHandsAmericanSignLanguageInterpreting', icon: fa.faHandsAmericanSignLanguageInterpreting });
		iconList.push({ name: 'faHandsAslInterpreting', icon: fa.faHandsAslInterpreting });
		iconList.push({ name: 'faHandsBound', icon: fa.faHandsBound });
		iconList.push({ name: 'faHandsBubbles', icon: fa.faHandsBubbles });
		iconList.push({ name: 'faHandsClapping', icon: fa.faHandsClapping });
		iconList.push({ name: 'faHandsHelping', icon: fa.faHandsHelping });
		iconList.push({ name: 'faHandsHolding', icon: fa.faHandsHolding });
		iconList.push({ name: 'faHandsHoldingChild', icon: fa.faHandsHoldingChild });
		iconList.push({ name: 'faHandsHoldingCircle', icon: fa.faHandsHoldingCircle });
		iconList.push({ name: 'faHandsPraying', icon: fa.faHandsPraying });
		iconList.push({ name: 'faHandsWash', icon: fa.faHandsWash });
		iconList.push({ name: 'faHandshake', icon: fa.faHandshake });
		iconList.push({ name: 'faHandshakeAlt', icon: fa.faHandshakeAlt });
		iconList.push({ name: 'faHandshakeAltSlash', icon: fa.faHandshakeAltSlash });
		iconList.push({ name: 'faHandshakeAngle', icon: fa.faHandshakeAngle });
		iconList.push({ name: 'faHandshakeSimple', icon: fa.faHandshakeSimple });
		iconList.push({ name: 'faHandshakeSimpleSlash', icon: fa.faHandshakeSimpleSlash });
		iconList.push({ name: 'faHandshakeSlash', icon: fa.faHandshakeSlash });
		iconList.push({ name: 'faHanukiah', icon: fa.faHanukiah });
		iconList.push({ name: 'faHardDrive', icon: fa.faHardDrive });
		iconList.push({ name: 'faHardHat', icon: fa.faHardHat });
		iconList.push({ name: 'faHardOfHearing', icon: fa.faHardOfHearing });
		iconList.push({ name: 'faHashtag', icon: fa.faHashtag });
		iconList.push({ name: 'faHatCowboy', icon: fa.faHatCowboy });
		iconList.push({ name: 'faHatCowboySide', icon: fa.faHatCowboySide });
		iconList.push({ name: 'faHatHard', icon: fa.faHatHard });
		iconList.push({ name: 'faHatWizard', icon: fa.faHatWizard });
		iconList.push({ name: 'faHaykal', icon: fa.faHaykal });
		iconList.push({ name: 'faHdd', icon: fa.faHdd });
		iconList.push({ name: 'faHeadSideCough', icon: fa.faHeadSideCough });
		iconList.push({ name: 'faHeadSideCoughSlash', icon: fa.faHeadSideCoughSlash });
		iconList.push({ name: 'faHeadSideMask', icon: fa.faHeadSideMask });
		iconList.push({ name: 'faHeadSideVirus', icon: fa.faHeadSideVirus });
		iconList.push({ name: 'faHeader', icon: fa.faHeader });
		iconList.push({ name: 'faHeading', icon: fa.faHeading });
		iconList.push({ name: 'faHeadphones', icon: fa.faHeadphones });
		iconList.push({ name: 'faHeadphonesAlt', icon: fa.faHeadphonesAlt });
		iconList.push({ name: 'faHeadphonesSimple', icon: fa.faHeadphonesSimple });
		iconList.push({ name: 'faHeadset', icon: fa.faHeadset });
		iconList.push({ name: 'faHeart', icon: fa.faHeart });
		iconList.push({ name: 'faHeartBroken', icon: fa.faHeartBroken });
		iconList.push({ name: 'faHeartCircleBolt', icon: fa.faHeartCircleBolt });
		iconList.push({ name: 'faHeartCircleCheck', icon: fa.faHeartCircleCheck });
		iconList.push({ name: 'faHeartCircleExclamation', icon: fa.faHeartCircleExclamation });
		iconList.push({ name: 'faHeartCircleMinus', icon: fa.faHeartCircleMinus });
		iconList.push({ name: 'faHeartCirclePlus', icon: fa.faHeartCirclePlus });
		iconList.push({ name: 'faHeartCircleXmark', icon: fa.faHeartCircleXmark });
		iconList.push({ name: 'faHeartCrack', icon: fa.faHeartCrack });
		iconList.push({ name: 'faHeartMusicCameraBolt', icon: fa.faHeartMusicCameraBolt });
		iconList.push({ name: 'faHeartPulse', icon: fa.faHeartPulse });
		iconList.push({ name: 'faHeartbeat', icon: fa.faHeartbeat });
		iconList.push({ name: 'faHelicopter', icon: fa.faHelicopter });
		iconList.push({ name: 'faHelicopterSymbol', icon: fa.faHelicopterSymbol });
		iconList.push({ name: 'faHelmetSafety', icon: fa.faHelmetSafety });
		iconList.push({ name: 'faHelmetUn', icon: fa.faHelmetUn });
		iconList.push({ name: 'faHighlighter', icon: fa.faHighlighter });
		iconList.push({ name: 'faHiking', icon: fa.faHiking });
		iconList.push({ name: 'faHillAvalanche', icon: fa.faHillAvalanche });
		iconList.push({ name: 'faHillRockslide', icon: fa.faHillRockslide });
		iconList.push({ name: 'faHippo', icon: fa.faHippo });
		iconList.push({ name: 'faHistory', icon: fa.faHistory });
		iconList.push({ name: 'faHockeyPuck', icon: fa.faHockeyPuck });
		iconList.push({ name: 'faHollyBerry', icon: fa.faHollyBerry });
		iconList.push({ name: 'faHome', icon: fa.faHome });
		iconList.push({ name: 'faHomeAlt', icon: fa.faHomeAlt });
		iconList.push({ name: 'faHomeLg', icon: fa.faHomeLg });
		iconList.push({ name: 'faHomeLgAlt', icon: fa.faHomeLgAlt });
		iconList.push({ name: 'faHomeUser', icon: fa.faHomeUser });
		iconList.push({ name: 'faHorse', icon: fa.faHorse });
		iconList.push({ name: 'faHorseHead', icon: fa.faHorseHead });
		iconList.push({ name: 'faHospital', icon: fa.faHospital });
		iconList.push({ name: 'faHospitalAlt', icon: fa.faHospitalAlt });
		iconList.push({ name: 'faHospitalSymbol', icon: fa.faHospitalSymbol });
		iconList.push({ name: 'faHospitalUser', icon: fa.faHospitalUser });
		iconList.push({ name: 'faHospitalWide', icon: fa.faHospitalWide });
		iconList.push({ name: 'faHotTub', icon: fa.faHotTub });
		iconList.push({ name: 'faHotTubPerson', icon: fa.faHotTubPerson });
		iconList.push({ name: 'faHotdog', icon: fa.faHotdog });
		iconList.push({ name: 'faHotel', icon: fa.faHotel });
		iconList.push({ name: 'faHourglass', icon: fa.faHourglass });
		iconList.push({ name: 'faHourglass1', icon: fa.faHourglass1 });
		iconList.push({ name: 'faHourglass2', icon: fa.faHourglass2 });
		iconList.push({ name: 'faHourglass3', icon: fa.faHourglass3 });
		iconList.push({ name: 'faHourglassEmpty', icon: fa.faHourglassEmpty });
		iconList.push({ name: 'faHourglassEnd', icon: fa.faHourglassEnd });
		iconList.push({ name: 'faHourglassHalf', icon: fa.faHourglassHalf });
		iconList.push({ name: 'faHourglassStart', icon: fa.faHourglassStart });
		iconList.push({ name: 'faHouse', icon: fa.faHouse });
		iconList.push({ name: 'faHouseChimney', icon: fa.faHouseChimney });
		iconList.push({ name: 'faHouseChimneyCrack', icon: fa.faHouseChimneyCrack });
		iconList.push({ name: 'faHouseChimneyMedical', icon: fa.faHouseChimneyMedical });
		iconList.push({ name: 'faHouseChimneyUser', icon: fa.faHouseChimneyUser });
		iconList.push({ name: 'faHouseChimneyWindow', icon: fa.faHouseChimneyWindow });
		iconList.push({ name: 'faHouseCircleCheck', icon: fa.faHouseCircleCheck });
		iconList.push({ name: 'faHouseCircleExclamation', icon: fa.faHouseCircleExclamation });
		iconList.push({ name: 'faHouseCircleXmark', icon: fa.faHouseCircleXmark });
		iconList.push({ name: 'faHouseCrack', icon: fa.faHouseCrack });
		iconList.push({ name: 'faHouseDamage', icon: fa.faHouseDamage });
		iconList.push({ name: 'faHouseFire', icon: fa.faHouseFire });
		iconList.push({ name: 'faHouseFlag', icon: fa.faHouseFlag });
		iconList.push({ name: 'faHouseFloodWater', icon: fa.faHouseFloodWater });
		iconList.push({ name: 'faHouseFloodWaterCircleArrowRight', icon: fa.faHouseFloodWaterCircleArrowRight });
		iconList.push({ name: 'faHouseLaptop', icon: fa.faHouseLaptop });
		iconList.push({ name: 'faHouseLock', icon: fa.faHouseLock });
		iconList.push({ name: 'faHouseMedical', icon: fa.faHouseMedical });
		iconList.push({ name: 'faHouseMedicalCircleCheck', icon: fa.faHouseMedicalCircleCheck });
		iconList.push({ name: 'faHouseMedicalCircleExclamation', icon: fa.faHouseMedicalCircleExclamation });
		iconList.push({ name: 'faHouseMedicalCircleXmark', icon: fa.faHouseMedicalCircleXmark });
		iconList.push({ name: 'faHouseMedicalFlag', icon: fa.faHouseMedicalFlag });
		iconList.push({ name: 'faHouseSignal', icon: fa.faHouseSignal });
		iconList.push({ name: 'faHouseTsunami', icon: fa.faHouseTsunami });
		iconList.push({ name: 'faHouseUser', icon: fa.faHouseUser });
		iconList.push({ name: 'faHryvnia', icon: fa.faHryvnia });
		iconList.push({ name: 'faHryvniaSign', icon: fa.faHryvniaSign });
		iconList.push({ name: 'faHurricane', icon: fa.faHurricane });
		iconList.push({ name: 'faI', icon: fa.faI });
		iconList.push({ name: 'faICursor', icon: fa.faICursor });
		iconList.push({ name: 'faIceCream', icon: fa.faIceCream });
		iconList.push({ name: 'faIcicles', icon: fa.faIcicles });
		iconList.push({ name: 'faIcons', icon: fa.faIcons });
		iconList.push({ name: 'faIdBadge', icon: fa.faIdBadge });
		iconList.push({ name: 'faIdCard', icon: fa.faIdCard });
		iconList.push({ name: 'faIdCardAlt', icon: fa.faIdCardAlt });
		iconList.push({ name: 'faIdCardClip', icon: fa.faIdCardClip });
		iconList.push({ name: 'faIgloo', icon: fa.faIgloo });
		iconList.push({ name: 'faIls', icon: fa.faIls });
		iconList.push({ name: 'faImage', icon: fa.faImage });
		iconList.push({ name: 'faImagePortrait', icon: fa.faImagePortrait });
		iconList.push({ name: 'faImages', icon: fa.faImages });
		iconList.push({ name: 'faInbox', icon: fa.faInbox });
		iconList.push({ name: 'faIndent', icon: fa.faIndent });
		iconList.push({ name: 'faIndianRupee', icon: fa.faIndianRupee });
		iconList.push({ name: 'faIndianRupeeSign', icon: fa.faIndianRupeeSign });
		iconList.push({ name: 'faIndustry', icon: fa.faIndustry });
		iconList.push({ name: 'faInfinity', icon: fa.faInfinity });
		iconList.push({ name: 'faInfo', icon: fa.faInfo });
		iconList.push({ name: 'faInfoCircle', icon: fa.faInfoCircle });
		iconList.push({ name: 'faInr', icon: fa.faInr });
		iconList.push({ name: 'faInstitution', icon: fa.faInstitution });
		iconList.push({ name: 'faItalic', icon: fa.faItalic });
		iconList.push({ name: 'faJ', icon: fa.faJ });
		iconList.push({ name: 'faJar', icon: fa.faJar });
		iconList.push({ name: 'faJarWheat', icon: fa.faJarWheat });
		iconList.push({ name: 'faJedi', icon: fa.faJedi });
		iconList.push({ name: 'faJetFighter', icon: fa.faJetFighter });
		iconList.push({ name: 'faJetFighterUp', icon: fa.faJetFighterUp });
		iconList.push({ name: 'faJoint', icon: fa.faJoint });
		iconList.push({ name: 'faJournalWhills', icon: fa.faJournalWhills });
		iconList.push({ name: 'faJpy', icon: fa.faJpy });
		iconList.push({ name: 'faJugDetergent', icon: fa.faJugDetergent });
		iconList.push({ name: 'faK', icon: fa.faK });
		iconList.push({ name: 'faKaaba', icon: fa.faKaaba });
		iconList.push({ name: 'faKey', icon: fa.faKey });
		iconList.push({ name: 'faKeyboard', icon: fa.faKeyboard });
		iconList.push({ name: 'faKhanda', icon: fa.faKhanda });
		iconList.push({ name: 'faKipSign', icon: fa.faKipSign });
		iconList.push({ name: 'faKiss', icon: fa.faKiss });
		iconList.push({ name: 'faKissBeam', icon: fa.faKissBeam });
		iconList.push({ name: 'faKissWinkHeart', icon: fa.faKissWinkHeart });
		iconList.push({ name: 'faKitMedical', icon: fa.faKitMedical });
		iconList.push({ name: 'faKitchenSet', icon: fa.faKitchenSet });
		iconList.push({ name: 'faKiwiBird', icon: fa.faKiwiBird });
		iconList.push({ name: 'faKrw', icon: fa.faKrw });
		iconList.push({ name: 'faL', icon: fa.faL });
		iconList.push({ name: 'faLadderWater', icon: fa.faLadderWater });
		iconList.push({ name: 'faLandMineOn', icon: fa.faLandMineOn });
		iconList.push({ name: 'faLandmark', icon: fa.faLandmark });
		iconList.push({ name: 'faLandmarkAlt', icon: fa.faLandmarkAlt });
		iconList.push({ name: 'faLandmarkDome', icon: fa.faLandmarkDome });
		iconList.push({ name: 'faLandmarkFlag', icon: fa.faLandmarkFlag });
		iconList.push({ name: 'faLanguage', icon: fa.faLanguage });
		iconList.push({ name: 'faLaptop', icon: fa.faLaptop });
		iconList.push({ name: 'faLaptopCode', icon: fa.faLaptopCode });
		iconList.push({ name: 'faLaptopFile', icon: fa.faLaptopFile });
		iconList.push({ name: 'faLaptopHouse', icon: fa.faLaptopHouse });
		iconList.push({ name: 'faLaptopMedical', icon: fa.faLaptopMedical });
		iconList.push({ name: 'faLariSign', icon: fa.faLariSign });
		iconList.push({ name: 'faLaugh', icon: fa.faLaugh });
		iconList.push({ name: 'faLaughBeam', icon: fa.faLaughBeam });
		iconList.push({ name: 'faLaughSquint', icon: fa.faLaughSquint });
		iconList.push({ name: 'faLaughWink', icon: fa.faLaughWink });
		iconList.push({ name: 'faLayerGroup', icon: fa.faLayerGroup });
		iconList.push({ name: 'faLeaf', icon: fa.faLeaf });
		iconList.push({ name: 'faLeftLong', icon: fa.faLeftLong });
		iconList.push({ name: 'faLeftRight', icon: fa.faLeftRight });
		iconList.push({ name: 'faLegal', icon: fa.faLegal });
		iconList.push({ name: 'faLemon', icon: fa.faLemon });
		iconList.push({ name: 'faLessThan', icon: fa.faLessThan });
		iconList.push({ name: 'faLessThanEqual', icon: fa.faLessThanEqual });
		iconList.push({ name: 'faLevelDown', icon: fa.faLevelDown });
		iconList.push({ name: 'faLevelDownAlt', icon: fa.faLevelDownAlt });
		iconList.push({ name: 'faLevelUp', icon: fa.faLevelUp });
		iconList.push({ name: 'faLevelUpAlt', icon: fa.faLevelUpAlt });
		iconList.push({ name: 'faLifeRing', icon: fa.faLifeRing });
		iconList.push({ name: 'faLightbulb', icon: fa.faLightbulb });
		iconList.push({ name: 'faLineChart', icon: fa.faLineChart });
		iconList.push({ name: 'faLinesLeaning', icon: fa.faLinesLeaning });
		iconList.push({ name: 'faLink', icon: fa.faLink });
		iconList.push({ name: 'faLinkSlash', icon: fa.faLinkSlash });
		iconList.push({ name: 'faLiraSign', icon: fa.faLiraSign });
		iconList.push({ name: 'faList', icon: fa.faList });
		iconList.push({ name: 'faList12', icon: fa.faList12 });
		iconList.push({ name: 'faListAlt', icon: fa.faListAlt });
		iconList.push({ name: 'faListCheck', icon: fa.faListCheck });
		iconList.push({ name: 'faListDots', icon: fa.faListDots });
		iconList.push({ name: 'faListNumeric', icon: fa.faListNumeric });
		iconList.push({ name: 'faListOl', icon: fa.faListOl });
		iconList.push({ name: 'faListSquares', icon: fa.faListSquares });
		iconList.push({ name: 'faListUl', icon: fa.faListUl });
		iconList.push({ name: 'faLitecoinSign', icon: fa.faLitecoinSign });
		iconList.push({ name: 'faLocation', icon: fa.faLocation });
		iconList.push({ name: 'faLocationArrow', icon: fa.faLocationArrow });
		iconList.push({ name: 'faLocationCrosshairs', icon: fa.faLocationCrosshairs });
		iconList.push({ name: 'faLocationDot', icon: fa.faLocationDot });
		iconList.push({ name: 'faLocationPin', icon: fa.faLocationPin });
		iconList.push({ name: 'faLocationPinLock', icon: fa.faLocationPinLock });
		iconList.push({ name: 'faLock', icon: fa.faLock });
		iconList.push({ name: 'faLockOpen', icon: fa.faLockOpen });
		iconList.push({ name: 'faLocust', icon: fa.faLocust });
		iconList.push({ name: 'faLongArrowAltDown', icon: fa.faLongArrowAltDown });
		iconList.push({ name: 'faLongArrowAltLeft', icon: fa.faLongArrowAltLeft });
		iconList.push({ name: 'faLongArrowAltRight', icon: fa.faLongArrowAltRight });
		iconList.push({ name: 'faLongArrowAltUp', icon: fa.faLongArrowAltUp });
		iconList.push({ name: 'faLongArrowDown', icon: fa.faLongArrowDown });
		iconList.push({ name: 'faLongArrowLeft', icon: fa.faLongArrowLeft });
		iconList.push({ name: 'faLongArrowRight', icon: fa.faLongArrowRight });
		iconList.push({ name: 'faLongArrowUp', icon: fa.faLongArrowUp });
		iconList.push({ name: 'faLowVision', icon: fa.faLowVision });
		iconList.push({ name: 'faLuggageCart', icon: fa.faLuggageCart });
		iconList.push({ name: 'faLungs', icon: fa.faLungs });
		iconList.push({ name: 'faLungsVirus', icon: fa.faLungsVirus });
		iconList.push({ name: 'faM', icon: fa.faM });
		iconList.push({ name: 'faMagic', icon: fa.faMagic });
		iconList.push({ name: 'faMagicWandSparkles', icon: fa.faMagicWandSparkles });
		iconList.push({ name: 'faMagnet', icon: fa.faMagnet });
		iconList.push({ name: 'faMagnifyingGlass', icon: fa.faMagnifyingGlass });
		iconList.push({ name: 'faMagnifyingGlassArrowRight', icon: fa.faMagnifyingGlassArrowRight });
		iconList.push({ name: 'faMagnifyingGlassChart', icon: fa.faMagnifyingGlassChart });
		iconList.push({ name: 'faMagnifyingGlassDollar', icon: fa.faMagnifyingGlassDollar });
		iconList.push({ name: 'faMagnifyingGlassLocation', icon: fa.faMagnifyingGlassLocation });
		iconList.push({ name: 'faMagnifyingGlassMinus', icon: fa.faMagnifyingGlassMinus });
		iconList.push({ name: 'faMagnifyingGlassPlus', icon: fa.faMagnifyingGlassPlus });
		iconList.push({ name: 'faMailBulk', icon: fa.faMailBulk });
		iconList.push({ name: 'faMailForward', icon: fa.faMailForward });
		iconList.push({ name: 'faMailReply', icon: fa.faMailReply });
		iconList.push({ name: 'faMailReplyAll', icon: fa.faMailReplyAll });
		iconList.push({ name: 'faMale', icon: fa.faMale });
		iconList.push({ name: 'faManatSign', icon: fa.faManatSign });
		iconList.push({ name: 'faMap', icon: fa.faMap });
		iconList.push({ name: 'faMapLocation', icon: fa.faMapLocation });
		iconList.push({ name: 'faMapLocationDot', icon: fa.faMapLocationDot });
		iconList.push({ name: 'faMapMarked', icon: fa.faMapMarked });
		iconList.push({ name: 'faMapMarkedAlt', icon: fa.faMapMarkedAlt });
		iconList.push({ name: 'faMapMarker', icon: fa.faMapMarker });
		iconList.push({ name: 'faMapMarkerAlt', icon: fa.faMapMarkerAlt });
		iconList.push({ name: 'faMapPin', icon: fa.faMapPin });
		iconList.push({ name: 'faMapSigns', icon: fa.faMapSigns });
		iconList.push({ name: 'faMarker', icon: fa.faMarker });
		iconList.push({ name: 'faMars', icon: fa.faMars });
		iconList.push({ name: 'faMarsAndVenus', icon: fa.faMarsAndVenus });
		iconList.push({ name: 'faMarsAndVenusBurst', icon: fa.faMarsAndVenusBurst });
		iconList.push({ name: 'faMarsDouble', icon: fa.faMarsDouble });
		iconList.push({ name: 'faMarsStroke', icon: fa.faMarsStroke });
		iconList.push({ name: 'faMarsStrokeH', icon: fa.faMarsStrokeH });
		iconList.push({ name: 'faMarsStrokeRight', icon: fa.faMarsStrokeRight });
		iconList.push({ name: 'faMarsStrokeUp', icon: fa.faMarsStrokeUp });
		iconList.push({ name: 'faMarsStrokeV', icon: fa.faMarsStrokeV });
		iconList.push({ name: 'faMartiniGlass', icon: fa.faMartiniGlass });
		iconList.push({ name: 'faMartiniGlassCitrus', icon: fa.faMartiniGlassCitrus });
		iconList.push({ name: 'faMartiniGlassEmpty', icon: fa.faMartiniGlassEmpty });
		iconList.push({ name: 'faMask', icon: fa.faMask });
		iconList.push({ name: 'faMaskFace', icon: fa.faMaskFace });
		iconList.push({ name: 'faMaskVentilator', icon: fa.faMaskVentilator });
		iconList.push({ name: 'faMasksTheater', icon: fa.faMasksTheater });
		iconList.push({ name: 'faMattressPillow', icon: fa.faMattressPillow });
		iconList.push({ name: 'faMaximize', icon: fa.faMaximize });
		iconList.push({ name: 'faMedal', icon: fa.faMedal });
		iconList.push({ name: 'faMedkit', icon: fa.faMedkit });
		iconList.push({ name: 'faMeh', icon: fa.faMeh });
		iconList.push({ name: 'faMehBlank', icon: fa.faMehBlank });
		iconList.push({ name: 'faMehRollingEyes', icon: fa.faMehRollingEyes });
		iconList.push({ name: 'faMemory', icon: fa.faMemory });
		iconList.push({ name: 'faMenorah', icon: fa.faMenorah });
		iconList.push({ name: 'faMercury', icon: fa.faMercury });
		iconList.push({ name: 'faMessage', icon: fa.faMessage });
		iconList.push({ name: 'faMeteor', icon: fa.faMeteor });
		iconList.push({ name: 'faMicrochip', icon: fa.faMicrochip });
		iconList.push({ name: 'faMicrophone', icon: fa.faMicrophone });
		iconList.push({ name: 'faMicrophoneAlt', icon: fa.faMicrophoneAlt });
		iconList.push({ name: 'faMicrophoneAltSlash', icon: fa.faMicrophoneAltSlash });
		iconList.push({ name: 'faMicrophoneLines', icon: fa.faMicrophoneLines });
		iconList.push({ name: 'faMicrophoneLinesSlash', icon: fa.faMicrophoneLinesSlash });
		iconList.push({ name: 'faMicrophoneSlash', icon: fa.faMicrophoneSlash });
		iconList.push({ name: 'faMicroscope', icon: fa.faMicroscope });
		iconList.push({ name: 'faMillSign', icon: fa.faMillSign });
		iconList.push({ name: 'faMinimize', icon: fa.faMinimize });
		iconList.push({ name: 'faMinus', icon: fa.faMinus });
		iconList.push({ name: 'faMinusCircle', icon: fa.faMinusCircle });
		iconList.push({ name: 'faMinusSquare', icon: fa.faMinusSquare });
		iconList.push({ name: 'faMitten', icon: fa.faMitten });
		iconList.push({ name: 'faMobile', icon: fa.faMobile });
		iconList.push({ name: 'faMobileAlt', icon: fa.faMobileAlt });
		iconList.push({ name: 'faMobileAndroid', icon: fa.faMobileAndroid });
		iconList.push({ name: 'faMobileAndroidAlt', icon: fa.faMobileAndroidAlt });
		iconList.push({ name: 'faMobileButton', icon: fa.faMobileButton });
		iconList.push({ name: 'faMobilePhone', icon: fa.faMobilePhone });
		iconList.push({ name: 'faMobileRetro', icon: fa.faMobileRetro });
		iconList.push({ name: 'faMobileScreen', icon: fa.faMobileScreen });
		iconList.push({ name: 'faMobileScreenButton', icon: fa.faMobileScreenButton });
		iconList.push({ name: 'faMoneyBill', icon: fa.faMoneyBill });
		iconList.push({ name: 'faMoneyBill1', icon: fa.faMoneyBill1 });
		iconList.push({ name: 'faMoneyBill1Wave', icon: fa.faMoneyBill1Wave });
		iconList.push({ name: 'faMoneyBillAlt', icon: fa.faMoneyBillAlt });
		iconList.push({ name: 'faMoneyBillTransfer', icon: fa.faMoneyBillTransfer });
		iconList.push({ name: 'faMoneyBillTrendUp', icon: fa.faMoneyBillTrendUp });
		iconList.push({ name: 'faMoneyBillWave', icon: fa.faMoneyBillWave });
		iconList.push({ name: 'faMoneyBillWaveAlt', icon: fa.faMoneyBillWaveAlt });
		iconList.push({ name: 'faMoneyBillWheat', icon: fa.faMoneyBillWheat });
		iconList.push({ name: 'faMoneyBills', icon: fa.faMoneyBills });
		iconList.push({ name: 'faMoneyCheck', icon: fa.faMoneyCheck });
		iconList.push({ name: 'faMoneyCheckAlt', icon: fa.faMoneyCheckAlt });
		iconList.push({ name: 'faMoneyCheckDollar', icon: fa.faMoneyCheckDollar });
		iconList.push({ name: 'faMonument', icon: fa.faMonument });
		iconList.push({ name: 'faMoon', icon: fa.faMoon });
		iconList.push({ name: 'faMortarBoard', icon: fa.faMortarBoard });
		iconList.push({ name: 'faMortarPestle', icon: fa.faMortarPestle });
		iconList.push({ name: 'faMosque', icon: fa.faMosque });
		iconList.push({ name: 'faMosquito', icon: fa.faMosquito });
		iconList.push({ name: 'faMosquitoNet', icon: fa.faMosquitoNet });
		iconList.push({ name: 'faMotorcycle', icon: fa.faMotorcycle });
		iconList.push({ name: 'faMound', icon: fa.faMound });
		iconList.push({ name: 'faMountain', icon: fa.faMountain });
		iconList.push({ name: 'faMountainCity', icon: fa.faMountainCity });
		iconList.push({ name: 'faMountainSun', icon: fa.faMountainSun });
		iconList.push({ name: 'faMouse', icon: fa.faMouse });
		iconList.push({ name: 'faMousePointer', icon: fa.faMousePointer });
		iconList.push({ name: 'faMugHot', icon: fa.faMugHot });
		iconList.push({ name: 'faMugSaucer', icon: fa.faMugSaucer });
		iconList.push({ name: 'faMultiply', icon: fa.faMultiply });
		iconList.push({ name: 'faMuseum', icon: fa.faMuseum });
		iconList.push({ name: 'faMusic', icon: fa.faMusic });
		iconList.push({ name: 'faN', icon: fa.faN });
		iconList.push({ name: 'faNairaSign', icon: fa.faNairaSign });
		iconList.push({ name: 'faNavicon', icon: fa.faNavicon });
		iconList.push({ name: 'faNetworkWired', icon: fa.faNetworkWired });
		iconList.push({ name: 'faNeuter', icon: fa.faNeuter });
		iconList.push({ name: 'faNewspaper', icon: fa.faNewspaper });
		iconList.push({ name: 'faNotEqual', icon: fa.faNotEqual });
		iconList.push({ name: 'faNotdef', icon: fa.faNotdef });
		iconList.push({ name: 'faNoteSticky', icon: fa.faNoteSticky });
		iconList.push({ name: 'faNotesMedical', icon: fa.faNotesMedical });
		iconList.push({ name: 'faO', icon: fa.faO });
		iconList.push({ name: 'faObjectGroup', icon: fa.faObjectGroup });
		iconList.push({ name: 'faObjectUngroup', icon: fa.faObjectUngroup });
		iconList.push({ name: 'faOilCan', icon: fa.faOilCan });
		iconList.push({ name: 'faOilWell', icon: fa.faOilWell });
		iconList.push({ name: 'faOm', icon: fa.faOm });
		iconList.push({ name: 'faOtter', icon: fa.faOtter });
		iconList.push({ name: 'faOutdent', icon: fa.faOutdent });
		iconList.push({ name: 'faP', icon: fa.faP });
		iconList.push({ name: 'faPager', icon: fa.faPager });
		iconList.push({ name: 'faPaintBrush', icon: fa.faPaintBrush });
		iconList.push({ name: 'faPaintRoller', icon: fa.faPaintRoller });
		iconList.push({ name: 'faPaintbrush', icon: fa.faPaintbrush });
		iconList.push({ name: 'faPalette', icon: fa.faPalette });
		iconList.push({ name: 'faPallet', icon: fa.faPallet });
		iconList.push({ name: 'faPanorama', icon: fa.faPanorama });
		iconList.push({ name: 'faPaperPlane', icon: fa.faPaperPlane });
		iconList.push({ name: 'faPaperclip', icon: fa.faPaperclip });
		iconList.push({ name: 'faParachuteBox', icon: fa.faParachuteBox });
		iconList.push({ name: 'faParagraph', icon: fa.faParagraph });
		iconList.push({ name: 'faParking', icon: fa.faParking });
		iconList.push({ name: 'faPassport', icon: fa.faPassport });
		iconList.push({ name: 'faPastafarianism', icon: fa.faPastafarianism });
		iconList.push({ name: 'faPaste', icon: fa.faPaste });
		iconList.push({ name: 'faPause', icon: fa.faPause });
		iconList.push({ name: 'faPauseCircle', icon: fa.faPauseCircle });
		iconList.push({ name: 'faPaw', icon: fa.faPaw });
		iconList.push({ name: 'faPeace', icon: fa.faPeace });
		iconList.push({ name: 'faPen', icon: fa.faPen });
		iconList.push({ name: 'faPenAlt', icon: fa.faPenAlt });
		iconList.push({ name: 'faPenClip', icon: fa.faPenClip });
		iconList.push({ name: 'faPenFancy', icon: fa.faPenFancy });
		iconList.push({ name: 'faPenNib', icon: fa.faPenNib });
		iconList.push({ name: 'faPenRuler', icon: fa.faPenRuler });
		iconList.push({ name: 'faPenSquare', icon: fa.faPenSquare });
		iconList.push({ name: 'faPenToSquare', icon: fa.faPenToSquare });
		iconList.push({ name: 'faPencil', icon: fa.faPencil });
		iconList.push({ name: 'faPencilAlt', icon: fa.faPencilAlt });
		iconList.push({ name: 'faPencilRuler', icon: fa.faPencilRuler });
		iconList.push({ name: 'faPencilSquare', icon: fa.faPencilSquare });
		iconList.push({ name: 'faPeopleArrows', icon: fa.faPeopleArrows });
		iconList.push({ name: 'faPeopleArrowsLeftRight', icon: fa.faPeopleArrowsLeftRight });
		iconList.push({ name: 'faPeopleCarry', icon: fa.faPeopleCarry });
		iconList.push({ name: 'faPeopleCarryBox', icon: fa.faPeopleCarryBox });
		iconList.push({ name: 'faPeopleGroup', icon: fa.faPeopleGroup });
		iconList.push({ name: 'faPeopleLine', icon: fa.faPeopleLine });
		iconList.push({ name: 'faPeoplePulling', icon: fa.faPeoplePulling });
		iconList.push({ name: 'faPeopleRobbery', icon: fa.faPeopleRobbery });
		iconList.push({ name: 'faPeopleRoof', icon: fa.faPeopleRoof });
		iconList.push({ name: 'faPepperHot', icon: fa.faPepperHot });
		iconList.push({ name: 'faPercent', icon: fa.faPercent });
		iconList.push({ name: 'faPercentage', icon: fa.faPercentage });
		iconList.push({ name: 'faPerson', icon: fa.faPerson });
		iconList.push({ name: 'faPersonArrowDownToLine', icon: fa.faPersonArrowDownToLine });
		iconList.push({ name: 'faPersonArrowUpFromLine', icon: fa.faPersonArrowUpFromLine });
		iconList.push({ name: 'faPersonBiking', icon: fa.faPersonBiking });
		iconList.push({ name: 'faPersonBooth', icon: fa.faPersonBooth });
		iconList.push({ name: 'faPersonBreastfeeding', icon: fa.faPersonBreastfeeding });
		iconList.push({ name: 'faPersonBurst', icon: fa.faPersonBurst });
		iconList.push({ name: 'faPersonCane', icon: fa.faPersonCane });
		iconList.push({ name: 'faPersonChalkboard', icon: fa.faPersonChalkboard });
		iconList.push({ name: 'faPersonCircleCheck', icon: fa.faPersonCircleCheck });
		iconList.push({ name: 'faPersonCircleExclamation', icon: fa.faPersonCircleExclamation });
		iconList.push({ name: 'faPersonCircleMinus', icon: fa.faPersonCircleMinus });
		iconList.push({ name: 'faPersonCirclePlus', icon: fa.faPersonCirclePlus });
		iconList.push({ name: 'faPersonCircleQuestion', icon: fa.faPersonCircleQuestion });
		iconList.push({ name: 'faPersonCircleXmark', icon: fa.faPersonCircleXmark });
		iconList.push({ name: 'faPersonDigging', icon: fa.faPersonDigging });
		iconList.push({ name: 'faPersonDotsFromLine', icon: fa.faPersonDotsFromLine });
		iconList.push({ name: 'faPersonDress', icon: fa.faPersonDress });
		iconList.push({ name: 'faPersonDressBurst', icon: fa.faPersonDressBurst });
		iconList.push({ name: 'faPersonDrowning', icon: fa.faPersonDrowning });
		iconList.push({ name: 'faPersonFalling', icon: fa.faPersonFalling });
		iconList.push({ name: 'faPersonFallingBurst', icon: fa.faPersonFallingBurst });
		iconList.push({ name: 'faPersonHalfDress', icon: fa.faPersonHalfDress });
		iconList.push({ name: 'faPersonHarassing', icon: fa.faPersonHarassing });
		iconList.push({ name: 'faPersonHiking', icon: fa.faPersonHiking });
		iconList.push({ name: 'faPersonMilitaryPointing', icon: fa.faPersonMilitaryPointing });
		iconList.push({ name: 'faPersonMilitaryRifle', icon: fa.faPersonMilitaryRifle });
		iconList.push({ name: 'faPersonMilitaryToPerson', icon: fa.faPersonMilitaryToPerson });
		iconList.push({ name: 'faPersonPraying', icon: fa.faPersonPraying });
		iconList.push({ name: 'faPersonPregnant', icon: fa.faPersonPregnant });
		iconList.push({ name: 'faPersonRays', icon: fa.faPersonRays });
		iconList.push({ name: 'faPersonRifle', icon: fa.faPersonRifle });
		iconList.push({ name: 'faPersonRunning', icon: fa.faPersonRunning });
		iconList.push({ name: 'faPersonShelter', icon: fa.faPersonShelter });
		iconList.push({ name: 'faPersonSkating', icon: fa.faPersonSkating });
		iconList.push({ name: 'faPersonSkiing', icon: fa.faPersonSkiing });
		iconList.push({ name: 'faPersonSkiingNordic', icon: fa.faPersonSkiingNordic });
		iconList.push({ name: 'faPersonSnowboarding', icon: fa.faPersonSnowboarding });
		iconList.push({ name: 'faPersonSwimming', icon: fa.faPersonSwimming });
		iconList.push({ name: 'faPersonThroughWindow', icon: fa.faPersonThroughWindow });
		iconList.push({ name: 'faPersonWalking', icon: fa.faPersonWalking });
		iconList.push({ name: 'faPersonWalkingArrowLoopLeft', icon: fa.faPersonWalkingArrowLoopLeft });
		iconList.push({ name: 'faPersonWalkingArrowRight', icon: fa.faPersonWalkingArrowRight });
		iconList.push({ name: 'faPersonWalkingDashedLineArrowRight', icon: fa.faPersonWalkingDashedLineArrowRight });
		iconList.push({ name: 'faPersonWalkingLuggage', icon: fa.faPersonWalkingLuggage });
		iconList.push({ name: 'faPersonWalkingWithCane', icon: fa.faPersonWalkingWithCane });
		iconList.push({ name: 'faPesetaSign', icon: fa.faPesetaSign });
		iconList.push({ name: 'faPesoSign', icon: fa.faPesoSign });
		iconList.push({ name: 'faPhone', icon: fa.faPhone });
		iconList.push({ name: 'faPhoneAlt', icon: fa.faPhoneAlt });
		iconList.push({ name: 'faPhoneFlip', icon: fa.faPhoneFlip });
		iconList.push({ name: 'faPhoneSlash', icon: fa.faPhoneSlash });
		iconList.push({ name: 'faPhoneSquare', icon: fa.faPhoneSquare });
		iconList.push({ name: 'faPhoneSquareAlt', icon: fa.faPhoneSquareAlt });
		iconList.push({ name: 'faPhoneVolume', icon: fa.faPhoneVolume });
		iconList.push({ name: 'faPhotoFilm', icon: fa.faPhotoFilm });
		iconList.push({ name: 'faPhotoVideo', icon: fa.faPhotoVideo });
		iconList.push({ name: 'faPieChart', icon: fa.faPieChart });
		iconList.push({ name: 'faPiggyBank', icon: fa.faPiggyBank });
		iconList.push({ name: 'faPills', icon: fa.faPills });
		iconList.push({ name: 'faPingPongPaddleBall', icon: fa.faPingPongPaddleBall });
		iconList.push({ name: 'faPizzaSlice', icon: fa.faPizzaSlice });
		iconList.push({ name: 'faPlaceOfWorship', icon: fa.faPlaceOfWorship });
		iconList.push({ name: 'faPlane', icon: fa.faPlane });
		iconList.push({ name: 'faPlaneArrival', icon: fa.faPlaneArrival });
		iconList.push({ name: 'faPlaneCircleCheck', icon: fa.faPlaneCircleCheck });
		iconList.push({ name: 'faPlaneCircleExclamation', icon: fa.faPlaneCircleExclamation });
		iconList.push({ name: 'faPlaneCircleXmark', icon: fa.faPlaneCircleXmark });
		iconList.push({ name: 'faPlaneDeparture', icon: fa.faPlaneDeparture });
		iconList.push({ name: 'faPlaneLock', icon: fa.faPlaneLock });
		iconList.push({ name: 'faPlaneSlash', icon: fa.faPlaneSlash });
		iconList.push({ name: 'faPlaneUp', icon: fa.faPlaneUp });
		iconList.push({ name: 'faPlantWilt', icon: fa.faPlantWilt });
		iconList.push({ name: 'faPlateWheat', icon: fa.faPlateWheat });
		iconList.push({ name: 'faPlay', icon: fa.faPlay });
		iconList.push({ name: 'faPlayCircle', icon: fa.faPlayCircle });
		iconList.push({ name: 'faPlug', icon: fa.faPlug });
		iconList.push({ name: 'faPlugCircleBolt', icon: fa.faPlugCircleBolt });
		iconList.push({ name: 'faPlugCircleCheck', icon: fa.faPlugCircleCheck });
		iconList.push({ name: 'faPlugCircleExclamation', icon: fa.faPlugCircleExclamation });
		iconList.push({ name: 'faPlugCircleMinus', icon: fa.faPlugCircleMinus });
		iconList.push({ name: 'faPlugCirclePlus', icon: fa.faPlugCirclePlus });
		iconList.push({ name: 'faPlugCircleXmark', icon: fa.faPlugCircleXmark });
		iconList.push({ name: 'faPlus', icon: fa.faPlus });
		iconList.push({ name: 'faPlusCircle', icon: fa.faPlusCircle });
		iconList.push({ name: 'faPlusMinus', icon: fa.faPlusMinus });
		iconList.push({ name: 'faPlusSquare', icon: fa.faPlusSquare });
		iconList.push({ name: 'faPodcast', icon: fa.faPodcast });
		iconList.push({ name: 'faPoll', icon: fa.faPoll });
		iconList.push({ name: 'faPollH', icon: fa.faPollH });
		iconList.push({ name: 'faPoo', icon: fa.faPoo });
		iconList.push({ name: 'faPooBolt', icon: fa.faPooBolt });
		iconList.push({ name: 'faPooStorm', icon: fa.faPooStorm });
		iconList.push({ name: 'faPoop', icon: fa.faPoop });
		iconList.push({ name: 'faPortrait', icon: fa.faPortrait });
		iconList.push({ name: 'faPoundSign', icon: fa.faPoundSign });
		iconList.push({ name: 'faPowerOff', icon: fa.faPowerOff });
		iconList.push({ name: 'faPray', icon: fa.faPray });
		iconList.push({ name: 'faPrayingHands', icon: fa.faPrayingHands });
		iconList.push({ name: 'faPrescription', icon: fa.faPrescription });
		iconList.push({ name: 'faPrescriptionBottle', icon: fa.faPrescriptionBottle });
		iconList.push({ name: 'faPrescriptionBottleAlt', icon: fa.faPrescriptionBottleAlt });
		iconList.push({ name: 'faPrescriptionBottleMedical', icon: fa.faPrescriptionBottleMedical });
		iconList.push({ name: 'faPrint', icon: fa.faPrint });
		iconList.push({ name: 'faProcedures', icon: fa.faProcedures });
		iconList.push({ name: 'faProjectDiagram', icon: fa.faProjectDiagram });
		iconList.push({ name: 'faPumpMedical', icon: fa.faPumpMedical });
		iconList.push({ name: 'faPumpSoap', icon: fa.faPumpSoap });
		iconList.push({ name: 'faPuzzlePiece', icon: fa.faPuzzlePiece });
		iconList.push({ name: 'faQ', icon: fa.faQ });
		iconList.push({ name: 'faQrcode', icon: fa.faQrcode });
		iconList.push({ name: 'faQuestion', icon: fa.faQuestion });
		iconList.push({ name: 'faQuestionCircle', icon: fa.faQuestionCircle });
		iconList.push({ name: 'faQuidditch', icon: fa.faQuidditch });
		iconList.push({ name: 'faQuidditchBroomBall', icon: fa.faQuidditchBroomBall });
		iconList.push({ name: 'faQuoteLeft', icon: fa.faQuoteLeft });
		iconList.push({ name: 'faQuoteLeftAlt', icon: fa.faQuoteLeftAlt });
		iconList.push({ name: 'faQuoteRight', icon: fa.faQuoteRight });
		iconList.push({ name: 'faQuoteRightAlt', icon: fa.faQuoteRightAlt });
		iconList.push({ name: 'faQuran', icon: fa.faQuran });
		iconList.push({ name: 'faR', icon: fa.faR });
		iconList.push({ name: 'faRadiation', icon: fa.faRadiation });
		iconList.push({ name: 'faRadiationAlt', icon: fa.faRadiationAlt });
		iconList.push({ name: 'faRadio', icon: fa.faRadio });
		iconList.push({ name: 'faRainbow', icon: fa.faRainbow });
		iconList.push({ name: 'faRandom', icon: fa.faRandom });
		iconList.push({ name: 'faRankingStar', icon: fa.faRankingStar });
		iconList.push({ name: 'faReceipt', icon: fa.faReceipt });
		iconList.push({ name: 'faRecordVinyl', icon: fa.faRecordVinyl });
		iconList.push({ name: 'faRectangleAd', icon: fa.faRectangleAd });
		iconList.push({ name: 'faRectangleList', icon: fa.faRectangleList });
		iconList.push({ name: 'faRectangleTimes', icon: fa.faRectangleTimes });
		iconList.push({ name: 'faRectangleXmark', icon: fa.faRectangleXmark });
		iconList.push({ name: 'faRecycle', icon: fa.faRecycle });
		iconList.push({ name: 'faRedo', icon: fa.faRedo });
		iconList.push({ name: 'faRedoAlt', icon: fa.faRedoAlt });
		iconList.push({ name: 'faRefresh', icon: fa.faRefresh });
		iconList.push({ name: 'faRegistered', icon: fa.faRegistered });
		iconList.push({ name: 'faRemove', icon: fa.faRemove });
		iconList.push({ name: 'faRemoveFormat', icon: fa.faRemoveFormat });
		iconList.push({ name: 'faReorder', icon: fa.faReorder });
		iconList.push({ name: 'faRepeat', icon: fa.faRepeat });
		iconList.push({ name: 'faReply', icon: fa.faReply });
		iconList.push({ name: 'faReplyAll', icon: fa.faReplyAll });
		iconList.push({ name: 'faRepublican', icon: fa.faRepublican });
		iconList.push({ name: 'faRestroom', icon: fa.faRestroom });
		iconList.push({ name: 'faRetweet', icon: fa.faRetweet });
		iconList.push({ name: 'faRibbon', icon: fa.faRibbon });
		iconList.push({ name: 'faRightFromBracket', icon: fa.faRightFromBracket });
		iconList.push({ name: 'faRightLeft', icon: fa.faRightLeft });
		iconList.push({ name: 'faRightLong', icon: fa.faRightLong });
		iconList.push({ name: 'faRightToBracket', icon: fa.faRightToBracket });
		iconList.push({ name: 'faRing', icon: fa.faRing });
		iconList.push({ name: 'faRmb', icon: fa.faRmb });
		iconList.push({ name: 'faRoad', icon: fa.faRoad });
		iconList.push({ name: 'faRoadBarrier', icon: fa.faRoadBarrier });
		iconList.push({ name: 'faRoadBridge', icon: fa.faRoadBridge });
		iconList.push({ name: 'faRoadCircleCheck', icon: fa.faRoadCircleCheck });
		iconList.push({ name: 'faRoadCircleExclamation', icon: fa.faRoadCircleExclamation });
		iconList.push({ name: 'faRoadCircleXmark', icon: fa.faRoadCircleXmark });
		iconList.push({ name: 'faRoadLock', icon: fa.faRoadLock });
		iconList.push({ name: 'faRoadSpikes', icon: fa.faRoadSpikes });
		iconList.push({ name: 'faRobot', icon: fa.faRobot });
		iconList.push({ name: 'faRocket', icon: fa.faRocket });
		iconList.push({ name: 'faRodAsclepius', icon: fa.faRodAsclepius });
		iconList.push({ name: 'faRodSnake', icon: fa.faRodSnake });
		iconList.push({ name: 'faRotate', icon: fa.faRotate });
		iconList.push({ name: 'faRotateBack', icon: fa.faRotateBack });
		iconList.push({ name: 'faRotateBackward', icon: fa.faRotateBackward });
		iconList.push({ name: 'faRotateForward', icon: fa.faRotateForward });
		iconList.push({ name: 'faRotateLeft', icon: fa.faRotateLeft });
		iconList.push({ name: 'faRotateRight', icon: fa.faRotateRight });
		iconList.push({ name: 'faRouble', icon: fa.faRouble });
		iconList.push({ name: 'faRoute', icon: fa.faRoute });
		iconList.push({ name: 'faRss', icon: fa.faRss });
		iconList.push({ name: 'faRssSquare', icon: fa.faRssSquare });
		iconList.push({ name: 'faRub', icon: fa.faRub });
		iconList.push({ name: 'faRuble', icon: fa.faRuble });
		iconList.push({ name: 'faRubleSign', icon: fa.faRubleSign });
		iconList.push({ name: 'faRug', icon: fa.faRug });
		iconList.push({ name: 'faRuler', icon: fa.faRuler });
		iconList.push({ name: 'faRulerCombined', icon: fa.faRulerCombined });
		iconList.push({ name: 'faRulerHorizontal', icon: fa.faRulerHorizontal });
		iconList.push({ name: 'faRulerVertical', icon: fa.faRulerVertical });
		iconList.push({ name: 'faRunning', icon: fa.faRunning });
		iconList.push({ name: 'faRupee', icon: fa.faRupee });
		iconList.push({ name: 'faRupeeSign', icon: fa.faRupeeSign });
		iconList.push({ name: 'faRupiahSign', icon: fa.faRupiahSign });
		iconList.push({ name: 'faS', icon: fa.faS });
		iconList.push({ name: 'faSackDollar', icon: fa.faSackDollar });
		iconList.push({ name: 'faSackXmark', icon: fa.faSackXmark });
		iconList.push({ name: 'faSadCry', icon: fa.faSadCry });
		iconList.push({ name: 'faSadTear', icon: fa.faSadTear });
		iconList.push({ name: 'faSailboat', icon: fa.faSailboat });
		iconList.push({ name: 'faSatellite', icon: fa.faSatellite });
		iconList.push({ name: 'faSatelliteDish', icon: fa.faSatelliteDish });
		iconList.push({ name: 'faSave', icon: fa.faSave });
		iconList.push({ name: 'faScaleBalanced', icon: fa.faScaleBalanced });
		iconList.push({ name: 'faScaleUnbalanced', icon: fa.faScaleUnbalanced });
		iconList.push({ name: 'faScaleUnbalancedFlip', icon: fa.faScaleUnbalancedFlip });
		iconList.push({ name: 'faSchool', icon: fa.faSchool });
		iconList.push({ name: 'faSchoolCircleCheck', icon: fa.faSchoolCircleCheck });
		iconList.push({ name: 'faSchoolCircleExclamation', icon: fa.faSchoolCircleExclamation });
		iconList.push({ name: 'faSchoolCircleXmark', icon: fa.faSchoolCircleXmark });
		iconList.push({ name: 'faSchoolFlag', icon: fa.faSchoolFlag });
		iconList.push({ name: 'faSchoolLock', icon: fa.faSchoolLock });
		iconList.push({ name: 'faScissors', icon: fa.faScissors });
		iconList.push({ name: 'faScrewdriver', icon: fa.faScrewdriver });
		iconList.push({ name: 'faScrewdriverWrench', icon: fa.faScrewdriverWrench });
		iconList.push({ name: 'faScroll', icon: fa.faScroll });
		iconList.push({ name: 'faScrollTorah', icon: fa.faScrollTorah });
		iconList.push({ name: 'faSdCard', icon: fa.faSdCard });
		iconList.push({ name: 'faSearch', icon: fa.faSearch });
		iconList.push({ name: 'faSearchDollar', icon: fa.faSearchDollar });
		iconList.push({ name: 'faSearchLocation', icon: fa.faSearchLocation });
		iconList.push({ name: 'faSearchMinus', icon: fa.faSearchMinus });
		iconList.push({ name: 'faSearchPlus', icon: fa.faSearchPlus });
		iconList.push({ name: 'faSection', icon: fa.faSection });
		iconList.push({ name: 'faSeedling', icon: fa.faSeedling });
		iconList.push({ name: 'faServer', icon: fa.faServer });
		iconList.push({ name: 'faShapes', icon: fa.faShapes });
		iconList.push({ name: 'faShare', icon: fa.faShare });
		iconList.push({ name: 'faShareAlt', icon: fa.faShareAlt });
		iconList.push({ name: 'faShareAltSquare', icon: fa.faShareAltSquare });
		iconList.push({ name: 'faShareFromSquare', icon: fa.faShareFromSquare });
		iconList.push({ name: 'faShareNodes', icon: fa.faShareNodes });
		iconList.push({ name: 'faShareSquare', icon: fa.faShareSquare });
		iconList.push({ name: 'faSheetPlastic', icon: fa.faSheetPlastic });
		iconList.push({ name: 'faShekel', icon: fa.faShekel });
		iconList.push({ name: 'faShekelSign', icon: fa.faShekelSign });
		iconList.push({ name: 'faSheqel', icon: fa.faSheqel });
		iconList.push({ name: 'faSheqelSign', icon: fa.faSheqelSign });
		iconList.push({ name: 'faShield', icon: fa.faShield });
		iconList.push({ name: 'faShieldAlt', icon: fa.faShieldAlt });
		iconList.push({ name: 'faShieldBlank', icon: fa.faShieldBlank });
		iconList.push({ name: 'faShieldCat', icon: fa.faShieldCat });
		iconList.push({ name: 'faShieldDog', icon: fa.faShieldDog });
		iconList.push({ name: 'faShieldHalved', icon: fa.faShieldHalved });
		iconList.push({ name: 'faShieldHeart', icon: fa.faShieldHeart });
		iconList.push({ name: 'faShieldVirus', icon: fa.faShieldVirus });
		iconList.push({ name: 'faShip', icon: fa.faShip });
		iconList.push({ name: 'faShippingFast', icon: fa.faShippingFast });
		iconList.push({ name: 'faShirt', icon: fa.faShirt });
		iconList.push({ name: 'faShoePrints', icon: fa.faShoePrints });
		iconList.push({ name: 'faShop', icon: fa.faShop });
		iconList.push({ name: 'faShopLock', icon: fa.faShopLock });
		iconList.push({ name: 'faShopSlash', icon: fa.faShopSlash });
		iconList.push({ name: 'faShoppingBag', icon: fa.faShoppingBag });
		iconList.push({ name: 'faShoppingBasket', icon: fa.faShoppingBasket });
		iconList.push({ name: 'faShoppingCart', icon: fa.faShoppingCart });
		iconList.push({ name: 'faShower', icon: fa.faShower });
		iconList.push({ name: 'faShrimp', icon: fa.faShrimp });
		iconList.push({ name: 'faShuffle', icon: fa.faShuffle });
		iconList.push({ name: 'faShuttleSpace', icon: fa.faShuttleSpace });
		iconList.push({ name: 'faShuttleVan', icon: fa.faShuttleVan });
		iconList.push({ name: 'faSign', icon: fa.faSign });
		iconList.push({ name: 'faSignHanging', icon: fa.faSignHanging });
		iconList.push({ name: 'faSignIn', icon: fa.faSignIn });
		iconList.push({ name: 'faSignInAlt', icon: fa.faSignInAlt });
		iconList.push({ name: 'faSignLanguage', icon: fa.faSignLanguage });
		iconList.push({ name: 'faSignOut', icon: fa.faSignOut });
		iconList.push({ name: 'faSignOutAlt', icon: fa.faSignOutAlt });
		iconList.push({ name: 'faSignal', icon: fa.faSignal });
		iconList.push({ name: 'faSignal5', icon: fa.faSignal5 });
		iconList.push({ name: 'faSignalPerfect', icon: fa.faSignalPerfect });
		iconList.push({ name: 'faSignature', icon: fa.faSignature });
		iconList.push({ name: 'faSigning', icon: fa.faSigning });
		iconList.push({ name: 'faSignsPost', icon: fa.faSignsPost });
		iconList.push({ name: 'faSimCard', icon: fa.faSimCard });
		iconList.push({ name: 'faSink', icon: fa.faSink });
		iconList.push({ name: 'faSitemap', icon: fa.faSitemap });
		iconList.push({ name: 'faSkating', icon: fa.faSkating });
		iconList.push({ name: 'faSkiing', icon: fa.faSkiing });
		iconList.push({ name: 'faSkiingNordic', icon: fa.faSkiingNordic });
		iconList.push({ name: 'faSkull', icon: fa.faSkull });
		iconList.push({ name: 'faSkullCrossbones', icon: fa.faSkullCrossbones });
		iconList.push({ name: 'faSlash', icon: fa.faSlash });
		iconList.push({ name: 'faSleigh', icon: fa.faSleigh });
		iconList.push({ name: 'faSliders', icon: fa.faSliders });
		iconList.push({ name: 'faSlidersH', icon: fa.faSlidersH });
		iconList.push({ name: 'faSmile', icon: fa.faSmile });
		iconList.push({ name: 'faSmileBeam', icon: fa.faSmileBeam });
		iconList.push({ name: 'faSmileWink', icon: fa.faSmileWink });
		iconList.push({ name: 'faSmog', icon: fa.faSmog });
		iconList.push({ name: 'faSmoking', icon: fa.faSmoking });
		iconList.push({ name: 'faSmokingBan', icon: fa.faSmokingBan });
		iconList.push({ name: 'faSms', icon: fa.faSms });
		iconList.push({ name: 'faSnowboarding', icon: fa.faSnowboarding });
		iconList.push({ name: 'faSnowflake', icon: fa.faSnowflake });
		iconList.push({ name: 'faSnowman', icon: fa.faSnowman });
		iconList.push({ name: 'faSnowplow', icon: fa.faSnowplow });
		iconList.push({ name: 'faSoap', icon: fa.faSoap });
		iconList.push({ name: 'faSoccerBall', icon: fa.faSoccerBall });
		iconList.push({ name: 'faSocks', icon: fa.faSocks });
		iconList.push({ name: 'faSolarPanel', icon: fa.faSolarPanel });
		iconList.push({ name: 'faSort', icon: fa.faSort });
		iconList.push({ name: 'faSortAlphaAsc', icon: fa.faSortAlphaAsc });
		iconList.push({ name: 'faSortAlphaDesc', icon: fa.faSortAlphaDesc });
		iconList.push({ name: 'faSortAlphaDown', icon: fa.faSortAlphaDown });
		iconList.push({ name: 'faSortAlphaDownAlt', icon: fa.faSortAlphaDownAlt });
		iconList.push({ name: 'faSortAlphaUp', icon: fa.faSortAlphaUp });
		iconList.push({ name: 'faSortAlphaUpAlt', icon: fa.faSortAlphaUpAlt });
		iconList.push({ name: 'faSortAmountAsc', icon: fa.faSortAmountAsc });
		iconList.push({ name: 'faSortAmountDesc', icon: fa.faSortAmountDesc });
		iconList.push({ name: 'faSortAmountDown', icon: fa.faSortAmountDown });
		iconList.push({ name: 'faSortAmountDownAlt', icon: fa.faSortAmountDownAlt });
		iconList.push({ name: 'faSortAmountUp', icon: fa.faSortAmountUp });
		iconList.push({ name: 'faSortAmountUpAlt', icon: fa.faSortAmountUpAlt });
		iconList.push({ name: 'faSortAsc', icon: fa.faSortAsc });
		iconList.push({ name: 'faSortDesc', icon: fa.faSortDesc });
		iconList.push({ name: 'faSortDown', icon: fa.faSortDown });
		iconList.push({ name: 'faSortNumericAsc', icon: fa.faSortNumericAsc });
		iconList.push({ name: 'faSortNumericDesc', icon: fa.faSortNumericDesc });
		iconList.push({ name: 'faSortNumericDown', icon: fa.faSortNumericDown });
		iconList.push({ name: 'faSortNumericDownAlt', icon: fa.faSortNumericDownAlt });
		iconList.push({ name: 'faSortNumericUp', icon: fa.faSortNumericUp });
		iconList.push({ name: 'faSortNumericUpAlt', icon: fa.faSortNumericUpAlt });
		iconList.push({ name: 'faSortUp', icon: fa.faSortUp });
		iconList.push({ name: 'faSpa', icon: fa.faSpa });
		iconList.push({ name: 'faSpaceShuttle', icon: fa.faSpaceShuttle });
		iconList.push({ name: 'faSpaghettiMonsterFlying', icon: fa.faSpaghettiMonsterFlying });
		iconList.push({ name: 'faSpellCheck', icon: fa.faSpellCheck });
		iconList.push({ name: 'faSpider', icon: fa.faSpider });
		iconList.push({ name: 'faSpinner', icon: fa.faSpinner });
		iconList.push({ name: 'faSplotch', icon: fa.faSplotch });
		iconList.push({ name: 'faSpoon', icon: fa.faSpoon });
		iconList.push({ name: 'faSprayCan', icon: fa.faSprayCan });
		iconList.push({ name: 'faSprayCanSparkles', icon: fa.faSprayCanSparkles });
		iconList.push({ name: 'faSprout', icon: fa.faSprout });
		iconList.push({ name: 'faSquare', icon: fa.faSquare });
		iconList.push({ name: 'faSquareArrowUpRight', icon: fa.faSquareArrowUpRight });
		iconList.push({ name: 'faSquareCaretDown', icon: fa.faSquareCaretDown });
		iconList.push({ name: 'faSquareCaretLeft', icon: fa.faSquareCaretLeft });
		iconList.push({ name: 'faSquareCaretRight', icon: fa.faSquareCaretRight });
		iconList.push({ name: 'faSquareCaretUp', icon: fa.faSquareCaretUp });
		iconList.push({ name: 'faSquareCheck', icon: fa.faSquareCheck });
		iconList.push({ name: 'faSquareEnvelope', icon: fa.faSquareEnvelope });
		iconList.push({ name: 'faSquareFull', icon: fa.faSquareFull });
		iconList.push({ name: 'faSquareH', icon: fa.faSquareH });
		iconList.push({ name: 'faSquareMinus', icon: fa.faSquareMinus });
		iconList.push({ name: 'faSquareNfi', icon: fa.faSquareNfi });
		iconList.push({ name: 'faSquareParking', icon: fa.faSquareParking });
		iconList.push({ name: 'faSquarePen', icon: fa.faSquarePen });
		iconList.push({ name: 'faSquarePersonConfined', icon: fa.faSquarePersonConfined });
		iconList.push({ name: 'faSquarePhone', icon: fa.faSquarePhone });
		iconList.push({ name: 'faSquarePhoneFlip', icon: fa.faSquarePhoneFlip });
		iconList.push({ name: 'faSquarePlus', icon: fa.faSquarePlus });
		iconList.push({ name: 'faSquarePollHorizontal', icon: fa.faSquarePollHorizontal });
		iconList.push({ name: 'faSquarePollVertical', icon: fa.faSquarePollVertical });
		iconList.push({ name: 'faSquareRootAlt', icon: fa.faSquareRootAlt });
		iconList.push({ name: 'faSquareRootVariable', icon: fa.faSquareRootVariable });
		iconList.push({ name: 'faSquareRss', icon: fa.faSquareRss });
		iconList.push({ name: 'faSquareShareNodes', icon: fa.faSquareShareNodes });
		iconList.push({ name: 'faSquareUpRight', icon: fa.faSquareUpRight });
		iconList.push({ name: 'faSquareVirus', icon: fa.faSquareVirus });
		iconList.push({ name: 'faSquareXmark', icon: fa.faSquareXmark });
		iconList.push({ name: 'faStaffAesculapius', icon: fa.faStaffAesculapius });
		iconList.push({ name: 'faStaffSnake', icon: fa.faStaffSnake });
		iconList.push({ name: 'faStairs', icon: fa.faStairs });
		iconList.push({ name: 'faStamp', icon: fa.faStamp });
		iconList.push({ name: 'faStapler', icon: fa.faStapler });
		iconList.push({ name: 'faStar', icon: fa.faStar });
		iconList.push({ name: 'faStarAndCrescent', icon: fa.faStarAndCrescent });
		iconList.push({ name: 'faStarHalf', icon: fa.faStarHalf });
		iconList.push({ name: 'faStarHalfAlt', icon: fa.faStarHalfAlt });
		iconList.push({ name: 'faStarHalfStroke', icon: fa.faStarHalfStroke });
		iconList.push({ name: 'faStarOfDavid', icon: fa.faStarOfDavid });
		iconList.push({ name: 'faStarOfLife', icon: fa.faStarOfLife });
		iconList.push({ name: 'faStepBackward', icon: fa.faStepBackward });
		iconList.push({ name: 'faStepForward', icon: fa.faStepForward });
		iconList.push({ name: 'faSterlingSign', icon: fa.faSterlingSign });
		iconList.push({ name: 'faStethoscope', icon: fa.faStethoscope });
		iconList.push({ name: 'faStickyNote', icon: fa.faStickyNote });
		iconList.push({ name: 'faStop', icon: fa.faStop });
		iconList.push({ name: 'faStopCircle', icon: fa.faStopCircle });
		iconList.push({ name: 'faStopwatch', icon: fa.faStopwatch });
		iconList.push({ name: 'faStopwatch20', icon: fa.faStopwatch20 });
		iconList.push({ name: 'faStore', icon: fa.faStore });
		iconList.push({ name: 'faStoreAlt', icon: fa.faStoreAlt });
		iconList.push({ name: 'faStoreAltSlash', icon: fa.faStoreAltSlash });
		iconList.push({ name: 'faStoreSlash', icon: fa.faStoreSlash });
		iconList.push({ name: 'faStream', icon: fa.faStream });
		iconList.push({ name: 'faStreetView', icon: fa.faStreetView });
		iconList.push({ name: 'faStrikethrough', icon: fa.faStrikethrough });
		iconList.push({ name: 'faStroopwafel', icon: fa.faStroopwafel });
		iconList.push({ name: 'faSubscript', icon: fa.faSubscript });
		iconList.push({ name: 'faSubtract', icon: fa.faSubtract });
		iconList.push({ name: 'faSubway', icon: fa.faSubway });
		iconList.push({ name: 'faSuitcase', icon: fa.faSuitcase });
		iconList.push({ name: 'faSuitcaseMedical', icon: fa.faSuitcaseMedical });
		iconList.push({ name: 'faSuitcaseRolling', icon: fa.faSuitcaseRolling });
		iconList.push({ name: 'faSun', icon: fa.faSun });
		iconList.push({ name: 'faSunPlantWilt', icon: fa.faSunPlantWilt });
		iconList.push({ name: 'faSuperscript', icon: fa.faSuperscript });
		iconList.push({ name: 'faSurprise', icon: fa.faSurprise });
		iconList.push({ name: 'faSwatchbook', icon: fa.faSwatchbook });
		iconList.push({ name: 'faSwimmer', icon: fa.faSwimmer });
		iconList.push({ name: 'faSwimmingPool', icon: fa.faSwimmingPool });
		iconList.push({ name: 'faSynagogue', icon: fa.faSynagogue });
		iconList.push({ name: 'faSync', icon: fa.faSync });
		iconList.push({ name: 'faSyncAlt', icon: fa.faSyncAlt });
		iconList.push({ name: 'faSyringe', icon: fa.faSyringe });
		iconList.push({ name: 'faT', icon: fa.faT });
		iconList.push({ name: 'faTShirt', icon: fa.faTShirt });
		iconList.push({ name: 'faTable', icon: fa.faTable });
		iconList.push({ name: 'faTableCells', icon: fa.faTableCells });
		iconList.push({ name: 'faTableCellsLarge', icon: fa.faTableCellsLarge });
		iconList.push({ name: 'faTableColumns', icon: fa.faTableColumns });
		iconList.push({ name: 'faTableList', icon: fa.faTableList });
		iconList.push({ name: 'faTableTennis', icon: fa.faTableTennis });
		iconList.push({ name: 'faTableTennisPaddleBall', icon: fa.faTableTennisPaddleBall });
		iconList.push({ name: 'faTablet', icon: fa.faTablet });
		iconList.push({ name: 'faTabletAlt', icon: fa.faTabletAlt });
		iconList.push({ name: 'faTabletAndroid', icon: fa.faTabletAndroid });
		iconList.push({ name: 'faTabletButton', icon: fa.faTabletButton });
		iconList.push({ name: 'faTabletScreenButton', icon: fa.faTabletScreenButton });
		iconList.push({ name: 'faTablets', icon: fa.faTablets });
		iconList.push({ name: 'faTachographDigital', icon: fa.faTachographDigital });
		iconList.push({ name: 'faTachometer', icon: fa.faTachometer });
		iconList.push({ name: 'faTachometerAlt', icon: fa.faTachometerAlt });
		iconList.push({ name: 'faTachometerAltAverage', icon: fa.faTachometerAltAverage });
		iconList.push({ name: 'faTachometerAltFast', icon: fa.faTachometerAltFast });
		iconList.push({ name: 'faTachometerAverage', icon: fa.faTachometerAverage });
		iconList.push({ name: 'faTachometerFast', icon: fa.faTachometerFast });
		iconList.push({ name: 'faTag', icon: fa.faTag });
		iconList.push({ name: 'faTags', icon: fa.faTags });
		iconList.push({ name: 'faTanakh', icon: fa.faTanakh });
		iconList.push({ name: 'faTape', icon: fa.faTape });
		iconList.push({ name: 'faTarp', icon: fa.faTarp });
		iconList.push({ name: 'faTarpDroplet', icon: fa.faTarpDroplet });
		iconList.push({ name: 'faTasks', icon: fa.faTasks });
		iconList.push({ name: 'faTasksAlt', icon: fa.faTasksAlt });
		iconList.push({ name: 'faTaxi', icon: fa.faTaxi });
		iconList.push({ name: 'faTeeth', icon: fa.faTeeth });
		iconList.push({ name: 'faTeethOpen', icon: fa.faTeethOpen });
		iconList.push({ name: 'faTeletype', icon: fa.faTeletype });
		iconList.push({ name: 'faTelevision', icon: fa.faTelevision });
		iconList.push({ name: 'faTemperature0', icon: fa.faTemperature0 });
		iconList.push({ name: 'faTemperature1', icon: fa.faTemperature1 });
		iconList.push({ name: 'faTemperature2', icon: fa.faTemperature2 });
		iconList.push({ name: 'faTemperature3', icon: fa.faTemperature3 });
		iconList.push({ name: 'faTemperature4', icon: fa.faTemperature4 });
		iconList.push({ name: 'faTemperatureArrowDown', icon: fa.faTemperatureArrowDown });
		iconList.push({ name: 'faTemperatureArrowUp', icon: fa.faTemperatureArrowUp });
		iconList.push({ name: 'faTemperatureDown', icon: fa.faTemperatureDown });
		iconList.push({ name: 'faTemperatureEmpty', icon: fa.faTemperatureEmpty });
		iconList.push({ name: 'faTemperatureFull', icon: fa.faTemperatureFull });
		iconList.push({ name: 'faTemperatureHalf', icon: fa.faTemperatureHalf });
		iconList.push({ name: 'faTemperatureHigh', icon: fa.faTemperatureHigh });
		iconList.push({ name: 'faTemperatureLow', icon: fa.faTemperatureLow });
		iconList.push({ name: 'faTemperatureQuarter', icon: fa.faTemperatureQuarter });
		iconList.push({ name: 'faTemperatureThreeQuarters', icon: fa.faTemperatureThreeQuarters });
		iconList.push({ name: 'faTemperatureUp', icon: fa.faTemperatureUp });
		iconList.push({ name: 'faTenge', icon: fa.faTenge });
		iconList.push({ name: 'faTengeSign', icon: fa.faTengeSign });
		iconList.push({ name: 'faTent', icon: fa.faTent });
		iconList.push({ name: 'faTentArrowDownToLine', icon: fa.faTentArrowDownToLine });
		iconList.push({ name: 'faTentArrowLeftRight', icon: fa.faTentArrowLeftRight });
		iconList.push({ name: 'faTentArrowTurnLeft', icon: fa.faTentArrowTurnLeft });
		iconList.push({ name: 'faTentArrowsDown', icon: fa.faTentArrowsDown });
		iconList.push({ name: 'faTents', icon: fa.faTents });
		iconList.push({ name: 'faTerminal', icon: fa.faTerminal });
		iconList.push({ name: 'faTextHeight', icon: fa.faTextHeight });
		iconList.push({ name: 'faTextSlash', icon: fa.faTextSlash });
		iconList.push({ name: 'faTextWidth', icon: fa.faTextWidth });
		iconList.push({ name: 'faTh', icon: fa.faTh });
		iconList.push({ name: 'faThLarge', icon: fa.faThLarge });
		iconList.push({ name: 'faThList', icon: fa.faThList });
		iconList.push({ name: 'faTheaterMasks', icon: fa.faTheaterMasks });
		iconList.push({ name: 'faThermometer', icon: fa.faThermometer });
		iconList.push({ name: 'faThermometer0', icon: fa.faThermometer0 });
		iconList.push({ name: 'faThermometer1', icon: fa.faThermometer1 });
		iconList.push({ name: 'faThermometer2', icon: fa.faThermometer2 });
		iconList.push({ name: 'faThermometer3', icon: fa.faThermometer3 });
		iconList.push({ name: 'faThermometer4', icon: fa.faThermometer4 });
		iconList.push({ name: 'faThermometerEmpty', icon: fa.faThermometerEmpty });
		iconList.push({ name: 'faThermometerFull', icon: fa.faThermometerFull });
		iconList.push({ name: 'faThermometerHalf', icon: fa.faThermometerHalf });
		iconList.push({ name: 'faThermometerQuarter', icon: fa.faThermometerQuarter });
		iconList.push({ name: 'faThermometerThreeQuarters', icon: fa.faThermometerThreeQuarters });
		iconList.push({ name: 'faThumbTack', icon: fa.faThumbTack });
		iconList.push({ name: 'faThumbsDown', icon: fa.faThumbsDown });
		iconList.push({ name: 'faThumbsUp', icon: fa.faThumbsUp });
		iconList.push({ name: 'faThumbtack', icon: fa.faThumbtack });
		iconList.push({ name: 'faThunderstorm', icon: fa.faThunderstorm });
		iconList.push({ name: 'faTicket', icon: fa.faTicket });
		iconList.push({ name: 'faTicketAlt', icon: fa.faTicketAlt });
		iconList.push({ name: 'faTicketSimple', icon: fa.faTicketSimple });
		iconList.push({ name: 'faTimeline', icon: fa.faTimeline });
		iconList.push({ name: 'faTimes', icon: fa.faTimes });
		iconList.push({ name: 'faTimesCircle', icon: fa.faTimesCircle });
		iconList.push({ name: 'faTimesRectangle', icon: fa.faTimesRectangle });
		iconList.push({ name: 'faTimesSquare', icon: fa.faTimesSquare });
		iconList.push({ name: 'faTint', icon: fa.faTint });
		iconList.push({ name: 'faTintSlash', icon: fa.faTintSlash });
		iconList.push({ name: 'faTired', icon: fa.faTired });
		iconList.push({ name: 'faToggleOff', icon: fa.faToggleOff });
		iconList.push({ name: 'faToggleOn', icon: fa.faToggleOn });
		iconList.push({ name: 'faToilet', icon: fa.faToilet });
		iconList.push({ name: 'faToiletPaper', icon: fa.faToiletPaper });
		iconList.push({ name: 'faToiletPaperSlash', icon: fa.faToiletPaperSlash });
		iconList.push({ name: 'faToiletPortable', icon: fa.faToiletPortable });
		iconList.push({ name: 'faToiletsPortable', icon: fa.faToiletsPortable });
		iconList.push({ name: 'faToolbox', icon: fa.faToolbox });
		iconList.push({ name: 'faTools', icon: fa.faTools });
		iconList.push({ name: 'faTooth', icon: fa.faTooth });
		iconList.push({ name: 'faTorah', icon: fa.faTorah });
		iconList.push({ name: 'faToriiGate', icon: fa.faToriiGate });
		iconList.push({ name: 'faTornado', icon: fa.faTornado });
		iconList.push({ name: 'faTowerBroadcast', icon: fa.faTowerBroadcast });
		iconList.push({ name: 'faTowerCell', icon: fa.faTowerCell });
		iconList.push({ name: 'faTowerObservation', icon: fa.faTowerObservation });
		iconList.push({ name: 'faTractor', icon: fa.faTractor });
		iconList.push({ name: 'faTrademark', icon: fa.faTrademark });
		iconList.push({ name: 'faTrafficLight', icon: fa.faTrafficLight });
		iconList.push({ name: 'faTrailer', icon: fa.faTrailer });
		iconList.push({ name: 'faTrain', icon: fa.faTrain });
		iconList.push({ name: 'faTrainSubway', icon: fa.faTrainSubway });
		iconList.push({ name: 'faTrainTram', icon: fa.faTrainTram });
		iconList.push({ name: 'faTram', icon: fa.faTram });
		iconList.push({ name: 'faTransgender', icon: fa.faTransgender });
		iconList.push({ name: 'faTransgenderAlt', icon: fa.faTransgenderAlt });
		iconList.push({ name: 'faTrash', icon: fa.faTrash });
		iconList.push({ name: 'faTrashAlt', icon: fa.faTrashAlt });
		iconList.push({ name: 'faTrashArrowUp', icon: fa.faTrashArrowUp });
		iconList.push({ name: 'faTrashCan', icon: fa.faTrashCan });
		iconList.push({ name: 'faTrashCanArrowUp', icon: fa.faTrashCanArrowUp });
		iconList.push({ name: 'faTrashRestore', icon: fa.faTrashRestore });
		iconList.push({ name: 'faTrashRestoreAlt', icon: fa.faTrashRestoreAlt });
		iconList.push({ name: 'faTree', icon: fa.faTree });
		iconList.push({ name: 'faTreeCity', icon: fa.faTreeCity });
		iconList.push({ name: 'faTriangleCircleSquare', icon: fa.faTriangleCircleSquare });
		iconList.push({ name: 'faTriangleExclamation', icon: fa.faTriangleExclamation });
		iconList.push({ name: 'faTrophy', icon: fa.faTrophy });
		iconList.push({ name: 'faTrowel', icon: fa.faTrowel });
		iconList.push({ name: 'faTrowelBricks', icon: fa.faTrowelBricks });
		iconList.push({ name: 'faTruck', icon: fa.faTruck });
		iconList.push({ name: 'faTruckArrowRight', icon: fa.faTruckArrowRight });
		iconList.push({ name: 'faTruckDroplet', icon: fa.faTruckDroplet });
		iconList.push({ name: 'faTruckFast', icon: fa.faTruckFast });
		iconList.push({ name: 'faTruckField', icon: fa.faTruckField });
		iconList.push({ name: 'faTruckFieldUn', icon: fa.faTruckFieldUn });
		iconList.push({ name: 'faTruckFront', icon: fa.faTruckFront });
		iconList.push({ name: 'faTruckLoading', icon: fa.faTruckLoading });
		iconList.push({ name: 'faTruckMedical', icon: fa.faTruckMedical });
		iconList.push({ name: 'faTruckMonster', icon: fa.faTruckMonster });
		iconList.push({ name: 'faTruckMoving', icon: fa.faTruckMoving });
		iconList.push({ name: 'faTruckPickup', icon: fa.faTruckPickup });
		iconList.push({ name: 'faTruckPlane', icon: fa.faTruckPlane });
		iconList.push({ name: 'faTruckRampBox', icon: fa.faTruckRampBox });
		iconList.push({ name: 'faTry', icon: fa.faTry });
		iconList.push({ name: 'faTshirt', icon: fa.faTshirt });
		iconList.push({ name: 'faTty', icon: fa.faTty });
		iconList.push({ name: 'faTurkishLira', icon: fa.faTurkishLira });
		iconList.push({ name: 'faTurkishLiraSign', icon: fa.faTurkishLiraSign });
		iconList.push({ name: 'faTurnDown', icon: fa.faTurnDown });
		iconList.push({ name: 'faTurnUp', icon: fa.faTurnUp });
		iconList.push({ name: 'faTv', icon: fa.faTv });
		iconList.push({ name: 'faTvAlt', icon: fa.faTvAlt });
		iconList.push({ name: 'faU', icon: fa.faU });
		iconList.push({ name: 'faUmbrella', icon: fa.faUmbrella });
		iconList.push({ name: 'faUmbrellaBeach', icon: fa.faUmbrellaBeach });
		iconList.push({ name: 'faUnderline', icon: fa.faUnderline });
		iconList.push({ name: 'faUndo', icon: fa.faUndo });
		iconList.push({ name: 'faUndoAlt', icon: fa.faUndoAlt });
		iconList.push({ name: 'faUniversalAccess', icon: fa.faUniversalAccess });
		iconList.push({ name: 'faUniversity', icon: fa.faUniversity });
		iconList.push({ name: 'faUnlink', icon: fa.faUnlink });
		iconList.push({ name: 'faUnlock', icon: fa.faUnlock });
		iconList.push({ name: 'faUnlockAlt', icon: fa.faUnlockAlt });
		iconList.push({ name: 'faUnlockKeyhole', icon: fa.faUnlockKeyhole });
		iconList.push({ name: 'faUnsorted', icon: fa.faUnsorted });
		iconList.push({ name: 'faUpDown', icon: fa.faUpDown });
		iconList.push({ name: 'faUpDownLeftRight', icon: fa.faUpDownLeftRight });
		iconList.push({ name: 'faUpLong', icon: fa.faUpLong });
		iconList.push({ name: 'faUpRightAndDownLeftFromCenter', icon: fa.faUpRightAndDownLeftFromCenter });
		iconList.push({ name: 'faUpRightFromSquare', icon: fa.faUpRightFromSquare });
		iconList.push({ name: 'faUpload', icon: fa.faUpload });
		iconList.push({ name: 'faUsd', icon: fa.faUsd });
		iconList.push({ name: 'faUser', icon: fa.faUser });
		iconList.push({ name: 'faUserAlt', icon: fa.faUserAlt });
		iconList.push({ name: 'faUserAltSlash', icon: fa.faUserAltSlash });
		iconList.push({ name: 'faUserAstronaut', icon: fa.faUserAstronaut });
		iconList.push({ name: 'faUserCheck', icon: fa.faUserCheck });
		iconList.push({ name: 'faUserCircle', icon: fa.faUserCircle });
		iconList.push({ name: 'faUserClock', icon: fa.faUserClock });
		iconList.push({ name: 'faUserCog', icon: fa.faUserCog });
		iconList.push({ name: 'faUserDoctor', icon: fa.faUserDoctor });
		iconList.push({ name: 'faUserEdit', icon: fa.faUserEdit });
		iconList.push({ name: 'faUserFriends', icon: fa.faUserFriends });
		iconList.push({ name: 'faUserGear', icon: fa.faUserGear });
		iconList.push({ name: 'faUserGraduate', icon: fa.faUserGraduate });
		iconList.push({ name: 'faUserGroup', icon: fa.faUserGroup });
		iconList.push({ name: 'faUserInjured', icon: fa.faUserInjured });
		iconList.push({ name: 'faUserLarge', icon: fa.faUserLarge });
		iconList.push({ name: 'faUserLargeSlash', icon: fa.faUserLargeSlash });
		iconList.push({ name: 'faUserLock', icon: fa.faUserLock });
		iconList.push({ name: 'faUserMd', icon: fa.faUserMd });
		iconList.push({ name: 'faUserMinus', icon: fa.faUserMinus });
		iconList.push({ name: 'faUserNinja', icon: fa.faUserNinja });
		iconList.push({ name: 'faUserNurse', icon: fa.faUserNurse });
		iconList.push({ name: 'faUserPen', icon: fa.faUserPen });
		iconList.push({ name: 'faUserPlus', icon: fa.faUserPlus });
		iconList.push({ name: 'faUserSecret', icon: fa.faUserSecret });
		iconList.push({ name: 'faUserShield', icon: fa.faUserShield });
		iconList.push({ name: 'faUserSlash', icon: fa.faUserSlash });
		iconList.push({ name: 'faUserTag', icon: fa.faUserTag });
		iconList.push({ name: 'faUserTie', icon: fa.faUserTie });
		iconList.push({ name: 'faUserTimes', icon: fa.faUserTimes });
		iconList.push({ name: 'faUserXmark', icon: fa.faUserXmark });
		iconList.push({ name: 'faUsers', icon: fa.faUsers });
		iconList.push({ name: 'faUsersBetweenLines', icon: fa.faUsersBetweenLines });
		iconList.push({ name: 'faUsersCog', icon: fa.faUsersCog });
		iconList.push({ name: 'faUsersGear', icon: fa.faUsersGear });
		iconList.push({ name: 'faUsersLine', icon: fa.faUsersLine });
		iconList.push({ name: 'faUsersRays', icon: fa.faUsersRays });
		iconList.push({ name: 'faUsersRectangle', icon: fa.faUsersRectangle });
		iconList.push({ name: 'faUsersSlash', icon: fa.faUsersSlash });
		iconList.push({ name: 'faUsersViewfinder', icon: fa.faUsersViewfinder });
		iconList.push({ name: 'faUtensilSpoon', icon: fa.faUtensilSpoon });
		iconList.push({ name: 'faUtensils', icon: fa.faUtensils });
		iconList.push({ name: 'faV', icon: fa.faV });
		iconList.push({ name: 'faVanShuttle', icon: fa.faVanShuttle });
		iconList.push({ name: 'faVault', icon: fa.faVault });
		iconList.push({ name: 'faVcard', icon: fa.faVcard });
		iconList.push({ name: 'faVectorSquare', icon: fa.faVectorSquare });
		iconList.push({ name: 'faVenus', icon: fa.faVenus });
		iconList.push({ name: 'faVenusDouble', icon: fa.faVenusDouble });
		iconList.push({ name: 'faVenusMars', icon: fa.faVenusMars });
		iconList.push({ name: 'faVest', icon: fa.faVest });
		iconList.push({ name: 'faVestPatches', icon: fa.faVestPatches });
		iconList.push({ name: 'faVial', icon: fa.faVial });
		iconList.push({ name: 'faVialCircleCheck', icon: fa.faVialCircleCheck });
		iconList.push({ name: 'faVialVirus', icon: fa.faVialVirus });
		iconList.push({ name: 'faVials', icon: fa.faVials });
		iconList.push({ name: 'faVideo', icon: fa.faVideo });
		iconList.push({ name: 'faVideoCamera', icon: fa.faVideoCamera });
		iconList.push({ name: 'faVideoSlash', icon: fa.faVideoSlash });
		iconList.push({ name: 'faVihara', icon: fa.faVihara });
		iconList.push({ name: 'faVirus', icon: fa.faVirus });
		iconList.push({ name: 'faVirusCovid', icon: fa.faVirusCovid });
		iconList.push({ name: 'faVirusCovidSlash', icon: fa.faVirusCovidSlash });
		iconList.push({ name: 'faVirusSlash', icon: fa.faVirusSlash });
		iconList.push({ name: 'faViruses', icon: fa.faViruses });
		iconList.push({ name: 'faVoicemail', icon: fa.faVoicemail });
		iconList.push({ name: 'faVolcano', icon: fa.faVolcano });
		iconList.push({ name: 'faVolleyball', icon: fa.faVolleyball });
		iconList.push({ name: 'faVolleyballBall', icon: fa.faVolleyballBall });
		iconList.push({ name: 'faVolumeControlPhone', icon: fa.faVolumeControlPhone });
		iconList.push({ name: 'faVolumeDown', icon: fa.faVolumeDown });
		iconList.push({ name: 'faVolumeHigh', icon: fa.faVolumeHigh });
		iconList.push({ name: 'faVolumeLow', icon: fa.faVolumeLow });
		iconList.push({ name: 'faVolumeMute', icon: fa.faVolumeMute });
		iconList.push({ name: 'faVolumeOff', icon: fa.faVolumeOff });
		iconList.push({ name: 'faVolumeTimes', icon: fa.faVolumeTimes });
		iconList.push({ name: 'faVolumeUp', icon: fa.faVolumeUp });
		iconList.push({ name: 'faVolumeXmark', icon: fa.faVolumeXmark });
		iconList.push({ name: 'faVoteYea', icon: fa.faVoteYea });
		iconList.push({ name: 'faVrCardboard', icon: fa.faVrCardboard });
		iconList.push({ name: 'faW', icon: fa.faW });
		iconList.push({ name: 'faWalkieTalkie', icon: fa.faWalkieTalkie });
		iconList.push({ name: 'faWalking', icon: fa.faWalking });
		iconList.push({ name: 'faWallet', icon: fa.faWallet });
		iconList.push({ name: 'faWandMagic', icon: fa.faWandMagic });
		iconList.push({ name: 'faWandMagicSparkles', icon: fa.faWandMagicSparkles });
		iconList.push({ name: 'faWandSparkles', icon: fa.faWandSparkles });
		iconList.push({ name: 'faWarehouse', icon: fa.faWarehouse });
		iconList.push({ name: 'faWarning', icon: fa.faWarning });
		iconList.push({ name: 'faWater', icon: fa.faWater });
		iconList.push({ name: 'faWaterLadder', icon: fa.faWaterLadder });
		iconList.push({ name: 'faWaveSquare', icon: fa.faWaveSquare });
		iconList.push({ name: 'faWeight', icon: fa.faWeight });
		iconList.push({ name: 'faWeightHanging', icon: fa.faWeightHanging });
		iconList.push({ name: 'faWeightScale', icon: fa.faWeightScale });
		iconList.push({ name: 'faWheatAlt', icon: fa.faWheatAlt });
		iconList.push({ name: 'faWheatAwn', icon: fa.faWheatAwn });
		iconList.push({ name: 'faWheatAwnCircleExclamation', icon: fa.faWheatAwnCircleExclamation });
		iconList.push({ name: 'faWheelchair', icon: fa.faWheelchair });
		iconList.push({ name: 'faWheelchairAlt', icon: fa.faWheelchairAlt });
		iconList.push({ name: 'faWheelchairMove', icon: fa.faWheelchairMove });
		iconList.push({ name: 'faWhiskeyGlass', icon: fa.faWhiskeyGlass });
		iconList.push({ name: 'faWifi', icon: fa.faWifi });
		iconList.push({ name: 'faWifi3', icon: fa.faWifi3 });
		iconList.push({ name: 'faWifiStrong', icon: fa.faWifiStrong });
		iconList.push({ name: 'faWind', icon: fa.faWind });
		iconList.push({ name: 'faWindowClose', icon: fa.faWindowClose });
		iconList.push({ name: 'faWindowMaximize', icon: fa.faWindowMaximize });
		iconList.push({ name: 'faWindowMinimize', icon: fa.faWindowMinimize });
		iconList.push({ name: 'faWindowRestore', icon: fa.faWindowRestore });
		iconList.push({ name: 'faWineBottle', icon: fa.faWineBottle });
		iconList.push({ name: 'faWineGlass', icon: fa.faWineGlass });
		iconList.push({ name: 'faWineGlassAlt', icon: fa.faWineGlassAlt });
		iconList.push({ name: 'faWineGlassEmpty', icon: fa.faWineGlassEmpty });
		iconList.push({ name: 'faWon', icon: fa.faWon });
		iconList.push({ name: 'faWonSign', icon: fa.faWonSign });
		iconList.push({ name: 'faWorm', icon: fa.faWorm });
		iconList.push({ name: 'faWrench', icon: fa.faWrench });
		iconList.push({ name: 'faX', icon: fa.faX });
		iconList.push({ name: 'faXRay', icon: fa.faXRay });
		iconList.push({ name: 'faXmark', icon: fa.faXmark });
		iconList.push({ name: 'faXmarkCircle', icon: fa.faXmarkCircle });
		iconList.push({ name: 'faXmarkSquare', icon: fa.faXmarkSquare });
		iconList.push({ name: 'faXmarksLines', icon: fa.faXmarksLines });
		iconList.push({ name: 'faY', icon: fa.faY });
		iconList.push({ name: 'faYen', icon: fa.faYen });
		iconList.push({ name: 'faYenSign', icon: fa.faYenSign });
		iconList.push({ name: 'faYinYang', icon: fa.faYinYang });
		iconList.push({ name: 'faZ', icon: fa.faZ });
		iconList.push({ name: 'faZap', icon: fa.faZap });
		
		setIcons(iconList);
		
	}, []);
	
	return (
		<Container fluid>
			<Row>
				{icons.filter(i => { if (props.query === undefined || props.query === '') return true; return i.name.toLowerCase().indexOf(props.query) !== -1; }).map((icon) => <Col xs={6} sm={4} md={3} lg={2} xl={1} key={icon.name}><Card className='mb-2'><Card.Header className='small text-center'>{icon.name}</Card.Header><Card.Body className='text-center'><h1 className='display-6 mb-0 pb-0'><FontAwesomeIcon icon={icon.icon} /></h1></Card.Body></Card></Col>)}
			</Row>
		</Container>
	);
}

export default IconViewer;