import { useState, useEffect } from 'react';
import Image from 'react-bootstrap/Image';

function Ad(props) {
	// https://adpiler.com/blog/banner-ad-sizes-and-dimensions-the-ultimate-guide/
	
	const [width, setWidth] = useState(250);
	const [height, setHeight] = useState(250);
	
	useEffect(() => {

	if (props.size) {
		
		switch (props.size.toLowerCase().trim()) {
			
			case 'medium rectangle':
				setWidth(300);
				setHeight(250);
				break;
				
			case 'leaderboard':
				setWidth(728);
				setHeight(90);
				break;
				
			case 'wide skyscraper':
				setWidth(160);
				setHeight(600);
				break;
				
			case 'half page':
				setWidth(300);
				setHeight(600);
				break;
				
			case 'billboard':
				setWidth(970);
				setHeight(250);
				break;
				
			case 'large rectangle':
				setWidth(336);
				setHeight(280);
				break;
				
			case 'banner':
				setWidth(468);
				setHeight(60);
				break;
				
			case 'half banner':
				setWidth(234);
				setHeight(60);
				break;
				
			case 'mobile leaderboard':
				setWidth(320);
				setHeight(50);
				break;
				
			case 'mobile full page flex':
				setWidth(320);
				setHeight(320);
				break;
				
			case 'large mobile banner':
				setWidth(320);
				setHeight(100);
				break;
				
			default: // square
				setWidth(250);
				setHeight(250);
				break;

		}
		
	}

	}, [props.size]);
	
	return (
		<div className={`${props.className} img-fluid text-center w-100`}>
			<Image src={`https://placehold.co/${width}x${height}${props.text ? `?text=${props.text}` : ''}`} fluid />
		</div>
	);
}

export default Ad;